/* eslint-disable no-debugger */
import Vue from 'vue';
import store from '@/store';
import VueRouter from 'vue-router';
//import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue';
import Wallet from '../views/Wallet.vue';
import Deposit from '../views/Deposit.vue';
import InstantTrade from '../views/InstantTrade.vue';
import Withdrawal from '../views/Withdrawal.vue';
import Login from '../views/Login.vue';
// import Register from '../views/Register.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import PasswordReset from '../views/PasswordReset.vue';
//import AccessSettings from '../views/Personal/AccessSettings.vue';
import AccountSettings from '../views/Personal/AccountSettings.vue';
import BankSettings from '../views/Personal/BankSettings.vue';
import History from '../views/Personal/History.vue';
import NotificationSettings from '../views/Personal/NotificationSettings.vue';
import PasswordSettings from '../views/Personal/PasswordSettings.vue';
import UserLoginSettings from '../views/Personal/UserLoginSettings.vue';
import Limit from '../views/Personal/Limit.vue';
// import EmailVerify from '../views/EmailVerify.vue';
import Deal from '../views/Deal';
import SpotTrade from '../views/SpotTrade.vue';
import Markets from '../views/Markets.vue';
import VerifySuccess from '../views/VerifySuccess.vue';
import MarginTrade from '../views/MarginTrade.vue';
import ComingSoon from '../views/ComingSoon.vue';

import API from '../api';
import Services from '../config/_axios';

import i18n from '../config/_i18n';
import isDev from '../config/isDev';
import restrictedRoutesInProd from '../config/restrictedProdRoutes';

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['customer/isAuthenticated']) {
    document.querySelector('body').classList.remove('fixed-navbar');
    localStorage.removeItem('refreshNextTime');
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters['customer/isAuthenticated']) {
    document.querySelector('body').classList.add('fixed-navbar');
    next();
    return;
  }

  next('/login');
};

const ifAuthenticatedAndNotLevelTwo = (to, from, next) => {
  if (
    to.name !== 'AccountSettings' &&
    store.getters['customer/isAuthenticated'] &&
    store.getters['customer/userLevel'] < 30
  ) {
    Vue.$toast.error(i18n.t("userLevelWarning"));
    next('/personal/account-settings');
    return;
  } else if (store.getters['customer/isAuthenticated']) {
    document.querySelector('body').classList.add('fixed-navbar');
    next();
    return;
  }

  next('/login');
};

const ifDealInfoTextApproved = (to, from, next) => {
  const dealInfoApproved = JSON.parse(window.localStorage.getItem("_dealInfoTextApproved"));
  if (dealInfoApproved && to.name === "deal-info-text") {
    next('/deal/intro');
    return;
  }
  if (!dealInfoApproved && to.name !== "deal-info-text") {
    next('/deal/info-text');
    return;
  }
  next();
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    //component: Home,
    component: Wallet,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: Deposit,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/instant-trade',
    name: 'InstantTrade',
    component: InstantTrade,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: Withdrawal,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/deal',
    name: 'Deal',
    component: Deal,
    redirect: '/deal/info-text',
    beforeEnter: ifAuthenticatedAndNotLevelTwo,
    children: [
      {
        path: 'info-text',
        name: 'deal-info-text',
        component: () => import("../views/Deal/InfoText.vue"),
        beforeEnter: ifDealInfoTextApproved
      },
      {
        path: 'intro',
        name: 'deal-intro',
        component: () => import("../views/Deal/Intro.vue"),
        beforeEnter: ifDealInfoTextApproved
      },
      {
        path: 'striker',
        name: 'striker',
        component: () => import("../views/Deal/Striker.vue"),
        beforeEnter: ifDealInfoTextApproved
      },
      {
        path: 'keeper',
        name: 'keeper',
        component: () => import("../views/Deal/Keeper.vue"),
        beforeEnter: ifDealInfoTextApproved
      },
      {
        path: 'my-strikers',
        name: 'my-strikers',
        component: () => import("../views/Deal/MyStrikers.vue"),
        beforeEnter: ifDealInfoTextApproved
      },
      {
        path: 'market-strikers',
        name: 'market-strikers',
        component: () => import("../views/Deal/MarketStrikers.vue"),
        beforeEnter: ifDealInfoTextApproved
      },
      /* {
        path: 'top-strikers',
        name: 'top-strikers',
        component: () => import("../views/Deal/TopStrikers.vue"),
        beforeEnter: ifDealInfoTextApproved
      }, */
      {
        path: 'my-keepers',
        name: 'my-keepers',
        component: () => import("../views/Deal/MyKeepers.vue"),
        beforeEnter: ifDealInfoTextApproved
      },
      {
        path: 'market-keepers',
        name: 'market-keepers',
        component: () => import("../views/Deal/MarketKeepers.vue"),
        beforeEnter: ifDealInfoTextApproved
      },
      /* {
        path: 'top-keepers',
        name: 'top-keepers',
        component: () => import("../views/Deal/TopKeepers.vue"),
        beforeEnter: ifDealInfoTextApproved
      }, */
      {
        path: 'approved-deals',
        name: 'approved-deals',
        component: () => import("../views/Deal/SKMatch.vue"),
        beforeEnter: ifDealInfoTextApproved
      }

    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  /* {
    path: '/register',
    name: 'Register',
    component: Register,
    beforeEnter: ifNotAuthenticated
  }, */
  /* {
    path: '/Register/EmailVerify',
    name: 'EmailVerify',
    component: EmailVerify,
    beforeEnter: ifNotAuthenticated
  }, */
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/PasswordReset',
    name: 'PasswordReset',
    component: PasswordReset,
    beforeEnter: ifNotAuthenticated
  },
  /*
  {
    path: '/personal/access-settings',
    name: 'AccessSettings',
    component: AccessSettings,
    beforeEnter: ifAuthenticated
  },
  */
  {
    path: '/personal/account-settings',
    name: 'AccountSettings',
    component: AccountSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/bank-settings',
    name: 'BankSettings',
    component: BankSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/history',
    name: 'History',
    component: History,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/notification-settings',
    name: 'NotificationSettings',
    component: NotificationSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/password-settings',
    name: 'PasswordSettings',
    component: PasswordSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/user-login-settings',
    name: 'UserLoginSettings',
    component: UserLoginSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/user-limit',
    name: 'UserLimit',
    component: Limit,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/verify-success',
    name: 'VerifySuccess',
    component: VerifySuccess,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/spot-trade',
    name: 'SpotTrade',
    component: SpotTrade,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/markets',
    name: 'Markets',
    component: Markets,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/margin-trade',
    name: 'MarginTrade',
    component: MarginTrade,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/coming-soon',
    name: 'ComingSoon',
    component: ComingSoon
  }
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes
});

router.afterEach(() => {
  let refreshNextTime = window.localStorage.getItem('refreshNextTime');
  refreshNextTime = parseInt(refreshNextTime);

  if (refreshNextTime && refreshNextTime < Date.now()) {
    setTimeout(() => {
      Services.post(API.refreshToken)
        .then((res) => {
          localStorage.setItem('userToken', res.data.access_token);
          localStorage.setItem('refreshNextTime', Date.now() + 3600000);
          Services.defaults.headers.common['Authorization'] = res.data.access_token;
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            console.log(err.response.data.message);
          }
        });
    }, 3000);
  }
});

router.beforeEach((to, from, next) => {
  if(!isDev && restrictedRoutesInProd.some(val => to.path.indexOf(val) !== -1)) {
    next("/");
  } else {
    next();
  }
  if (store.getters['customer/isAuthenticated']) {
    store.dispatch('customer/GET_USER_NOTIFICATIONS', { isNextPage: false })
  }

});


export default router;
