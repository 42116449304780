import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from '@/assets/languages/en.json';
import tr from '@/assets/languages/tr.json';
/* import de from '@/assets/languages/en.json';
import es from '@/assets/languages/en.json';
import et from '@/assets/languages/en.json';
import fr from '@/assets/languages/en.json';
import me from '@/assets/languages/en.json';
import nl from '@/assets/languages/en.json'; */

import { localeChanged } from 'vee-validate';
import MessagesEN from 'vee-validate/dist/locale/en.json';
import MessagesTR from 'vee-validate/dist/locale/tr.json';
/* import MessagesDE from 'vee-validate/dist/locale/en.json';
import MessagesES from 'vee-validate/dist/locale/en.json';
import MessagesET from 'vee-validate/dist/locale/en.json';
import MessagesFR from 'vee-validate/dist/locale/en.json';
import MessagesME from 'vee-validate/dist/locale/en.json';
import MessagesNL from 'vee-validate/dist/locale/en.json'; */

Vue.use(VueI18n);

const qs = new URLSearchParams(window.location.search);

if (qs.has('lang')) {
  sessionStorage.setItem('lang', qs.get('lang'));
}

let lang = sessionStorage.getItem('lang');
if (!lang) {
  lang = 'tr';
  sessionStorage.setItem('lang', lang);
}

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'tr',
  messages: {
    en: { ...en, validation: MessagesEN.messages },
    tr: { ...tr, validation: MessagesTR.messages },
    /* de: { ...de, validation: MessagesDE.messages },
    es: { ...es, validation: MessagesES.messages },
    et: { ...et, validation: MessagesET.messages },
    fr: { ...fr, validation: MessagesFR.messages },
    me: { ...me, validation: MessagesME.messages },
    nl: { ...nl, validation: MessagesNL.messages }, */
  }
});
document.documentElement.setAttribute('lang', lang);

export function changeLanguages(lang, refresh) {
  sessionStorage.setItem('lang', lang);
  i18n.locale = lang;
  localeChanged();
  if (refresh) {
    window.history.pushState({}, document.title, window.location.pathname);
    location.reload();
  }
}

export default i18n;
