/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';

const qs = require('qs');

const account = {
  state: () => ({
    secondLevel: {
      nationalId: '',
      showModal: false,
      url: ''
    },    
    notifications: [],
    actions: [],
    next: '',
    passwords: {
      curPassword: '',
      newPassword: '',
      reNewPassword: ''
    },
    bank_settings: {
      bank: '',
      account_name: '',
      iban: '',
      currency: null
    },
    bankAccountList: [],
    isHidden: false,
    countryCodes: [],
    formTwoData: [],
    formTwoRequired: [],
    responseNdLevel: {},
    twoNdLevel: [],
    level_2: {
      isLoading: false
    },
    showUpgrade: {
      mainMenu: true,
      secondLevel: false,
      thirdLevel: false
    },
    level_3: {
      documentTypes: [],
      formDatas: {},
      isLoading: false
    }
  }),
  mutations: {
    SET_ACTIONS(state, payload) {
      state.next = payload.payload.links.next;

      if (!payload.isNextPage) {
        state.actions = [];
      }
      for (let el in payload.payload.data) {
        let object = payload.payload.data[el];
        state.actions.push(object);
      }
    },
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = [];
      for (let key in payload.data) {
        let object = payload.data[key];
        Object.entries(object).forEach(([index, data]) => (state.notifications[index] = data.value));
      }
    },
    SET_BANK_ACCOUNT_LIST(state, payload) {
      state.bankAccountList = [];
      state.isHidden = false;
      for (let key in payload.data) {
        let object = payload.data[key];
        state.bankAccountList.push(object);
      }
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_COUNTRY_CODES(state, payload) {
      state.countryCodes = [];
      for (let key in payload.countries) {
        let object = payload.countries[key];
        state.countryCodes.push(object);
      }
    },
    SET_FORM_2ND_LEVEL(state, payload) {
      state.formTwoData = [];
      state.formTwoRequired = [];
      state.responseNdLevel = payload;
    },
    showSecondLevel(state) {
      state.showUpgrade.secondLevel = true;
      state.showUpgrade.mainMenu = false;
    },
    showThirdLevel(state) {
      state.showUpgrade.thirdLevel = true;
      state.showUpgrade.mainMenu = false;
    },
    cancelUpgrade(state) {
      state.showUpgrade.mainMenu = true;
      state.showUpgrade.secondLevel = false;
      state.showUpgrade.thirdLevel = false;
    },
    SET_DOCUMENTS(state, params) {
      state.level_3.documents = params;
    },
    SET_DOCUMENT_TYPE(state, params) {
      var documentTypes = [];
      Object.values(params.data.types[params.code]).forEach(function(item) {
        documentTypes.push({ name: item });
      });
      state.level_3.documentTypes = documentTypes;
    },
    SET_TWO_LEVEL_DATA(state, params) {
      state.secondLevel.url = params.url;
    },
    SET_STATUS_MODAL(state, params) {
      state.secondLevel.showModal = params;
    },
  },
  actions: {
    GET_ACTIONS({ state, commit }, params) {
      let nextPage = `${API.actions}?page=1`;
      if (params.isNextPage) {
        nextPage = `${state.next}`;
      }
      Services.get(nextPage)
        .then(res => {
          let data = {
            payload: res.data,
            isNextPage: params.isNextPage
          };
          commit('SET_ACTIONS', data);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_NOTIFICATIONS({ commit }) {
      Services.get(API.notifications)
        .then(res => {
          commit('SET_NOTIFICATIONS', res.data);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_DOCUMENTS({ commit }) {
      Services.get(API.documentsToCountry)
        .then(res => {
          commit('SET_COUNTRY_CODES', res.data);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_DOCUMENT_TYPE({ commit }, code) {
      Services.get(`${API.documentTypes}${code}`)
        .then(res => {
          commit('SET_DOCUMENT_TYPE', { code: code, data: res.data });
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_3ND_LEVEL({ commit }, params) {
      const data = {
        DataFields: params.content
      };
      Services.post(`${API.verifyDocument}${params.selectedCountry}`, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(() => {
          commit('cancelUpgrade', false);
          Vue.$toast.success('İşleminiz Beklemede');
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_NOTIFICATION({ state }, params) {
      const data = {
        notification: params,
        value: state.notifications[params]
      };
      Services.post(API.notifications, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(res => {
          console.log(res.data);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_PASSWORD({ state, dispatch }) {
      const data = {
        password_old: state.passwords.curPassword,
        password: state.passwords.newPassword,
        password_confirmation: state.passwords.reNewPassword
      };
      Services.post(API.updatePassword, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(res => {
          Vue.$toast.success(res.data.message);
          dispatch('CANCEL_PASSWORD');
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    CANCEL_PASSWORD({ state }) {
      state.passwords.curPassword = null;
      state.passwords.newPassword = null;
      state.passwords.reNewPassword = null;
    },
    GET_BANK_ACCOUNT_LIST({ commit }) {
      Services.get(API.bankAccountList)
        .then(res => {
          commit('SET_BANK_ACCOUNT_LIST', res.data);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    ADD_BANK_ACCOUNT({ state, dispatch }) {
      let address = `TR${state.bank_settings.iban.trim().replace(/ +/g, '')}`;
      const data = {
        provider: 'bank_transfer',
        currency: state.bank_settings.currency.code.toLowerCase(),
        address: address,
        bank: state.bank_settings.bank.id,
        label: state.bank_settings.account_name,
      };
      console.log(data);
      Services.post(API.createWallet, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(res => {
          state.isHidden = false;
          state.bank_settings = {
            bank: '',
            account_name: '',
            iban: '',
            currency: null
          };
          Vue.$toast.success(res.data.message);
          dispatch('GET_BANK_ACCOUNT_LIST');
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    DELETE_BANK({ dispatch, commit }, bankCode) {
      Services.delete(API.withdraw + '/' + bankCode)
        .then(res => {
          Vue.$toast.success(res.data.message);
          dispatch('GET_BANK_ACCOUNT_LIST');
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_COUNTRY_CODES({ commit }) {
      Services.get(API.countryCodes)
        .then(res => {
          commit('SET_COUNTRY_CODES', res.data);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_FORM_2ND_LEVEL({ commit, dispatch }, params) {
      Services.get(`${API.formToCountry}${params}`)
        .then(res => {
          commit('SET_FORM_2ND_LEVEL', res.data);
          dispatch('CREATE_FORM_2ND_LEVEL', res.data.DataFields);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    CREATE_FORM_2ND_LEVEL({ state, dispatch }, params) {
      if (typeof params.required != 'undefined' && params.required.length > 0)
        state.formTwoRequired = [...state.formTwoRequired, ...params.required];
      Object.entries(params.properties).forEach(([key, value]) => {
        if (value.type != 'object') {
          state.formTwoData.push({ name: key, data: value });
        } else {
          dispatch('CREATE_FORM_2ND_LEVEL', value);
        }
      });
    },
    POST_2ND_LEVEL({ state, commit }, params) {
      state.level_2.isLoading = true;
      const data = {
        DataFields: params.content,
        ConsentForDataSources: []
      };
      Services.post(`${API.upgradeTwoNdLevel}${params.selectedCountry}`, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(res => {
          this.dispatch('customer/GET_USER', { root: true });
          commit('cancelUpgrade', false);
          state.level_2.isLoading = false;
          Vue.$toast.success(res.data.message);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
            state.level_2.isLoading = false;
          }
        });
    },
    POST_2ND_LEVEL_NEW({ state/* , commit */}) {
      const data = {
        identity_number: state.secondLevel.nationalId
      };
      state.level_2.isLoading = true;
      Services.post(`${API.secondLevelAmani}`, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then((res) => {
          state.level_2.isLoading = false;

          window.location.assign(res.data.url);

          // commit('SET_TWO_LEVEL_DATA', res.data)
          // commit('SET_STATUS_MODAL', true)
          //this.dispatch('customer/GET_USER', { root: true });
        })
        .catch(err => {
          state.level_2.isLoading = false;
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_AMANI_CONFIRM() {
      const data = {
        id: 1,
        status: "complete",
        errors: "error"
      };
      Services.post(`${API.amaniConfirm}`, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then((res) => {
          console.log(res)
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_THIRD_LEVEL({ state, commit }) {
      let formData = new FormData();
      state.level_3.isLoading = true;
      Object.entries(state.level_3.formDatas).forEach(([key, value]) => {
        formData.append(key, value);
      });
      Services.post(API.verifyDocument, formData, {
        headers: { 'content-type': 'multipart/form-data' }
      })
        .then(res => {
          this.dispatch('customer/GET_USER', { root: true });
          commit('cancelUpgrade', false);
          Vue.$toast.success(res.data.message);
          state.level_3.isLoading = false;
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            state.level_3.isLoading = false;
            Vue.$toast.error(err.response.data.message);
          }
        });
    }
  },
  getters: {},
  namespaced: true
};

export default account;
