<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form method="post" @submit.prevent="handleSubmit(handleContinue)">
      <div v-if="!showAddBankAccount">
        <div>
          <div class="form-group">
            <label class="mr-sm-2">{{ $t('withdrawal-type') }}</label>
            <validation-provider rules="required" v-slot="{ classes, errors }">
              <div class="input-group">
                <multiselect
                  v-model="selectedCurrency"
                  @input="getCoinSelection"
                  deselect-label=""
                  select-label=""
                  :class="classes"
                  selected-label=""
                  track-by="name"
                  :label="$t('withdrawal-type')"
                  :custom-label="currencySelectCustomLabel"
                  :show-labels="false"
                  :placeholder="$t('withdrawal-dropdownCryptoWallet')"
                  :options="withdrawCurrency"
                  :allow-empty="false"
                  open-direction="bottom"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    >{{ `${option.name} (${option.code})` }}
                  </template>
                  <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
          <div class="d-flex mt-1">
            <div
              class="alert alert-warning"
              role="alert"
              v-html="$t('depositWithdrawOwnAccountTransferWarning')"
            ></div>
          </div>
          <div v-if="selectedCurrency">
            <div class="form-group" v-if="selectedCurrency.code === 'USDT'">
              <label class="mr-sm-2">{{ $t('network') }}</label>
              <validation-provider rules="required" v-slot="{ classes, errors }">
                <div class="input-group">
                  <multiselect
                    v-model="selectedNetwork"
                    @input="onNetworkChange"
                    deselect-label=""
                    select-label=""
                    :class="classes"
                    selected-label=""
                    track-by="name"
                    :label="$t('network')"
                    :options="networks"
                    :allow-empty="false"
                    open-direction="bottom"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      >{{ option.name }}
                    </template>
                    <template slot="option" slot-scope="{ option }">{{ option.name }}</template>
                    <template slot="noOptions">{{ $t('noOptions') }}</template>
                    <template slot="noResult">{{ $t('noOptions') }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </div>
              </validation-provider>
            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label class="mr-sm-2" v-if="selectedCurrency.crypto">{{ $t('withdrawal-labelCrypto') }}</label>
                <label class="mr-sm-2" v-else>{{ $t('withdrawal-labelBank') }}</label>
                <div>
                  <span class="add-wallet" @click="addWalletBtn">
                    <template v-if="selectedCurrency.crypto">{{ $t('withdrawal-addCryptoWalletModal') }}</template>
                    <template v-else>{{ $t('withdrawal-addAccountModal') }}</template>
                  </span>
                </div>
              </div>

              <validation-provider
                rules="required"
                v-slot="{ classes, errors }"
                :name="selectedCurrency.crypto ? $t('withdrawal-wallet') : $t('withdrawal-bank')"
              >
                <div class="input-group">
                  <multiselect
                    v-model="selectedBank"
                    deselect-label=""
                    select-label=""
                    selected-label=""
                    track-by="bank_id"
                    :class="classes"
                    :label="$t('withdrawal-bank')"
                    :placeholder="$t('withdrawal-dropdownCryptoWallet')"
                    :options="wallet"
                    :searchable="false"
                    :allow-empty="false"
                    open-direction="bottom"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      >{{ `${option.label} ${option.address}` }}
                    </template>
                    <template slot="option" slot-scope="{ option }">{{ `${option.label} ${option.address}` }}</template>
                    <template slot="noOptions">{{ $t('noOptions') }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </div>
              </validation-provider>
            </div>
            <div class="form-group">
              <div class="row">
                <label class="col-6" v-if="selectedCurrency.crypto">{{ $t('withdrawal-labelAmountCrypto') }}</label>
                <label class="col-6" v-else>{{ $t('withdrawal-labelAmount') }}</label>
              </div>
              <div class="row"></div>
              <div class="row">
                <validation-provider
                  :rules="`required|insufficient_balance:${withdrawalBalanceNotFormatted}`"
                  :name="selectedCurrency.crypto ? $t('withdrawal-labelAmountCrypto') : $t('withdrawal-labelAmount')"
                  v-slot="{ classes, errors }"
                  class="col-6"
                >
                  <currency-input
                    id="currency_amount"
                    class="form-control"
                    autocomplete="off"
                    :distractionFree="false"
                    :class="classes"
                    v-model="amount"
                    :precision="getCurrencyInputPrecision"
                    :placeholder="
                      selectedCurrency.crypto ? $t('withdrawal-labelAmountCrypto') : $t('withdrawal-labelAmount')
                    "
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div class="col-6">
                  <div class="available-limit">
                    <p class="mb-0 font-small-2">{{ $t('withdrawal-availablelimit') }}</p>
                    <h6 class="mb-0 font-medium-5">{{ withdrawalBalance }}</h6>
                  </div>
                </div>
              </div>
              <PercentButtons @onSelectedPercent="onSelectedPercent" ref="percentButtons" class="my-sm-1" />
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div
                class="alert alert-warning w-100 font-weight-bold"
                role="alert"
              >{{ getMinWithdrawalAmountWarning }}</div>
            </div>
            <div class="col-12 text-center mt-2">
              <button type="submit" class="btn-gradient-primary btn-block">{{ $t('withdrawal-btnContinue') }}</button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <BankList />
        <div class="col-12 text-center mt-2">
          <button
            type="button"
            class="btn btn-outline-secondary -border mr-md-2"
            @click="$store.commit('withdrawal/showAddBankAccountUpdate', false)"
          >
            {{ $t('withdrawal-back') }}
          </button>
          <button type="button" class="btn-gradient-primary mt-1 mt-md-0" @click="addBankWalletBtn">
            {{ $t('withdrawal-addAccountModal') }}
          </button>
        </div>
      </div>
    </form>
    <modal v-if="isTransfer" ref="confirmationModal" @onCloseModal="cancelTransfer">
      <template v-slot:header>
        <h1>{{ $t('withdrawal-btnConfirm') }}</h1>
      </template>
      <template v-slot:body>
        <div class="d-flex flex-wrap justify-content-between align-items-center mb-1">
          <span>{{ selectedCurrency.crypto ? $t('withdrawal-addCryptoWalletAddress') : 'IBAN' }}</span>
          <span class="font-weight-bold">
            {{ selectedBank.address }}
          </span>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-center mb-1">
          <span>{{ $t('withdrawal-labelAmount') }} ({{ selectedCurrency.code }})</span>
          <span class="font-weight-bold">
            {{ amount }}
          </span>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-center mb-1">
          <span>{{ $t('withdrawal-labelCommission') }} ({{ selectedCurrency.code }})</span>
          <span class="font-weight-bold">
            {{ transfer.fee ? transfer.fee : '' }}
          </span>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-center mb-2">
          <span>{{ $t('withdrawal-labelTotalAmount') }} ({{ selectedCurrency.code }})</span>
          <span class="font-weight-bold">
            {{ transfer.totalAmount ? transfer.totalAmount : '' }}
          </span>
        </div>
        <div class="row">
          <div class="col-6 text-center mt-2">
            <a type="button" class="btn btn-outline-secondary -border w-100" @click="cancelTransfer">
              {{ $t('withdrawal-btnCancel') }}
            </a>
          </div>
          <div class="col-6 text-center mt-2">
            <button type="button" class="btn-gradient-primary w-100" @click="createWithdrawal">
              {{ $t('commons.approve') }}
            </button>
          </div>
        </div>
      </template>
    </modal>
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import BankList from '@/components/withdrawal/BankList';
import Modal from '@/components/global/Modal';
import PercentButtons from "@/components/global/PercentButtons.vue";

export default {
  name: 'WithdrawalForm',
  computed: {
    ...mapState({
      wallet: (state) => state.withdrawal.wallet,
      withdrawalBalance: (state) => state.withdrawal.withdrawalBalance,
      withdrawalBalanceNotFormatted: (state) => state.withdrawal.withdrawalBalanceNotFormatted,
      showAddBankAccount: (state) => state.withdrawal.showAddBankAccount,
      transfer: (state) => state.withdrawal.transfer,
      currency: (state) => state.global.currency,
      isLoading: (state) => state.global.isLoading,
      user: (state) => state.customer.user,
      staticContent: (state) => state.withdrawal.infoContent,
      networks: (state) => state.withdrawal.networks,
    }),
    selectedBank: {
      get() {
        return this.$store.state.withdrawal.selectedBank;
      },
      set(value) {
        this.$store.commit('withdrawal/updateSelectedBank', value);
      }
    },
    amount: {
      get() {
        return this.$store.state.withdrawal.amount;
      },
      set(value) {
        this.$store.commit('withdrawal/updateAmount', value);
      }
    },
    selectedCurrency: {
      get() {
        return this.$store.state.withdrawal.selectedCurrency;
      },
      set(value) {
        this.$store.commit('withdrawal/updateSelectedCurrency', value);
      }
    },
    isTransfer: {
      get() {
        return this.$store.state.withdrawal.isTransfer;
      },
      set(value) {
        this.$store.commit('withdrawal/SET_CREATE_WITHDRAWAL', value);
      }
    },
    withdrawCurrency: function () {
      return this.currency.filter(function (currency) {
        return currency.withdraw;
      });
    },
    getMinWithdrawalAmountWarning() {
      // return '₺25';
      const minFromProvider = this.selectedBank?.min_pay ? 
          `${parseFloat(this.selectedBank.min_pay).toFixed(2)} TRY` :
          `10 TRY`;
      const content = `Talimat verebileceğiniz minimum çekim miktarı ${minFromProvider} ’dir.`;
      return this.staticContent?.content ? this.staticContent?.content[1] : content;
    },
    getCurrencyInputPrecision() {
      return this.selectedCurrency.crypto ? 8 : 2;
    },
    selectedNetwork: {
      get() {
        return this.$store.state.withdrawal.selectedNetwork;
      },
      set(value) {
        this.$store.commit('withdrawal/updateSelectedNetwork', value);
      }
    },
    isCrypto() {
      return this.selectedCurrency?.crypto ? this.selectedCurrency.crypto : false;
    }
  },
  components: {
    BankList,
    Multiselect,
    Modal,
    PercentButtons
  },
  methods: {
    ...mapActions('global', ['GET_CURRENCY']),
    ...mapActions('withdrawal', [
      'GET_WITHDRAWAL',
      'POST_CREATE_WITHDRAWAL',
      'GET_BALANCE',
      'GET_HISTORY'
    ]),
    createWithdrawal() {
      const formData = {
        temp: true,
        amount: this.amount,
        currency: this.selectedCurrency.code.toUpperCase(),
        address: this.selectedBank.address,
        provider: this.selectedCurrency.crypto ? "crypto_currency" : "bank_transfer",
        wallet_code: this.selectedBank.code
      };
      if(this.selectedCurrency.code.toUpperCase() === 'USDT') {
        formData.network = this.selectedNetwork.network;
      }
      if(!this.isCrypto) {
        formData.bank = this.selectedBank.bank_id
      }
      this.POST_CREATE_WITHDRAWAL(formData);
    },
    handleContinue() {
      this.$store.commit('withdrawal/SET_TRANSFER');
    },
    getCoinSelection() {
      this.amount = null;
      this.selectedBank = '';
      this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: false });
      this.GET_WITHDRAWAL(this.selectedCurrency);
      this.GET_BALANCE(this.selectedCurrency.code.toLowerCase());
      this.$refs.form.reset();
    },
    addWalletBtn() {
      if (!this.selectedCurrency.crypto) {
        this.$store.commit('withdrawal/showAddBankAccountUpdate', true);
      } else {
        this.$store.commit('withdrawal/showAddBankAccountUpdate', false);
        this.$store.commit('withdrawal/showAddWalletModalUpdate', true);
      }
    },
    addBankWalletBtn() {
      this.$store.commit('withdrawal/showAddWalletModalUpdate', true);
    },
    cancelTransfer() {
      this.$store.commit('withdrawal/CANCEL_TRANSFER');
    },
    currencySelectCustomLabel ({ name, code }) {
      return `${name} (${code})`
    },
    onSelectedPercent(percent) {
      if(!percent) {
        return;
      }
      const cleanBalance = this.withdrawalBalanceNotFormatted;
      const decimal = this.getCurrencyInputPrecision;
      if(parseFloat(cleanBalance) > 0) {
        let p = parseInt(percent);
        const calcAmount = (parseFloat(cleanBalance) * (p/100)).toFixed(decimal);
        this.$store.commit('withdrawal/updateAmount', calcAmount);
      }
    },
    onNetworkChange() {
      this.amount = null;
      this.selectedProvider = '';
      // this.GET_WITHDRAWAL(this.selectedNetwork.currency);
      this.$refs.form.reset();
    },
  },
  created() {
    this.GET_CURRENCY();

    //this.GET_WITHDRAWAL(this.selectedCurrency);
    //this.GET_BALANCE(this.selectedCurrency.code.toLowerCase());
  },
  watch: {
    withdrawCurrency: function (val) {
      this.selectedCurrency = val[0];
      this.getCoinSelection();
    }
  }
};
</script>

<style lang="scss" scoped>
.add-wallet {
  cursor: pointer;
  color: #1b65d3;
  @media only screen and (max-width: 768px) {
    padding: 20px 0 20px 5px;
    text-align: center;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
}

.available-limit {
  text-align: right;
  padding-top: 5px;
}
</style>
