<template>
    <div class="row mt-3">
        <div class="col-md-4 col-sm-12">
            <crypto-widget type="accordion" template="chart4" color="default" assets="BTC~USD,ETH~USD,LTC~USD,DASH~USD" start_expanded="false" api="cryptocompare.com" realtime="true" animation="flash" style="font-size:15px;"></crypto-widget>        
        </div>
        <div class="col-md-4 col-sm-12">
            <crypto-widget type="accordion" template="chart4" color="default" assets="STORJ~USD,CHZ~USD,DOGE~USD,SHIB~USD" start_expanded="false" api="cryptocompare.com" realtime="true" animation="flash" style="font-size:15px;"></crypto-widget>        
        </div> 
        <div class="col-md-4 col-sm-12">
            <crypto-widget type="accordion" template="chart4" color="default" assets="ADA~USD,XRP~USD,SOL~USD,HEX~USD" start_expanded="false" api="cryptocompare.com" realtime="true" animation="flash" style="font-size:15px;"></crypto-widget>
        </div>
    </div>  
</template>

<script>
export default {
    name: "AccordionWidgets",
};
</script>

<style scoped>
</style>