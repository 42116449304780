<template>
  <div class="row">
    <div id="history" class="col-12 mt-2">
      <h3 class="my-2">{{ $t('wallet-detail-wallethistory') }}</h3>
      <div class="card">
        <div class="card-content card-min-area">
          <div class="col-md-4 col-12">
            <label for="currency" class="col-form-label col-form-label-lg">{{ $t('wallet-label-cryptotype') }}</label>
            <multiselect
              v-model="selectedCurrency"
              @input="getCoinSelection"
              deselect-label=""
              select-label=""
              selected-label=""
              track-by="name"
              label="name"
              placeholder="Select one"
              :options="walletCurrency"
              :searchable="false"
              :allow-empty="false"
              open-direction="bottom"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
              <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
              <template slot="noOptions">{{ $t('noOptions') }}</template>
            </multiselect>
          </div>
          <div class="table-responsive">
            <table class="table table-hover table-xl">
              <thead>
                <tr>
                  <th class="border-top-0">{{ $t('wallet-detail-datatable-operation') }}</th>
                  <th class="border-top-0">{{ $t('wallet-detail-datatable-date') }}</th>
                  <th class="border-top-0">{{ $t('wallet-detail-datatable-price') }}</th>
                  <th class="border-top-0">{{ $t('wallet-detail-datatable-amount') }}</th>
                  <th class="border-top-0">{{ $t('wallet-detail-datatable-total') }}</th>
                  <th class="border-top-0">{{ $t('wallet-detail-datatable-status') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="isLoading">
                  <tr>
                    <td colspan="6" class="text-center loading-content">
                      <content-loader
                        :width="1000"
                        :height="63"
                        :speed="2"
                        primaryColor="#ffffff"
                        secondaryColor="#eee"
                        class="loading-bg"
                        v-for="(item, index) in 3"
                        :key="'loading-' + index"
                      >
                        <rect x="0" y="0" rx="6" ry="6" width="1000" height="83" />
                      </content-loader>
                    </td>
                  </tr>
                </template>
                <template v-else-if="history.length === 0">
                  <tr>
                    <td colspan="6" class="text-center">{{ $t('wallet-detail-datatable-noData') }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr
                    v-for="(item, index) in history"
                    :key="'history-' + index"
                    :class="['progress-status', progressStatusColors['transfer'][item.status]]"
                  >
                    <td class="text-truncate">
                      <span :class="['progress-type', item.type_code]">
                        {{ item.type }}
                      </span>
                    </td>
                    <td class="text-truncate">{{ item.created_at | dateFormat }}</td>
                    <td class="text-truncate">{{ item.price_formatted }}</td>
                    <td class="text-truncate">{{ item.amount_formatted }}</td>
                    <td class="text-truncate">{{ item.total_formatted }}</td>
                    <td class="text-truncate">
                      <span class="progress-text d-flex justify-content-start align-items-center">
                        <i class="la la-dot-circle-o font-medium-1 m-r-5"></i>
                        {{ item.status_str }}
                      </span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="col-12 text-center mt-2 mb-2" v-if="next != null">
            <button type="button" class="btn-gradient-primary" @click="getMore">
              {{ $t('wallet-detail-datatable-loadMore') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import dateFormat from '@/config/_date-format';
import Multiselect from 'vue-multiselect';
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'WalletHistory',
  data() {
    return {
      selectedCurrency: {
        code: 'TRY',
        name: this.$t('wallet-turkish-lira')
      }
    };
  },
  components: {
    Multiselect,
    ContentLoader
  },
  filters: {
    dateFormat
  },
  computed: {
    ...mapState({
      history: (state) => state.wallet.history,
      next: (state) => state.wallet.next,
      currency: (state) => state.global.currency,
      isLoading: (state) => state.global.isLoading,
      progressStatusColors: (state) => state.global.progressStatusColors
    }),
    walletCurrency: function () {
      return this.currency.filter(function (currency) {
        return currency.balance;
      });
    }
  },
  methods: {
    ...mapActions('global', ['GET_CURRENCY']),
    ...mapActions('wallet', ['GET_HISTORY']),
    getCoinSelection() {
      this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: false });
    },
    getMore() {
      if (this.next) this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: true });
    }
  },
  created() {
    this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: false });
  }
};
</script>

<style lang="scss" scoped>
.btn-status {
  cursor: default !important;
}

.card-min-area {
  min-height: 350px;
  @media only screen and (max-width: 768px) {
    min-height: 400px;
  }
}
</style>
