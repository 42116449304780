<template>
    <div class="content-wrapper">
        <Breadcrumb title="Margin Trade" />

        <div class="top-page-container remove-margins">
            <TopBlock class="mx-2" />
            <div class="row main-block mx-2">
                <LeftBlock />
                <MiddleBlock />
                <RightBlock />
            </div>
            <Positions />
            <Funds />
            <BottomBlock />
        </div>
        <WelcomeModal v-if="welcomeModal" />
        <TransferModal v-if="transferModal" />
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb';
import TopBlock from '../components/SpotTrade/TopBlock';
import LeftBlock from '@/components/SpotTrade/LeftBlock';
import RightBlock from '@/components/SpotTrade/RightBlock';
import MiddleBlock from '@/components/SpotTrade/MiddleBlock';
import BottomBlock from '@/components/SpotTrade/BottomBlock';
import Funds from '@/components/MarginTrade/Funds.vue';
import Positions from '@/components/MarginTrade/Positions.vue';

import WelcomeModal from '@/components/MarginTrade/WelcomeModal.vue';
import TransferModal from '@/components/MarginTrade/TransferModal.vue';

export default {
    name: 'MarginTrade',
    components: { 
        Breadcrumb,
        TopBlock,
        LeftBlock,
        MiddleBlock,
        RightBlock,
        BottomBlock,
        Funds,
        Positions,
        WelcomeModal,
        TransferModal,
    },
    computed: {
        ...mapState({
            welcomeModal: state => state.margintrade.welcomeModal === 'true',
            transferModal: state => state.margintrade.transferModal,
        }),
        ...mapGetters('spottrade', ['allPairs']),
    },
    async beforeMount() {
        await this.FETCH_ALL_PAIRS();
        this.$store.dispatch('spottrade/SELECT_PAIR', this.allPairs[0]);
    },
    methods: {
        ...mapActions('spottrade', ['FETCH_ALL_PAIRS']),
        ...mapActions('global', ['GET_CURRENCY']),
        ...mapActions('margintrade', ['FETCH_MARGIN_WALLETS', 'FETCH_POSITIONS', 'TRANSFER_CROSS_WALLET_CURRENCIES']),
    },
    async created() {
        this.GET_CURRENCY();
        this.FETCH_MARGIN_WALLETS();
        this.FETCH_POSITIONS();
        this.TRANSFER_CROSS_WALLET_CURRENCIES();
    }
};
</script>

<style lang="scss" scoped>

.remove-margins {
    margin: 0 -2.2rem;
}

.main-block {
    min-height: 970px;
    height: 100%;
    overflow-y: hidden;
    padding-bottom: 1rem;
}
</style>
