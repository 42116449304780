<template>
    <div class="table-responsive col-2 card m-0 p-0 h-auto overflow-scroll">
        <table class="table">
            <thead>
                <tr>
                    <th class="text-center">{{ $t('spotTrade.pair') }}</th>
                    <th class="text-right">{{ $t('commons.price') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(pair, index) in allPairs" :key="'pair-' + index" @click="onPairSelected(pair)"
                    @mouseover="onMouseOver(pair)" @mouseleave="onMouseLeave(pair)"
                    :class="{ 'pair-hover': pair.id == hoveredPair || selectedPair.id === pair.id }">
                    <td class="text-center">{{ pair.name }}</td>
                    <td class="text-right">{{ pair.price | currency('', 6) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import currency from '@/config/_currency';

export default {
    name: 'RightBlock',
    data() {
        return {
            hoveredPair: ""
        }
    },
    filters: {
        currency
    },
    computed: {
        ...mapGetters('spottrade', ['allPairs', 'selectedPair'])
    },
    methods: {
        ...mapActions('spottrade', ['SELECT_PAIR', 'SET_PAIR_BALANCE_BY_DIRECTION']),
        onPairSelected(pair) {
            this.SELECT_PAIR(pair);
            this.SET_PAIR_BALANCE_BY_DIRECTION();
        },
        onMouseOver(pair) {
            this.hoveredPair = pair.id;
        },
        onMouseLeave() {
            this.hoveredPair = "";
        }

    }
};
</script>
<style lang="scss" scoped>
.pair-hover {
    cursor: pointer;
    color: #000;
    text-shadow: 0.5px 0.5px;
}
</style>