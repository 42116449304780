import i18n from './_i18n'

/**
 * 
 * @param {*} res | server response
 * @returns 
 */
export const getSuccessServiceMsg = (res) => {
    if(res?.data?.message) {
        const _key = `service.${res?.data?.message}`;
        return i18n.te(_key) ? i18n.t(_key) : res?.data?.message;
    }
    let msgKey;
    if(res?.data?.message_key) msgKey = `service.${res?.data?.message_key}`;
    else if(res?.data?.message?.message_key) msgKey = `service.${res?.data?.message?.message_key}`;
    else msgKey = 'service.operation_success';
    return i18n.te(msgKey) ? i18n.t(msgKey) : i18n.t('service.operation_success')
}

/**
 * 
 * @param {*} res | server response
 * @returns 
 */
export const getErrorServiceMsg = (res) => {
    if(res?.data?.message) {
        const _key = `service.${res?.data?.message}`;
        return i18n.te(_key) ? i18n.t(_key) : res?.data?.message;
    }
    let msgKey;
    if(res?.data?.message_key) msgKey = `service.${res?.data?.message_key}`;
    else if(res?.data?.message?.message_key) msgKey = `service.${res?.data?.message?.message_key}`;
    else msgKey = 'service.fatal_error';
    return i18n.te(msgKey) ? i18n.t(msgKey) : i18n.t('service.fatal_error');
}