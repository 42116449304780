import Vue from 'vue';
const moment = require('moment');

import API from '@/api';
import Services from '@/config/_axios';
import { getSuccessServiceMsg, getErrorServiceMsg } from '@/config/serviceMessages';

const striker = {
  state: () => ({
    strikerList: [],
    strikerMyList: [],
    skmatch: [],
    selectedStrikerId: '',
    isLoading: true,
    status_striker_list: {
      countCanceled: 0,
      countActive: 0,
      countFinish: 0
    },
    topStrikers: []
  }),
  mutations: {
    SET_STRIKER(state, payload) {
      state.strikerList = [];
      for (let key in payload) {
        let object = payload[key];
        var actionTime = moment(object.signDate?.date, 'YYYY-MM-DD HH:mm:ssZ');
        var timeAgo = actionTime.fromNow();
        state.strikerList.push(Object.assign({ time_ago: timeAgo }, payload[key]));
      }
    },
    SET_MY_STRIKER(state, payload) {
      state.strikerMyList = [];
      for (let key in payload) {
        let object = payload[key];
        var actionTime = moment(object.signDate?.date, 'YYYY-MM-DD HH:mm:ssZ');
        var timeAgo = actionTime.fromNow();
        state.strikerMyList.push(Object.assign({ time_ago: timeAgo }, payload[key]));
      }
    },
    SET_SKMATCH(state, payload) {
      state.skmatch = [];
      for (let key in payload) {
        let object = payload[key];
        var actionTime = moment(object.end_date?.date, 'YYYY-MM-DD HH:mm:ssZ');
        var timeAgo = actionTime.fromNow();
        state.skmatch.push(Object.assign({ time_ago: timeAgo }, payload[key]));
      }
    },
    SET_STRIKER_ID(state, payload) {
      state.selectedStrikerId = payload;
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_STRIKER_STATUS_LIST(state, payload) {
      state.status_striker_list = payload;
    },
    SET_TOP_STRIKERS(state, payload) {
      state.topStrikers = payload;
    }
  },
  actions: {
    async GET_STRIKER({ commit }, params) {
      commit('IS_LOADING', true);
      await Services.get(API.striker, params)
        .then((res) => {
          commit('SET_STRIKER', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async GET_STRIKER_STATUS_LIST({ commit }) {
      commit('IS_LOADING', true);
      await Services.get(API.striker_status_count_list)
        .then((res) => {
          if (res.data?.data && res.data.data != 0) commit('SET_STRIKER_STATUS_LIST', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async GET_MY_STRIKER({ commit }, params) {
      commit('IS_LOADING', true);
      await Services.get(API.strikerMy, { params: params || null })
        .then((res) => {
          commit('SET_MY_STRIKER', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async GET_STRIKER_TV({ commit }) {
      await Services.get(`${API.striker}?limit=10&order=DESC`)
        .then((res) => {
          commit('SET_STRIKER', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async GET_LAST_TRANSACTION({ commit }, params) {
      commit('IS_LOADING', true);
      await Services.get(API.skmatch, params)
        .then((res) => {
          commit('SET_SKMATCH', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          commit('IS_LOADING', false);
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async POST_STRIKER({ commit, dispatch }, params) {
      commit('IS_LOADING', true);
      try {
        const res = await Services.get(API.striker_order, {
          params
        });
        const { success } = res.data;
        if (!success) {
          Vue.$toast.error(getErrorServiceMsg(res));
          return "error";
        }
        Vue.$toast.success(getSuccessServiceMsg(res));
        dispatch('GET_STRIKER_STATUS_LIST');
        dispatch('GET_MY_STRIKER');
        dispatch('GET_STRIKER');
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('IS_LOADING', false);
      }
    },
    POST_STRIKER_NEW({ commit }, params) {
      commit('IS_LOADING', true);
      Services.post(API.striker_new, params)
        .then((res) => {
          commit('IS_LOADING', false);
          commit('keeper/statusModal', false, { root: true });
          if (res.data.success) {
            Vue.$toast.success(getSuccessServiceMsg(res));
            this.dispatch('keeper/GET_KEEPER', { root: true });
          } else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    DELETE_STRIKER({ commit }, id) {
      const data = {
        striker_id: id
      };
      Services.post(`${API.striker_rollback}`, data)
        .then((res) => {
          commit('IS_LOADING', false);
          if (res.data.success) {
            Vue.$toast.success(getSuccessServiceMsg(res));
            this.dispatch('striker/GET_STRIKER_STATUS_LIST', { root: true });
            this.dispatch('striker/GET_MY_STRIKER', { root: true });
          } else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    TRANSFER({ commit, dispatch }, id) {
      const data = {
        striker_id: id
      };
      Services.post(`${API.striker_transfer}`, data)
        .then((res) => {
          commit('IS_LOADING', false);
          if (res.data.success == true) {
            Vue.$toast.success(getSuccessServiceMsg(res));
            dispatch('GET_STRIKER');
          } else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    FETCH_TOP_STRIKERS({ commit }) {
      Services.get(`${API.top_strikers}`)
        .then((res) => {
          commit('SET_TOP_STRIKERS', res.data?.data || [])
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        })
        .finally(() => {
          commit('IS_LOADING', false);
        })
    }
  },
  namespaced: true
};

export default striker;
