<template>
  <div>
    <img class="responsive-img" :src="comingSoonImgPath" alt="" />
    <v-style>
      html body, #app {
        background-image: linear-gradient(180deg, #6d1983 5% 10%, #530e63 45% 100%) !important;
      }
    </v-style>
  </div>
</template>

<script>
import Vue from 'vue';
Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
});
export default {
  name: 'ComingSoon',
  data() {
    return {
        comingSoonImgPath: require('@/assets/images/pages/mcm-coming-soon.jpg'),
    };
  },
};
</script>
<style lang="scss" scoped>
.responsive-img {
  width: 80%;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
