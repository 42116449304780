<template>
  <div class="card-body">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form method="post" @submit.prevent="handleSubmit(upgradeThirdLevel)">
        <div class="col-12" v-if="selectedCountry">
          <div class="row m-1">
            <label class="col-sm-4 col-form-label col-form-label-lg font-small-3">
              {{ $t('accountSettings-country') }}
            </label>
            <div class="col-sm-8 col-form-label col-form-label-lg font-small-3">
              {{ selectedCountry.label }}
            </div>
          </div>
        </div>
        <div class="col-12" v-else>
          <validation-provider
            rules="required"
            :name="$t('accountSettings-country')"
            v-slot="{ errors }"
            class="row m-1"
          >
            <label for="country" class="col-sm-4 col-form-label col-form-label-lg font-small-3">
              {{ $t('accountSettings-country') }}
            </label>
            <div class="col-sm-8">
              <multiselect
                v-model="selectedCountry"
                :placeholder="$t('accountSettings-chooseCountry')"
                track-by="label"
                :options="countryCodes"
                :option-height="104"
                :show-labels="false"
                @input="getFormToCountry"
                class="multiselectRegular"
                :allow-empty="false"
                open-direction="bottom"
              >
                <template slot="singleLabel" slot-scope="props">
                  <div class="option">
                    <span class="option__title">{{ props.option.label }}</span>
                  </div>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option">
                    <span class="option__title">{{ props.option.label }}</span>
                  </div>
                </template>
                <template slot="noOptions">{{ $t('noOptions') }}</template>
              </multiselect>
              <span class="error__message">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>

        <div v-if="selectedCountry">
          <div class="col-12">
            <div class="row m-1">
              <label class="col-sm-4 col-form-label col-form-label-lg font-small-3">
                {{ $t('accountSettings-name') }}
              </label>
              <div class="col-sm-8 col-form-label col-form-label-lg font-small-3">{{ user.name }}</div>
            </div>
          </div>
          <div class="col-12">
            <div class="row m-1">
              <label class="col-sm-4 col-form-label col-form-label-lg font-small-3">
                {{ $t('accountSettings-surname') }}
              </label>
              <div class="col-sm-8 col-form-label col-form-label-lg font-small-3">
                {{ user.surname }}
              </div>
            </div>
          </div>
          <div class="col-12">
            <validation-provider
              rules="required"
              :name="$t('accountSettings-docType')"
              v-slot="{ classes, errors }"
              class="row m-1"
            >
              <label class="col-sm-4 col-form-label col-form-label-lg font-small-3">
                {{ $t('accountSettings-docType') }}
              </label>
              <div class="col-sm-8">
                <multiselect
                  v-model="selectedDocumentTypes"
                  deselect-label=""
                  select-label=""
                  selected-label=""
                  track-by="name"
                  :placeholder="$t('accountSettings-chooseDocType')"
                  :options="documentTypes"
                  :searchable="false"
                  :allow-empty="false"
                  :class="classes"
                  open-direction="bottom"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{ $t(`${option.name}`) }}</template>
                  <template slot="option" slot-scope="{ option }">{{ $t(`${option.name}`) }}</template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider
              rules="required|img_required"
              :name="$t('accountSettings-frontPhoto')"
              v-slot="{ classes, errors, validate }"
            >
              <div class="row m-1">
                <label class="col-sm-4 col-form-label col-form-label-lg font-small-3">
                  {{ $t('accountSettings-frontPhoto') }}
                </label>
                <div class="col-sm-8">
                  <span class="btn col-md-4 btn-outline-info round btn-file" for="front_photo" :class="classes">
                    {{ $t('browseFile') }}
                    <input
                      id="front_photo"
                      ref="front_photo"
                      @change="validate"
                      type="file"
                      class="custom-input"
                      accept="image/*"
                      :class="classes"
                    />
                  </span>
                  <span class="fileName" v-if="$refs.front_photo && $refs.front_photo.files[0]">
                    {{ $refs.front_photo.files[0].name }}
                  </span>
                  <span class="error__message">{{ errors[0] }}</span>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              rules="required|img_required"
              :name="$t('accountSettings-backPhoto')"
              v-slot="{ classes, errors, validate }"
            >
              <div class="row m-1">
                <label for="back_photo" class="col-sm-4 col-form-label col-form-label-lg font-small-3">
                  {{ $t('accountSettings-backPhoto') }}
                </label>
                <div class="col-sm-8">
                  <span class="btn col-md-4 btn-outline-info round btn-file" :class="classes">
                    {{ $t('browseFile') }}
                    <input
                      id="back_photo"
                      ref="back_photo"
                      name="back_photo"
                      @change="validate"
                      type="file"
                      class="custom-input"
                      accept="image/*"
                      :class="classes"
                    />
                  </span>

                  <span class="fileName" v-if="$refs.back_photo && $refs.back_photo.files[0]">
                    {{ $refs.back_photo.files[0].name }}
                  </span>
                  <span class="error__message">{{ errors[0] }}</span>
                </div>
              </div>
            </validation-provider>
            <validation-provider
              rules="required|img_required"
              :name="$t('accountSettings-livePhoto')"
              v-slot="{ classes, errors, validate }"
            >
              <div class="row m-1">
                <label for="back_photo" class="col-sm-4 col-form-label col-form-label-lg font-small-3">
                  {{ $t('accountSettings-livePhoto') }}
                </label>
                <div class="col-sm-8">
                  <span class="btn col-md-4 btn-outline-info round btn-file" :class="classes">
                    {{ $t('browseFile') }}
                    <input
                      id="live_photo"
                      ref="live_photo"
                      name="live_photo"
                      @change="validate"
                      type="file"
                      class="custom-input"
                      accept="image/*"
                      :class="classes"
                    />
                  </span>
                  <span class="fileName" v-if="$refs.live_photo && $refs.live_photo.files[0]">
                    {{ $refs.live_photo.files[0].name }}
                  </span>
                  <span class="error__message">{{ errors[0] }}</span>
                </div>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="row text-center mt-2">
          <div class="col-12 text-center mt-2 order-2 order-sm-1" :class="selectedCountry == null ? '' : 'col-sm-6'">
            <button type="button" class="btn-gradient-secondary btn-block" @click="cancelUpgrade">
              {{ $t('accountSettings-btnCancel') }}
            </button>
          </div>
          <div class="col-12 col-sm-6 text-center mt-2 order-1 order-sm-2">
            <button
              :type="isLoading ? 'button' : 'submit'"
              class="btn-gradient-primary btn-block"
              v-if="selectedCountry"
            >
              <template v-if="isLoading">
                {{ $t('accountSettings-btnSending') }}
              </template>
              <template v-else>
                {{ $t('accountSettings-btnSave') }}
              </template>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  name: 'ThirdLevelUpgrade',
  data() {
    return {
      selectedCountry: null,
      selectedDocumentTypes: null
    };
  },
  components: {
    Multiselect
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.account.level_3.isLoading,
      formDatas: (state) => state.account.level_3.formDatas,
      countryCodes: (state) => state.account.countryCodes,
      documentTypes: (state) => state.account.level_3.documentTypes,
      user: (state) => state.customer.user
    })
  },
  methods: {
    ...mapActions('account', ['GET_COUNTRY_CODES', 'GET_DOCUMENTS', 'GET_DOCUMENT_TYPE', 'POST_THIRD_LEVEL']),
    getFormToCountry() {
      if (this.selectedCountry) {
        this.GET_DOCUMENT_TYPE(this.selectedCountry.countryCode);
      }
    },
    upgradeThirdLevel() {
      this.formDatas.DocumentFrontImage = this.$refs.front_photo.files[0];
      this.formDatas.DocumentBackImage = this.$refs.back_photo.files[0];
      this.formDatas.LivePhoto = this.$refs.live_photo.files[0];
      this.formDatas.CountryCode = this.selectedCountry.countryCode;
      this.formDatas.FirstGivenName = this.user.name;
      this.formDatas.FirstSurName = this.user.surname;
      this.formDatas.DocumentType = this.selectedDocumentTypes.name;
      this.POST_THIRD_LEVEL();
    },
    cancelUpgrade() {
      this.$store.commit('account/cancelUpgrade', false);
    }
  },
  created() {
    if (this.user?.country_code && this.user?.country) {
      this.selectedCountry = {};
      this.selectedCountry.countryCode = this.user.country_code.toUpperCase();
      this.selectedCountry.label = this.user.country;
    }

    this.getFormToCountry();

    this.GET_COUNTRY_CODES();
    //this.GET_COUNTRY_CODES();
    //this.GET_DOCUMENTS();
    //this.selectedCountry.countryCode = this.user.country_id;
    //this.selectedCountry.label = this.user.country;
  },
  destroyed() {
    this.cancelUpgrade();
  }
};
</script>

<style scoped>
.is-invalid ~ .error__message {
  display: block;
}

[hidden] {
  display: none !important;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
</style>
