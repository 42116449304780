<template>
    <div class="row mt-3">
      <div class="col-12">
        <div id="heat-map"></div>
      </div>
    </div>
</template>

<script>
export default {
  name: "HeatMap",
  mounted() {   
    let options = {
        "width": "100%",
        "height": "500",
        "currencies": [
            "EUR",
            "USD",
            "JPY",
            "GBP",
            "CHF",
            "AUD",
            "NZD",
            "CNY",
            "TRY"
        ],
        "isTransparent": true,
        "colorTheme": "light",
        "locale": "en"
    };
    var js = document.createElement("script");
    js.async = true;    
    js.src = "https://s3.tradingview.com/external-embedding/embed-widget-forex-heat-map.js";
    js.textContent =  JSON.stringify(options);
    document.getElementById("heat-map").appendChild(js);
  },
};
</script>

<style scoped>
</style>