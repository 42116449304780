<template>
    <div class="col-12 px-0">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }" class="d-flex flex-grow-1">
            <form class="flex-grow-1 mt-1" @submit.prevent="handleSubmit(onSubmit)">
                <div class="form-group">
                    <TimesButtons @onSelectedTimes="onSelectedTimes" :default-value="timeValue" ref="timesButtons" />
                </div>
                <div class="form-group">
                    <label class="mr-sm-2">Coin</label>
                    <validation-provider rules="required" name="Coin" v-slot="{ classes, errors }"
                        class="col-6">
                        <multiselect v-model="selectedCoin" @input="onSelectCoin" deselect-label="" select-label=""
                            selected-label="" track-by="currency_id" label="currency_name"
                            :placeholder="$t('commons.choose')" :options="assetsData"
                            :allow-empty="false" open-direction="bottom" :class="classes">
                            <template slot="singleLabel" slot-scope="{ option }">{{ `${option.currency_name}` }}</template>
                            <template slot="noOptions">{{ $t('noOptions') }}</template>
                            <template slot="noResult">{{ $t('noOptions') }}</template>
                        </multiselect>
                        <span class="error__message">{{ errors[0] }}</span>
                    </validation-provider>
                </div>
                <div class="form-group px-1">
                    <div class="row justify-content-between mb-1">
                        <span>{{ $t('marginTrade.total_hourly_fee') }}</span>
                        <span class="text-dark font-weight-bold">0.01000000 {{ getSelectedCoinCode }}</span>
                    </div>
                    <div class="row justify-content-between mb-1">
                        <span>{{ $t('marginTrade.borrowed') }}</span>
                        <span class="text-dark font-weight-bold">{{ isCalculating ? '...' : debt_formatted }}</span>
                    </div>
                    <div class="row justify-content-between mb-1">
                        <span>{{ $t('marginTrade.available_debt') }}(USD)</span>
                        <span class="text-dark font-weight-bold">{{ isCalculating ? '...' : available_usd_debt_formatted }}</span>
                    </div>
                </div>
                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label class="mr-sm-2">{{ $t('commons.amount') }}</label>
                        <span>{{ $t('marginTrade.available_debt') }}: <strong>{{ isCalculating ? '...' : available_debt_formatted }}</strong><span class="transfer-small-text text-primary ml-1" @click="onClickTransfer">Transfer</span></span>
                    </div>
                    <validation-provider :rules="`required`" :name="$t('commons.amount')" v-slot="{ classes, errors }">
                        <currency-input
                            id="amount"
                            class="form-control"
                            :class="classes"
                            :distractionFree="false"
                            :precision="8"
                            v-model="amount"
                            placeholder="0.00000000"
                            @keyup="timeValue = null"
                        />
                        <span class="error__message">{{ errors[0] }}</span>
                    </validation-provider>
                </div>
                <button type="submit" class="btn-block btn-gradient-success">
                    {{ $t('commons.approve') }}
                </button>
            </form>
        </ValidationObserver>
        <ConfirmModal v-if="confirmModal" ref="confirmBorrowModal" @onCloseModal="confirmModal = false">
            <template v-slot:header>
                <h1>{{ $t('commons.approve') }}</h1>
            </template>
            <template v-slot:body>
                <div class="d-flex justify-content-between mb-1">
                    <span>{{ $t('marginTrade.total_hourly_fee') }}</span>
                    <span class="text-dark font-weight-bold">0.01000000 {{ getSelectedCoinCode }}</span>
                </div>
                <div class="d-flex justify-content-between mb-1">
                    <span>{{ $t('marginTrade.available_debt') }}(USD)</span>
                    <span class="text-dark font-weight-bold">{{ available_usd_debt_formatted }}</span>
                </div>
                <div class="d-flex justify-content-between mb-1">
                    <span>{{ $t('marginTrade.available_debt') }}</span>
                    <span class="text-dark font-weight-bold">{{ available_debt_formatted }}</span>
                </div>
                <div class="d-flex justify-content-between text-success font-weight-bold">
                    <label class="mr-sm-2">{{ $t('marginTrade.borrowing') }}</label>
                    <span>{{ amount }} {{ getSelectedCoinCode }}</span>
                </div>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-center align-items-center">
                    <button type="button" @click="onConfirmed" class="btn-gradient-success w-50" :disabled="isSubmitting">
                        {{ $t('commons.approve') }} {{ isSubmitting ? '...' : '' }}
                    </button>
                </div>
            </template>
        </ConfirmModal>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import TimesButtons from '@/components/global/TimesButtons.vue';
import {mapGetters, mapActions, mapState} from 'vuex';
import {OrderWalletType} from '@/enums/spot-trade-order.enum';
import {cryptoAmountFormatter} from '@/config/_currency';
import {getErrorServiceMsg} from '@/config/serviceMessages';
import ConfirmModal from '@/components/global/Modal';


export default {
    name: 'BorrowForm',
    components: {Multiselect, TimesButtons, ConfirmModal},
    filters: {
        amountFormatter: cryptoAmountFormatter
    },
    data() {
        return {
            isSubmitting: false,
            isCalculating: false,
            selectedCoin: null,
            balanceLoading: false,
            amount: null,
            available_debt: 0,
            available_debt_formatted: '0.00000000',
            debt_formatted: '0.00000000',
            available_usd_debt_formatted: '$ 0',
            timeValue: null,
            confirmModal: false,
        };
    },
    computed: {
        ...mapGetters('margintrade', ['selectedWalletType', 'crossWalletAssets', 'isolatedWalletAssets']),
        ...mapGetters('spottrade', ['allPairs', 'selectedPair']),
        ...mapState({
            commonCurrencies: (state) => state.global.currency,
        }),
        isIsolated() {
            return this.selectedWalletType === OrderWalletType.Isolated;
        },
        assetsData() {
            if (this.isIsolated) {
                return this.isolatedWalletAssets?.filter(asset => `${asset?.pair_id}` === `${this.selectedPair?.id}`);
            }
            return this.crossWalletAssets;
        },
        getSelectedCoinCode() {
            if(this.selectedCoin && this.commonCurrencies && this.commonCurrencies.length > 0) {
                const findCurrency = this.commonCurrencies.find(val => `${val.id}` === `${this.selectedCoin.currency_id}`);
                return findCurrency?.code || this.selectedCoin.currency_name;
            }
            return '';
        }
    },
    methods: {
        ...mapActions('margintrade', ['BORROW', 'CALCULATE']),
        onConfirmed() {
            const formData = {
                asset_id: this.selectedCoin.id,
                amount: `${this.amount}`
            }
            this.isSubmitting = true;
            this.BORROW(formData)
                .then(() => {
                    this.isSubmitting = false;
                    this.confirmModal = false;
                    this.$nextTick(() => {
                        this.resetFormAndValues();
                    });
                }).catch(() => {
                    this.isSubmitting = false;
                });
        },
        onSubmit() {
            if(parseFloat(this.amount) === 0) {
                const message = this.$t('veeValidateExtraMsgs.must_be_bigger_than_zero', { field: this.$t('commons.amount') });
                this.$refs.form?.setErrors({[this.$t('commons.amount')]: message});
                this.$toast.error(message);
                return;
            }
            if(parseFloat(this.amount) > parseFloat(this.available_debt)) {
                const message = this.$t('veeValidateExtraMsgs.max_amount', { amount: this.available_debt_formatted });
                this.$refs.form?.setErrors({[this.$t('commons.amount')]: message});
                this.$toast.error(message);
                return;
            }
            this.confirmModal = true;
        },
        async onSelectCoin() {
            // console.log(this.selectedCoin);
            this.isCalculating = true;
            this.$store.commit('margintrade/SET_WALLET_RISK_RATE_ID', this.selectedCoin?.wallet_id)
            try {
                if(this.selectedCoin?.id) {
                    const calcRes = await this.CALCULATE(this.selectedCoin.id);
                    this.available_debt = calcRes.data?.available_debt || 0;
                    this.available_debt_formatted = calcRes.data?.available_debt_formatted || '0.00000000';
                    this.debt_formatted = calcRes.data?.debt_formatted || '0.00000000';
                    this.available_usd_debt_formatted = calcRes.data?.available_usd_debt_formatted || '$ 0';
                }
                this.isCalculating = false;
            } catch (error) {
                this.$toast.error(getErrorServiceMsg(error.response));
                this.isCalculating = false;
            }
        },
        coinCustomLabel({ name, code }) {
            return `${name} (${code})`;
        },
        onSelectedTimes(timesVal) {
            if(!this.selectedCoin) {
                this.$refs.form?.setErrors({ Coin: this.$t('veeValidateExtraMsgs.field_is_required', {field: 'Coin'}) });
                return;
            }
            // console.log(timesVal)
            if(timesVal) {
                this.timeValue = timesVal.value;
                const assetAvailableValue = this.selectedCoin?.available ? parseFloat(this.selectedCoin.available) : 0;
                const getCalcuatedAmount = this.calculateBorrowing(this.timeValue, assetAvailableValue);
                this.amount = getCalcuatedAmount ? parseFloat(getCalcuatedAmount) : 0;
            }
        },
        calculateBorrowing(time, amount) {
            return parseFloat((time*parseFloat(amount)) - parseFloat(amount)).toFixed(8);
        },
        
        onClickTransfer() {
            this.$store.commit('margintrade/TOGGLE_TRANSFER_MODAL', true);
        },
        resetFormAndValues() {
            this.selectedCoin = null;
            this.amount = null;
            this.timeValue = null;
            this.available_debt = 0;
            this.available_debt_formatted = '0.00000000';
            this.debt_formatted = '0.00000000';
            this.available_usd_debt_formatted = '$ 0';
            this.$refs.form.reset();
        }
    },
    watch: {
        selectedWalletType: function(newValue, oldValue) {
            if(newValue !== oldValue) {
                this.resetFormAndValues();
            }
        },
        selectedPair: function(newValue, oldValue) {
            if(newValue !== oldValue) {
                this.resetFormAndValues();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.transfer-small-text {
    font-weight: bold;
    text-decoration: underline;
    &:hover {
        opacity: 0.8;
        cursor: pointer;
        text-decoration: none;
    }
}
</style>
