export const OrderDirection = {
    Buy: 0,
    Sell: 1,
    Borrow: 2,
    // Repay: 3
};

export const OrderType = {
    Limit: 0,
    Market: 1
};

export const OrderWalletType = {
    Cross: 1,
    Isolated: 0
};
