<template>
  <div class="d-flex justify-content-between">
    <button
      type="button"
      v-for="p in percents"
      :key="p.value"
      style="width: 22%"
      class="btn btn-sm bg-lighten-1 font-weight-bold"
      :class="selected && selected.value === p.value ? 'bg-success text-white' : 'text-secondary'"
      @click="selectPercent(p)"
    >
      {{ `~${p.label}%` }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'PercentButtons',
  data() {
    return {
      percents: [
        {label: '25', value: 25},
        {label: '50', value: 50},
        {label: '75', value: 75},
        {label: '100', value: 99}
      ],
      selected: null
    };
  },
  methods: {
    selectPercent(percent) {
      if (percent === this.selected) {
        this.selected = null;
        this.$emit('onSelectedPercent', null);
        return;
      }
      this.selected = percent;
      this.$emit('onSelectedPercent', percent.value);
    }
  }
};
</script>
