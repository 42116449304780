<template>
  <div class="row">
    <!-- <ProgressBar shape="line" :progressValue="0.7" background="#16cce1" /> -->
    <div id="history" class="col-12 mt-2">
      <h3 class="my-2">{{ $t('withdrawal-withdrawalHistory') }}</h3>
      <div class="card">
        <div class="card-content">
          <div class="table-responsive">
            <table class="table table-hover table-xl">
              <thead>
                <tr>
                  <th class="border-top-0">{{ $t('withdrawal-datatable-date') }}</th>
                  <th class="border-top-0">{{ $t('withdrawal-datatable-operation') }}</th>
                  <th class="border-top-0">{{ $t('withdrawal-datatable-amount') }}</th>
                  <th class="border-top-0 text-center">{{ $t('withdrawal-datatable-status') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="isLoading">
                  <tr>
                    <td colspan="4" class="text-center loading-content">
                      <content-loader :width="1000" :height="63" :speed="2" primaryColor="#ffffff" secondaryColor="#eee" class="loading-bg" v-for="(item, index) in 3" :key="'loading-' + index">
                        <rect x="0" y="0" rx="6" ry="6" width="1000" height="83" />
                      </content-loader>
                    </td>
                  </tr>
                </template>
                <template v-else-if="history.length === 0">
                  <tr>
                    <td colspan="4" class="text-center">{{ $t('withdrawal-noData') }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(item, index) in history" :key="'history-' + index" :class="['progress-status', progressStatusColors['transfer'][item.status]]">
                    <td class="text-truncate">{{ item.created_at | dateFormat }}</td>
                    <td class="text-truncate">{{ item.currency }}</td>
                    <td class="text-truncate">{{ item.amount_formatted }}</td>
                    <td>
                      <ProgressBar 
                        :key="`progress-${item.code}-${index}`"
                        :val="getProgressValue(item.status)"
                        :bar-color="statusColor(item.status)"
                        :text="item.status_str"
                        :title="item.status_str"
                      />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="col-12 text-center mt-2 mb-2" v-if="next != null">
            <button type="button" class="btn-gradient-primary" @click="getMore">{{ $t('withdrawal-loadMore') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import dateFormat from '@/config/_date-format';
import { ContentLoader } from 'vue-content-loader';
import utils from '@/config/utils';
import ProgressBar from 'vue-simple-progress';
export default {
  name: 'WithdrawalHistory',
  filters: {
    dateFormat
  },
  components: {
    ContentLoader,
    ProgressBar
  },
  computed: {
    ...mapState({
      history: (state) => state.withdrawal.history,
      next: (state) => state.withdrawal.next,
      selectedCurrency: (state) => state.withdrawal.selectedCurrency,
      isLoading: (state) => state.global.isLoading,
      progressStatusColors: (state) => state.global.progressStatusColors
    })
  },
  methods: {
    ...mapActions('withdrawal', ['GET_HISTORY']),
    getMore() {
      if (this.next) this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: true });
    },
    getProgressValue: utils.getProgressValue,
    statusColor: utils.getProgressColor
  },
  created() {
    this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: false });
  }
};
</script>

<style scoped>
.progress {
  background: rgb(118 118 118 / 42%);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 40px;
  width: 30%;
}

.progress-value {
  animation: load 3s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #fff;
  height: 30px;
  width: 0;
  text-align: center;
  line-height: 30px;
}
</style>
