/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import { usdtNetworks } from './global';

const qs = require('qs');

const withdrawal = {
  state: () => ({
    selectedCurrency: null,
    wallet: [],
    history: [],
    next: '',
    banks: [],
    isLoading: true,
    series: [],
    cryptoPrice: 0,
    withdrawalBalance: 0,
    withdrawalBalanceNotFormatted: 0,
    amount: null,
    selectedBank: '',
    selectedBankCurrency: null,
    showAddBankAccount: false,
    transfer: {
      fee: '0',
      totalAmount: 0
    },
    isCrypto: false,
    isTransfer: false,
    showAddWalletModal: false,
    add: {
      provider: '',
      currency: '',
      address: '',
      bank: '',
      label: '',
      tag: ''
    },
    infoContent: {
      title: null,
      content: null
    },
    networks: usdtNetworks,
    selectedNetwork: usdtNetworks[0]
  }),
  mutations: {
    SET_BALANCE(state, payload) {
      state.withdrawalBalance = payload.data.available_formatted;
      const stringPrice = payload.data.available_formatted.replace(/[^\d.]/g, '');
      const priceFloat = parseFloat(stringPrice);
      state.withdrawalBalanceNotFormatted = priceFloat;
    },
    SET_WITHDRAWAL(state, payload) {
      state.wallet = payload.data;
    },
    SET_BUY_NOW_PRICES(state, payload) {
      state.cryptoPrice = payload.data.price_buy / 100;
    },
    SET_TRANSFER(state) {
      const stateAmount = parseFloat(state.amount);
      const isCrypto = state.selectedCurrency.crypto;
      const toFixedDigit = isCrypto ? 8 : 2;
      state.isTransfer = true;
      // const fee = 0.01; // fee will be %1 static, discussed with Mustafa and Halis on Jan 11 2024 at 02:20 PM
      const fee = 0; // Jan 22 2024 at 11:44 AM
      const feeAsPercent = fee*100;
      let totalAmount = 0;
      let expense = 0;
      if(stateAmount) {
        expense = parseFloat(stateAmount)*parseFloat(fee)
        totalAmount = parseFloat(stateAmount) - expense;
      }
      state.transfer = {
        ...state.transfer,
        fee: `${feeAsPercent}% (${expense.toFixed(toFixedDigit)})`,
        totalAmount: totalAmount.toFixed(toFixedDigit),
      }
    },
    CANCEL_TRANSFER(state) {
      state.isTransfer = false;
    },
    SET_ADD_WALLET(state) {
      state.showAddWalletModal = false;
      state.showAddBankAccount = false;
    },
    SET_CREATE_WITHDRAWAL(state) {
      state.isTransfer = false;
      state.transfer = {
        ...state.transfer,
        fee: '0',
        totalAmount: 0
      }
      state.amount = null;
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_BANKS(state, payload) {
      if (state.banks.length > 0) return;
      for (let key in payload.data) {
        state.banks.push(payload.data[key]);
      }
    },
    CLEAR_WITHDRAWAL(state) {
      state.wallet = [];
    },
    CLEAR_WALLET_FORM(state) {
      state.add = {
        provider: '',
        currency: '',
        address: '',
        bank: '',
        label: '',
        tag: ''
      };
    },
    updateAmount(state, value) {
      state.amount = value;
    },
    updateSelectedBank(state, value) {
      state.selectedBank = value;
    },
    updateIsCrypto(state, value) {
      state.isCrypto = value;
    },
    updateSelectedCurrency(state, value) {
      state.selectedCurrency = value;
      state.isCrypto = value.crypto;
    },
    showAddWalletModalUpdate(state, playload) {
      state.showAddWalletModal = playload;
    },
    showAddBankAccountUpdate(state, playload) {
      state.showAddBankAccount = playload;
    },
    SET_HISTORY(state, payload) {
      state.next = payload.payload.links.next;

      if (!payload.isNextPage) {
        state.history = [];
      }
      for (let idx in payload.payload.data) {
        state.history.push(payload.payload.data[idx]);
      }
    },
    SET_INFO_CONTENT(state, payload) {
      state.infoContent = payload;
    },
    updateSelectedNetwork(state, payload) {
      state.selectedNetwork = payload;
    }
  },
  actions: {
    GET_BALANCE({ commit }, currency) {
      Services.get(API.currencyBalance + currency)
        .then((res) => {
          commit('SET_BALANCE', res.data);
          this.dispatch('wallet/GET_BALANCE', { root: true });
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_BANKS({ commit }) {
      Services.get(API.banks)
        .then((res) => {
          commit('SET_BANKS', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_WITHDRAWAL({ commit }, currency) {
      let url;
      if (currency.crypto) {
        url = '/crypto_currency/' + currency.code.toLowerCase();
      } else {
        url = '/bank_transfer/' + currency.code.toLowerCase();
      }
      commit('CLEAR_WITHDRAWAL');
      Services.get(API.withdraw + url)
        .then((res) => {
          commit('SET_WITHDRAWAL', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_CREATE_WITHDRAWAL({ state, commit, dispatch }, formData) {
      Services.post(
        API.withdrawCreate,
        formData
      )
        .then((res) => {
          commit('SET_CREATE_WITHDRAWAL', res.data);
          Vue.$toast.success(res.data.message);
          dispatch('GET_HISTORY', { currency: state.selectedCurrency.code.toLowerCase(), isNextPage: false });
          dispatch('GET_BALANCE', state.selectedCurrency.code);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_ADD_WALLET({ state, dispatch, commit }) {
      let address;
      if (state.isCrypto) {
        address = state.add.address;
      } else {
        address = state.add.address.trim().replace(/ +/g, '');
        let addressPrefix = state.add.bank.country.code;
        if (state.add.bank.type === 'UBAN') addressPrefix = 'ct';
        address = addressPrefix + address;
      }

      let request = {
        provider: !state.isCrypto ? 'bank_transfer' : 'crypto_currency',
        currency: !state.isCrypto ? state.add.currency.code : state.selectedCurrency.code,
        address: address,
        bank: state.add.bank.id,
        label: state.add.label
      };

      let walletTag = state.add.tag.trim();
      if (walletTag !== '') {
        request.tag = walletTag;
      }

      Services.post(API.createWallet, qs.stringify(request))
        .then((res) => {
          commit('SET_ADD_WALLET', res.data);
          Vue.$toast.success(res.data.message);
          dispatch('GET_WITHDRAWAL', state.selectedCurrency);
          commit('CLEAR_WALLET_FORM');
          commit('IS_LOADING', false);
          this.$refs.form.reset();
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    DELETE_BANK({ state, dispatch, commit }, bankCode) {
      Services.delete(API.withdraw + '/' + bankCode)
        .then((res) => {
          Vue.$toast.success(res.data.message);
          dispatch('GET_WITHDRAWAL', state.selectedCurrency);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_HISTORY({ state, commit }, params) {
      let nextPage = `${API.withdrawHistory}${params.currency}?page=1`;
      if (params.isNextPage) {
        nextPage = `${state.next}&currency=${params.currency}`;
      }
      Services.get(nextPage)
        .then((res) => {
          let data = {
            payload: res.data,
            isNextPage: params.isNextPage
          };
          commit('SET_HISTORY', data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    async FETCH_CONTENT({state, commit}, code) {
      const currencyCode = code ? code : state.selectedCurrency?.code || 'TRY';
      try {
        const res = await Services.get(`${API.withdrawContent}?currency=${currencyCode}`);
        commit('SET_INFO_CONTENT', res.data.data);
      // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  },
  getters: {},
  namespaced: true
};

export default withdrawal;
