<template>
  <div class="content-wrapper">
    <Breadcrumb :title="$t('miracle_deal.title')" />
    <Marquee />
    <!-- <div class="row justify-content-center">
      <div class="col-12">
        <iframe style="width: 100%; height: 55vh; border-radius: 5px;" src="https://www.youtube.com/embed/J1kjnKOWTTw?rel=0&showinfo=0&modestbranding=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div> -->
    <!-- <div class="row mt-2">
      <div class="col-12 text-center">
        <Information />
      </div>
    </div> -->
    <router-view />
  </div>
</template>

<script>
import Breadcrumb from '@/components/layout/Breadcrumb';
import Marquee from '@/components/markets/Marquee.vue';

export default {
  name: 'Deal',
  components: {
    Breadcrumb,
    Marquee,
  },
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>
<style scoped>
.hide {
  display: none;
}
.btn {
  border-radius: 2rem;
  padding: 1rem 3rem;
  font-size: 1rem;
}
</style>
<style lang="scss">
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: #c0c0c0;
}
::-webkit-scrollbar-thumb:window-inactive {
  background:#c0c0c0;
}
::-webkit-scrollbar-thumb:hover {
  background:#e0e0e0;
}
</style>
