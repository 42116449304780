import Vue from 'vue';

const moment = require('moment');

import API from '@/api';
import Services from '@/config/_axios';
import { getSuccessServiceMsg, getErrorServiceMsg } from '@/config/serviceMessages';

const keeper = {
  state: () => ({
    keeper: [],
    isLoading: true,
    searchIsLoading: false,
    userList: [],
    keeperList: [],
    keeperMyList: [],
    showKeeperModal: false,
    selectedKeeperId: '',
    status_keeper_list: {
      countCanceled: 0,
      countActive: 0,
      countFinish: 0
    },
    topKeepers: []
  }),
  mutations: {
    SET_KEEPER(state, payload) {
      state.keeperList = [];
      for (let key in payload) {
        let object = payload[key];
        var actionTime = moment(object.processDate?.date, 'YYYY-MM-DD HH:mm:ssZ');
        var timeAgo = actionTime.fromNow();
        state.keeperList.push(Object.assign({ time_ago: timeAgo }, payload[key]));
      }
    },
    SET_MY_KEEPER(state, payload) {
      state.keeperMyList = [];
      for (let key in payload) {
        let object = payload[key];
        var actionTime = moment(object.processDate?.date, 'YYYY-MM-DD HH:mm:ssZ');
        var timeAgo = actionTime.fromNow();
        state.keeperMyList.push(Object.assign({ time_ago: timeAgo }, payload[key]));
      }
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SEARCH_IS_LOADING(state, payload) {
      state.searchIsLoading = payload;
    },
    SET_SEARCH_KEEPER(state, payload) {
      state.userList = payload;
    },
    statusModal(state, payload) {
      state.showKeeperModal = payload;
    },
    SET_KEEPER_ID(state, payload) {
      state.selectedKeeperId = payload;
    },
    SET_KEEPER_STATUS_LIST(state, payload) {
      state.status_keeper_list = payload;
    },
    SET_TOP_KEEPERS(state, payload) {
      state.topKeepers = payload;
    }
  },
  actions: {
    async GET_KEEPER({ commit }, params) {
      commit('IS_LOADING', true);
      await Services.get(`${API.keeper}`, params)
        .then((res) => {
          commit('SET_KEEPER', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async GET_KEEPER_STATUS_LIST({ commit }) {
      commit('IS_LOADING', true);
      await Services.get(API.keeper_status_count_list)
        .then((res) => {
          if (res.data?.data && res.data.data != 0) commit('SET_KEEPER_STATUS_LIST', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async GET_MY_KEEPER({ commit }, params) {
      commit('IS_LOADING', true);
      await Services.get(`${API.keeperMy}`, { params: params || null })
        .then((res) => {
          commit('SET_MY_KEEPER', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async GET_KEEPER_TV({ commit }, params) {
      await Services.get(`${API.keeper}?limit=10&order=DESC`, params)
        .then((res) => {
          commit('SET_KEEPER', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async POST_KEEPER({ commit, dispatch }, params) {
      commit('IS_LOADING', true);
      try {
        const res = await Services.get(`${API.keeper_order}`, {
          params
        });
        const { success } = res.data;
        if(!success) {
          Vue.$toast.error(getErrorServiceMsg(res));
          return "error";
        }
        Vue.$toast.success(getSuccessServiceMsg(res));
        dispatch('GET_KEEPER_STATUS_LIST');
        dispatch('GET_MY_KEEPER');
        dispatch('GET_KEEPER');
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('IS_LOADING', false);
      }
    },
    POST_KEEPER_NEW({ commit }, params) {
      commit('IS_LOADING', true);
      Services.post(`${API.keeper_new}`, params)
        .then((res) => {
          commit('IS_LOADING', false);
          commit('statusModal', false);
          if (res.data.success) {
            Vue.$toast.success(getSuccessServiceMsg(res));
            this.dispatch('striker/GET_STRIKER', { root: true });
          } else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    GET_STRIKER_LIST({ commit }, params) {
      commit('IS_LOADING', true);
      Services.get(`${API.transactions_calculate}`, params)
        .then((res) => {
          commit('IS_LOADING', false);
          Vue.$toast.success(getSuccessServiceMsg(res));
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    SEARCH_KEEPER({ commit }, params) {
      commit('SEARCH_IS_LOADING', true);
      Services.get(`${API.user_search}?type=corporate&search=${params}`)
        .then((res) => {
          commit('SEARCH_IS_LOADING', false);
          commit('SET_SEARCH_KEEPER', res.data.results);
          //Vue.$toast.success(getSuccessServiceMsg(res));
        })
        .catch((err) => {
          commit('SEARCH_IS_LOADING', false);
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    DELETE_KEEPER({ commit }, id) {
      const data = {
        keeper_id: id
      };
      Services.post(`${API.keeper_rollback}`, data)
        .then((res) => {
          commit('IS_LOADING', false);
          if (res.data.success == true) {
            Vue.$toast.success(getSuccessServiceMsg(res));
            this.dispatch('keeper/GET_KEEPER_STATUS_LIST', { root: true });
            this.dispatch('keeper/GET_MY_KEEPER', { root: true });
          } else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    TRANSFER({ commit, dispatch }, id) {
      const data = {
        keeper_id: id
      };
      Services.post(`${API.keeper_transfer}`, data)
        .then((res) => {
          commit('IS_LOADING', false);
          if (res.data.success == true) {
            Vue.$toast.success(getSuccessServiceMsg(res));
            dispatch('GET_KEEPER');
          } else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    FETCH_TOP_KEEPERS({ commit }) {
      Services.get(`${API.top_keepers}`)
        .then((res) => {
          commit('SET_TOP_KEEPERS', res.data?.data || [])
        })
        .catch((err) => {
          if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
        })
        .finally(() => {
          commit('IS_LOADING', false);
        })
    }
  },
  namespaced: true
};

export default keeper;
