<template>
    <div class="row mt-3">
        <div class="col-12">
            <crypto-widget type="comparison" template="basic2" color="violet" assets="BTC~USD,ETH~USD,LTC~USD,DASH~USD" fields="asset,change_abs_24h,change_pct_24h,total_volume_24h_to,supply,market_cap,chart" api="cryptocompare.com" realtime="true" animation="flash" markups="{name},{name},{name},{name}" urls="/crypto/{symbol_from}/{symbol_to},/crypto/{symbol_from}/{symbol_to},/crypto/{symbol_from}/{symbol_to},/crypto/{symbol_from}/{symbol_to}" period="24h"></crypto-widget>
        </div>
    </div>
</template>

<script>
export default {
    name: "Comparison",
};
</script>

<style scoped>
</style>