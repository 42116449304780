<template>
  <div>
    <div class="card-body" v-if="!$store.state.account.isHidden">
      <div class="table-responsive">
        <table class="table table-hover table-xl" v-if="bankAccountList.length">
          <thead>
            <tr>
              <th class="border-top-0">{{ $t('bankSettings-label') }}</th>
              <th class="border-top-0">{{ $t('bankSettings-bank') }}</th>
              <th class="border-top-0">{{ $t('bankSettings-account') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in bankAccountList" :key="'account-list-' + index">
              <td class="text-truncate">{{ item.label }}</td>
              <td class="text-truncate">{{ item.bank }}</td>
              <td class="text-truncate">{{ item.address }}</td>
              <td>
                <button type="button" class="btn btn-secondary btn-sm" @click="deleteBank(item.code)">
                  {{ $t('bankSettings-deleteConfirm') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="alert alert-primary" role="alert">
          {{ $t('bankSettings-noRegisteredBank') }}
        </div>
      </div>
      <div class="col-12 text-right">
        <button
          type="submit"
          class="btn-gradient-primary my-1"
          @click="$store.state.account.isHidden = !$store.state.account.isHidden"
        >
          {{ $t('bankSettings-addNewBank') }}
        </button>
      </div>
    </div>
    <div class="card-body" v-if="$store.state.account.isHidden">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form method="post" @submit.prevent="handleSubmit(saveNewBank)">
          <div class="col-12">
            <validation-provider rules="required" :name="$t('bankSettings-label')" v-slot="{ classes, errors }">
              <fieldset class="form-group">
                <label class="mr-sm-2" for="account_name">{{ $t('bankSettings-label') }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="account_name"
                  v-model="bank_settings.account_name"
                  :placeholder="$t('bankSettings-enterAccountName')"
                  :class="classes"
                />
                <span class="error__message">{{ errors[0] }}</span>
              </fieldset>
            </validation-provider>
            <validation-provider rules="required" v-slot="{ classes, errors }" :name="$t('withdrawal-label-currenciestype')">
              <fieldset class="form-group">
                <label class="mr-sm-2">{{ $t('withdrawal-label-currenciestype') }}</label>
                <multiselect
                  v-model="bank_settings.currency"
                  deselect-label=""
                  select-label=""
                  selected-label=""
                  id="currency"
                  track-by="name"
                  label="name"
                  :placeholder="$t('bankSettings-chooseCurrency')"
                  :options="getFilteredCurrency"
                  :searchable="true"
                  :allow-empty="false"
                  :class="classes"
                  open-direction="bottom"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name}` }}</template>
                  <template slot="option" slot-scope="{ option }">{{ `${option.name}` }}</template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>

                <span class="error__message">{{ errors[0] }}</span>
              </fieldset>
            </validation-provider>
            <validation-provider rules="required" :name="$t('bankSettings-bank')" v-slot="{ classes, errors }">
              <fieldset class="form-group">
                <label class="mr-sm-2">{{ $t('bankSettings-bank') }}</label>
                <multiselect
                  v-model="bank_settings.bank"
                  deselect-label=""
                  select-label=""
                  selected-label=""
                  id="banks"
                  track-by="name"
                  label="name"
                  :placeholder="$t('bankSettings-chooseBank')"
                  :options="banks"
                  :searchable="true"
                  :allow-empty="false"
                  :class="classes"
                  open-direction="bottom"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{ `${option.short}` }}</template>
                  <template slot="option" slot-scope="{ option }">{{ `${option.short}` }}</template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>
                <span class="error__message">{{ errors[0] }}</span>
              </fieldset>
            </validation-provider>

            <validation-provider
              rules="required"
              :name="$t('bankSettings-iban')"
              v-slot="{ classes, errors }"
            >
              <fieldset class="form-group position-relative">
                <label class="mr-sm-2">{{ $t('bankSettings-iban') }}</label>
                <div class="iban-country-code">{{ ibanCountryCode }}</div>
                <the-mask
                  class="form-control iban-input"
                  :class="classes"
                  v-model="bank_settings.iban"
                  :placeholder="$t('bankSettings-enterIBAN')"
                  mask="## #### #### #### #### #### ##"
                />
                <!-- <input
                  v-model="bank_settings.iban"
                  class="form-control iban-input"
                  :placeholder="$t('bankSettings-enterIBAN')"
                  :class="classes"
                  v-uppercase
                /> -->
                <span class="error__message">{{ errors[0] }}</span>
              </fieldset>
            </validation-provider>

            <div class="row justify-content-center">
              <button
                type="button"
                @click="$store.state.account.isHidden = !$store.state.account.isHidden"
                class="btn btn-outline-primary round btn-block col-5 col-md-3"
              >
                {{ $t('bankSettings-cancel') }}
              </button>
              <button type="submit" class="btn btn-gradient-primary round btn-block col-6 col-md-3 offset-1">
                {{ $t('bankSettings-btnSave') }}
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';

export default {
  name: 'Bank',
  data() {
    return {
      ibanCountryCode: 'TR'
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapState({
      banks: (state) => state.global.banks,
      bank_settings: (state) => state.account.bank_settings,
      bankAccountList: (state) => state.account.bankAccountList
    }),
    ...mapGetters('global', ['getCurrency']),

    getFilteredCurrency: function () {
      return this.getCurrency.filter(function (item) {
        return item.crypto === false;
      });
    }
  },
  methods: {
    ...mapActions('global', ['GET_BANKS', 'GET_CURRENCY']),
    ...mapActions('account', ['ADD_BANK_ACCOUNT', 'GET_BANK_ACCOUNT_LIST', 'DELETE_BANK']),

    saveNewBank() {
      const cleanIban = this.bank_settings?.iban?.replace(/ +/g, '');
      if(cleanIban.length !== 24) {
        const msg = this.$t('veeValidateExtraMsgs.length', {length: 24});
        this.$refs.form?.setErrors({[this.$t('bankSettings-iban')]: msg});
        return;
      }
      this.ADD_BANK_ACCOUNT();
    },
    deleteBank(code) {
      this.$swal({
        icon: 'warning',
        title: this.$t('bankSettings-deleteTitle'),
        text: this.$t('bankSettings-deleteAccount'),
        confirmButtonText: this.$t('bankSettings-deleteConfirm'),
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: this.$t('bankSettings-cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.DELETE_BANK(code);
        }
      });
    }
  },
  directives: {
    uppercase: {
      update(el) {
        el.value = el.value.toUpperCase();
      }
    }
  },

  created() {
    this.GET_BANKS();
    this.GET_CURRENCY();
    this.GET_BANK_ACCOUNT_LIST();
  }
};
</script>

<style scoped></style>
