/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';

const qs = require('qs');

const access = {
  state: () => ({
    isShow: {
      googleAuthenticator: false,
      sms: false,
      eMail: false,
      show: true
    },
    qaData: [],
    form: {
      verifyCode: '',
      password: ''
    }
  }),
  mutations: {
    SET_CHANGE_FORM(state, params) {
      state.isShow[params] = !state.isShow[params];
      state.isShow.show = !state.isShow.show;
      state.form.verifyCode = '';
      state.form.password = '';
    },
    SET_GOOGLE_AUTHENTICATOR(state, payload) {
      state.qaData = payload;
    }
  },
  actions: {
    GaOtpStep1({ commit }) {
      Services.get(API.authenticatorOtp)
        .then(res => {
          if (res.data.status == 'ok') {
            commit('SET_GOOGLE_AUTHENTICATOR', res.data);
            commit('SET_CHANGE_FORM', 'googleAuthenticator');
          }
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GaOtpStep2({ state, commit }) {
      const data = {
        verify_code: state.form.verifyCode
      };
      Services.post(API.authenticatorOtp, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(res => {
          if (res.data.status == 'ok') {
            commit('SET_CHANGE_FORM', 'googleAuthenticator');
            this.dispatch('customer/GET_USER', { root: true });
            Vue.$toast.success(res.data.message);
          }
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    smsOtpStep1({ commit }) {
      Services.get(API.smsOtp)
        .then(res => {
          if (res.data.status == 'ok') {
            Vue.$toast.success(res.data.message);
            commit('SET_CHANGE_FORM', 'sms');
          }
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    smsOtpStep2({ state, commit }) {
      const data = {
        verify_code: state.form.verifyCode,
        password: state.form.password
      };
      Services.post(API.smsOtp, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(res => {
          if (res.data.status == 'ok') {
            commit('SET_CHANGE_FORM', 'sms');
            this.dispatch('customer/GET_USER', { root: true });
            Vue.$toast.success(res.data.message);
          }
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    eMailOtpStep1({ commit }) {
      Services.get(API.emailOtp)
        .then(res => {
          if (res.data.status == 'ok') {
            Vue.$toast.success(res.data.message);
            commit('SET_CHANGE_FORM', 'eMail');
          }
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    eMailOtpStep2({ state, commit }) {
      const data = {
        verify_code: state.form.verifyCode,
        password: state.form.password
      };
      Services.post(API.emailOtp, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      })
        .then(res => {
          if (res.data.status == 'ok') {
            commit('SET_CHANGE_FORM', 'eMail');
            this.dispatch('customer/GET_USER', { root: true });
            Vue.$toast.success(res.data.message);
          }
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    }
  },
  getters: {},
  namespaced: true
};

export default access;
