<template>
  <div class='col-xl-4 col-lg-4 col-md-12'>
    <div class='card pull-up'>
      <div class='card-body'>
        <div class='text-center row clearfix mb-2'>
          <div class='col-12'>
            <img
              class='bg-primary bg-glow white rounded-circle p-3 d-inline-block'
              :src='user.avatar'
              :alt='user.fullname'
              v-if='user.avatar'
            />
            <i class='icon-users font-large-3 bg-icon bg-glow white rounded-circle p-3 d-inline-block' v-else></i>
          </div>
        </div>
        <h2 class='text-center'>{{ user.fullname }}</h2>
        <p class='text-center text-bold-700' :style="{ color: statusClass() }">{{ user.level_str }}</p>
      </div>
      <div class='personal-menu col-12' id="personal-menu">
        <div class='text-center mx-1'>
          <router-link to='/personal/account-settings' active-class='active'
                       class='btn btn-outline-primary round btn-block' id="accountSettings">
            {{ $t('personalMenu-accountSettings') }}
          </router-link>
        </div>
        <div class='text-center m-1'>
          <router-link to='/personal/history' active-class='active' class='btn btn-outline-primary round btn-block' id="history">
            {{ $t('personalMenu-history') }}
          </router-link>
        </div>
        <div class='text-center m-1'>
          <router-link to='/personal/bank-settings' active-class='active'
                       class='btn btn-outline-primary round btn-block' id="bankSettings">
            {{ $t('personalMenu-bankSettings') }}
          </router-link>
        </div>
        <div class='text-center m-1'>
          <router-link to='/personal/password-settings' active-class='active'
                       class='btn btn-outline-primary round btn-block' id="passwordSettings">
            {{ $t('personalMenu-passwordSettings') }}
          </router-link>
        </div>
        <div class='text-center m-1'>
          <router-link to='/personal/notification-settings' active-class='active'
                       class='btn btn-outline-primary round btn-block' id="notificationSettings">
            {{ $t('personalMenu-notificationSettings') }}
          </router-link>
        </div>
        <div class='text-center m-1'>
          <router-link to='/personal/user-login-settings' active-class='active'
                       class='btn btn-outline-primary round btn-block' id="userLoginSettings">
            {{ $t('personalMenu-userLoginSettings') }}
          </router-link>
        </div>
        <!--
        <div class="text-center m-1">
          <router-link to="/personal/access-settings" class="btn btn-outline-primary round btn-block">
            {{ $t('personalMenu-accessSettings') }}
          </router-link>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Sidebar',
  computed: {
    ...mapState({
      user: state => state.customer.user
    })
  },
  methods: {
    statusClass() {
      console.log(this.user.level)
        switch (this.user.level) {
            case 20:
              return '#ffb347';
            case 30:
              return '#89E894';
            default:
                return '#6B6F82';
        }
    }
  },
};
</script>

<style scoped>
  .bg-icon{
    background-color:#aaace0 ;
  }
</style>
