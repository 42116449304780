import i18n from './_i18n';
import iban from 'iban';
import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import utils from './utils';

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
    dirty: ['is-dirty', 'is-dirty']
  }
});

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: (_, values) => i18n.t(`validation.${rule}`, values)
  });
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: i18n.t('veeValidateExtraMsgs.passwords_do_not_match')
});
extend('strongPassword', {
  validate(value) {
    const strongRegex = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).+$');
    return strongRegex.test(value);
  },
  message: i18n.t('veeValidateExtraMsgs.strong_password')
});
extend('img_required', {
  validate(imgUrl) {
    return imgUrl !== '';
  },
  message() {
    return i18n.t('veeValidateExtraMsgs.required_field');
  }
});

extend('max_amount', {
  params: ['maxAmount'],
  validate(value, { maxAmount }) {
    return parseFloat(value) <= parseFloat(maxAmount);
  },
  message (_, {maxAmount}) {
    return i18n.t('veeValidateExtraMsgs.max_amount', { amount: maxAmount });
  }
});

extend('iban_valid', {
  validate(value) {
    return iban.isValid(value)
  },
  message(){
    return i18n.t('veeValidateExtraMsgs.invalid_iban')
  }
});

extend('custom_iban_valid', {
  validate(value) {
    return utils.isValidIBANNumber(value);
  },
  message(){
    return i18n.t('veeValidateExtraMsgs.invalid_iban')
  }
});

extend('insufficient_balance', {
  params: ['balance'],
  validate(value, {balance}) {
    return parseFloat(value) <= parseFloat(balance);
  },
  message(){
    return i18n.t('veeValidateExtraMsgs.insufficient_balance')
  }
})

extend('noEmojis', {
  message: field => i18n.t('veeValidateExtraMsgs.noEmojisOrSpecialChars', { field: field }),
  validate: value => {
    const regex = /^[a-zA-ZçÇğĞıİşŞöÖüÜ ]+$/;  // allow latin and turkish chars
    return regex.test(value);
  },
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
