import Vue from 'vue';
const moment = require('moment');

import API from '@/api';
import Services from '@/config/_axios';
import { getErrorServiceMsg } from '@/config/serviceMessages';

const skmatch = {
  state: () => ({
    skmatchList: [],
    isLoading: true
  }),
  mutations: {
    SET_SKMATCH(state, payload) {
      state.skmatchList = [];
      if (payload.length > 0) {
        state.skmatchList = payload.map((val) => ({
          ...val,
          time_ago: moment(val.end_date, 'YYYY-MM-DD HH:mm:ssZ').fromNow()
        }));
      }
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    }
  },
  actions: {
    async GET_SK_MATCH_LIST({ commit }) {
      commit('IS_LOADING', true);
      try {
        const res = await Services.get(API.skmatch_list);
        commit('SET_SKMATCH', res.data?.data);
        return res;
      } catch (err) {
        if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('IS_LOADING', false);
      }
    }
  },
  namespaced: true
};

export default skmatch;
