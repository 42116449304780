export const languages = {
  tr: {
    title: 'Türkçe',
    lang_code: 'tr',
    flag_code: 'tr'
  },
  en: {
    title: 'English',
    lang_code: 'en',
    flag_code: 'gb'
  }
  /* nl: {
      title: 'Dutch',
      lang_code: 'nl',
      flag_code: 'nl'
    },
    me: {
      title: 'Montenegrin',
      lang_code: 'me',
      flag_code: 'me'
    },
    et: {
      title: 'Eestlane',
      lang_code: 'et',
      flag_code: 'ee'
    },
    de: {
      title: 'Deutsch',
      lang_code: 'de',
      flag_code: 'de'
    },
    fr: {
      title: 'Français',
      lang_code: 'fr',
      flag_code: 'fr'
    },
    es: {
      title: 'Español',
      lang_code: 'es',
      flag_code: 'es'
    } */
};
