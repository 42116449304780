<template>
  <div class="content-header row">
    <div class="content-header-left col-md-12 col-12 mb-2 breadcrumb-new">
      <h3 class="content-header-title mb-0 d-inline-block">{{ title }}</h3>
      <div class="row breadcrumbs-top d-inline-block">
        <div class="breadcrumb-wrapper col-12">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">{{ $t('dashboard') }}</router-link>
            </li>
            <li class="breadcrumb-item active">{{ (title == 'Miracle DEAL' && lang == 'tr') ? 'MIRACLE DEAL' : title }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Breadcrumb',
  props: {
    title: String
  },
  computed: {
    ...mapState({
      lang: (state) => state.global.lang
    })
  },
};
</script>
