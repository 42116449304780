/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import i18n, { changeLanguages } from '../../config/_i18n';

const qs = require('qs');

export const disabledCurrencyCodes = ['USDTTRC','USDTPLYGN', 'USDTBNB'];
export const usdtNetworks = [
  {name: 'Ethereum (ERC20)', network: 'eth', asset_id: 4, user_balance_id: 'USDT_ERC20'},
  {name: 'Tron (TRC20)', network: 'trx', asset_id: 81, user_balance_id: 'TRX_USDT_S2UZ'},
  /* {name: 'Polygon', network: 'polygon', asset_id: 82, user_balance_id: 'USDT_POLYGON'},
  {name: 'BNB', network: 'bnb', asset_id: 86, user_balance_id: 'USDT_BSC'}, */
];

const global = {
  state: () => ({
    isLoading: true,
    maintenanceModal: localStorage.getItem('maintenanceModalStatus') || 'true',
    history: [],
    currency: [],
    crypto: [],
    banks: [],
    countries: [],
    cities: [],
    taxbranches: [],
    currenciesIsLoading: true,
    next: '',
    lang: sessionStorage.getItem('lang'),
    progressStatusColors: {
      deposit: {
        10: 'waiting',
        20: 'progressing',
        30: 'completed',
        40: 'canceled',
        50: 'error',
        60: 'waiting-finance-approval',
        70: 'waiting-user-approval'
      },
      manualTransaction: {
        10: 'waiting',
        20: 'success',
        30: 'error'
      },
      transfer: {
        10: 'waiting',
        20: 'completed',
        30: 'error'
      },
      withdraw: {
        10: 'waiting',
        20: 'progressing',
        30: 'completed',
        40: 'canceled',
        50: 'error',
        60: 'waiting-finance-approval',
        70: 'waiting-user-approval'
      },
      quicksale: {
        10: 'completed',
        20: 'waiting',
        30: 'progressing'
      }
    },
    currencyBalanceFormatted: "",
    currencyBalance: ""
  }),
  mutations: {
    SET_CURRENCY(state, payload) {
      state.currency = [];
      state.currenciesIsLoading = false;
      for (let key in payload.data) {
        let object = payload.data[key];
        /**
         * @INFO disable USDTTRC,USDTPLYGN globally
         */
        if(disabledCurrencyCodes.includes(object.code)) {
            continue
        }
        object.name = i18n.te(`service.${object.name}`) ? i18n.t(`service.${object.name}`) : object.name;
        state.currency.push(object);
      }
    },

    SET_CRYPTO(state, payload) {
      state.crypto = [];
      for (let key in payload.data) {
        let object = payload.data[key];
        if (object.crypto) {
          state.crypto.push(object);
        }
      }
    },
    SET_HISTORY(state, payload) {
      state.next = payload.payload.links.next;

      if (!payload.isNextPage) {
        state.history = [];
      }
      for (let idx in payload.payload.data) {
        state.history.push(payload.payload.data[idx]);
      }
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_BANKS(state, payload) {
      state.banks = [];
      for (let key in payload.data) {
        state.banks.push(payload.data[key]);
      }
    },
    updateLanguage() {},
    SET_COUNTRIES(state, payload) {
      for (let idx in payload.data) {
        let object = payload.data[idx];
        state.countries.push({
          title: object.name,
          desc: object.code,
          img: object.flag_png_url,
          code: object.calling_code
        });
      }
    },
    SET_CITIES(state, payload) {
      for (let idx in payload.data) {
        let object = payload.data[idx];
        state.cities.push({ title: object.name, id: object.id });
      }
    },
    SET_TAXBRANCHES(state, payload) {
      for (let idx in payload.data) {
        let object = payload.data[idx];
        state.taxbranches.push({ title: object.name, id: object.id });
      }
    },
    SET_MAINTENANCE_MODAL(state, payload) {
      state.maintenanceModal = payload;
    },
    SET_CURRENCY_BALANCE(state, payload) {
      const { available_formatted } = payload.data;
      state.currencyBalanceFormatted = available_formatted;
      const stringPrice = available_formatted.replace(/[^\d.]/g, '');
      const priceFloat = parseFloat(stringPrice);
      state.currencyBalance = priceFloat;
    },
    CLEAR_CURRENCY_BALANCE(state) {
      state.currencyBalanceFormatted = null;
      state.currencyBalance = null;
    },
  },
  actions: {
    async GET_CURRENCY({ commit }) {
      try {
        const res = await Services.get(API.currencies)
        commit('SET_CURRENCY', res.data);
        commit('SET_CRYPTO', res.data);
        commit('IS_LOADING', false);
        return res;
      } catch (err) {
        /* if (err?.response?.data?.message) {
          Vue.$toast.error(err.response.data.message);
        } */
        console.log('global/GET_CURRENCY: ', err?.response?.data);
        throw err;
      }
    },
    GET_HISTORY({ state, commit }, params) {
      let nextPage = `${API.transactions}?page=1&currency=${params.currency}`;
      if (params.isNextPage) {
        nextPage = `${state.next}&currency=${params.currency}`;
      }
      Services.get(nextPage)
        .then((res) => {
          let data = {
            payload: res.data,
            isNextPage: params.isNextPage
          };
          commit('SET_HISTORY', data);
          commit('IS_LOADING', false);
        })
        .catch((/* err */) => {
          /* if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          } */
        });
    },
    GET_BANKS({ commit }) {
      Services.get(API.banks)
        .then((res) => {
          commit('SET_BANKS', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    CHANGE_LANGUAGE({ commit }, params) {
      Services.put(API.updateLanguage, qs.stringify({ language: params.lang }))
        .then((res) => {
          Vue.$toast.success(res.data.message);
          commit('IS_LOADING', false);
          changeLanguages(params.lang, params.refresh);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    CHANGE_MAINTENANCE_MODAL_STATUS({ commit }, status) {
      commit('SET_MAINTENANCE_MODAL', status);
      localStorage.setItem('maintenanceModalStatus', status);
    },
    async GET_BALANCE_BY_CURRENCY({ commit }, currency) {
      try {
        const res = await Services.get(API.currencyBalance + currency);
        commit("SET_CURRENCY_BALANCE", res.data);
      } catch (err) {
        if (err?.response?.data?.message) {
          Vue.$toast.error(err.response.data.message);
        }
      }
    },
  },
  getters: {
    getCrypto: (state) => state.currency.filter(currency => currency.crypto && currency.quicksale),
    getCurrency: (state) => state.currency,
  },
  namespaced: true
};

export default global;
