<template>
    <transition name='fade'>
        <div class='modal show'>
            <div class='modal__backdrop'></div>
            <div class='modal__dialog'>
                <div class='modal__header'>
                    <h3 class="text-uppercase text-primary">{{ $t('marginTrade.welcome_modal_title') }}</h3>
                </div>
                <div class='modal__body'>
                    <span class="text-dark" v-html="$t('marginTrade.welcome_modal_desc')"></span>
                </div>
                <div class='modal__footer'>
                    <button
                        type="button"
                        class="btn btn-success text-uppercase font-weight-bold btn-round w-50"
                        @click="onConfirm"
                        :disabled="isSubmitting"
                    >
                        {{ $t('commons.approve') }} {{ isSubmitting ? '...' : '' }}
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>
  
<script>
export default {
    name: 'MarginTradeWelcomeModal',
    data: () => ({
        isSubmitting: false
    }),
    methods: {
        onConfirm() {
            this.isSubmitting = true;
            setTimeout(() => {
                this.isSubmitting = false;
                this.$store.commit('margintrade/TOGGLE_WELCOME_MODAL', 'false');
            }, 800)
        }
    }
}
</script>
  
<style lang='scss' scoped>
.modal {
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1031;

    &.show {
        display: block;
    }

    &__backdrop {
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    &__dialog {
        background-color: #ffffff;
        position: relative;
        width: 600px;
        margin: 100px auto;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        z-index: 2;

        @media screen and (max-width: 992px) {
            width: 90%;
        }
    }

    &__close {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    &__close:hover {
        opacity: .7;
    }

    &__header {
        padding: 35px 20px 10px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    &__body {
        padding: 10px 30px 10px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        text-align: center;
    }

    &__footer {
        padding: 10px 20px 35px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>