<template>
  <div>
    <div class="form-group">
      <label class="mr-sm-2">{{ $t('deposit-title') }}</label>
      <div class="input-group">
        <multiselect
          v-model="selectedCurrency"
          @input="getCoinSelection"
          deselect-label=""
          select-label=""
          selected-label=""
          track-by="name"
          label="name"
          :custom-label="currencySelectCustomLabel"
          :show-labels="false"
          :placeholder="$t('commons.choose')"
          :options="depositCurrency"
          :allow-empty="false"
          open-direction="bottom"
        >
          <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
          <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
          <template slot="noOptions">{{ $t('noOptions') }}</template>
        </multiselect>
      </div>
    </div>
    <!-- BEGIN::USDT providers -->
    <div class="form-group" v-if="selectedCurrency.crypto && selectedCurrency.code === 'USDT'">
      <label class="mr-sm-2">{{ $t('network') }}</label>
      <div class="input-group">
        <multiselect v-model="selectedNetwork" deselect-label="" select-label="" selected-label="" track-by="currency" :label="$t('network')" :options="networks" :allow-empty="false" open-direction="bottom">
          <template slot="singleLabel" slot-scope="{ option }">{{ option.currency }} </template>
          <template slot="option" slot-scope="{ option }">{{ option.currency }}</template>
          <template slot="noOptions">{{ $t('noOptions') }}</template>
          <template slot="noResult">{{ $t('noOptions') }}</template>
        </multiselect>
      </div>
    </div>
    <!-- END::USDT providers -->
    <template v-if="!selectedCurrency.crypto">
      <div class="d-flex mt-1">
        <div
          class="alert alert-warning"
          role="alert"
          v-html="$t('depositWithdrawOwnAccountTransferWarning')"
        ></div>
      </div>
      <ul class="nav nav-tabs d-none">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#transfer">{{ $t('deposit-section1') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#creditcard">{{ $t('deposit-section2') }}</a>
        </li>
      </ul>
      <div class="tab-content tab-content-default">
        <div class="tab-pane fade active show" id="transfer" role="tabpanel">
          <div class="form-group">
            <label class="mr-sm-2">{{ $t('bankSettings-bank') }}</label>
            <div class="row">
              <validation-provider rules="required" :name="$t('bankSettings-bank')" v-slot="{ classes, errors }" class="col-12">
                <multiselect
                  v-model="selectedBank"
                  deselect-label=""
                  select-label=""
                  selected-label=""
                  track-by="id"
                  label="name"
                  :placeholder="$t('deposit-dropdownBank')"
                  :options="bankList"
                  :searchable="false"
                  :allow-empty="false"
                  :class="classes"
                  open-direction="bottom"
                  @input="createDeposit"
                >
                  <template slot="singleLabel" slot-scope="{ option }">{{ `${option.bank}` }}</template>
                  <template slot="option" slot-scope="{ option }">{{ `${option.bank}` }}</template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>
                <span class="error__message">{{ errors[0] }}</span>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>
      <template v-if="isLoading">
        <div class="d-flex justify-content-center mt-1">
          <span class="mb-0 mr-1 font-weight-bold">{{ $t('commons.data_loading') }}</span>
        </div>
      </template>
      <div class="form-group" v-if="!isLoading && isDeposit">
        <div class="d-flex justify-content-center mt-1">
          <img id="bankLogo" :src="selectedBank.logo" />
        </div>
        <div class="d-flex justify-content-center mt-1 font-weight-bold">
          {{ transfer.provider.bank }}
        </div>
        <div class="d-flex justify-content-center align-items-center mt-1">
          <span class="mb-0 mr-1 font-weight-bold">{{ $t('deposit-bankRecipient') }}</span>
          <span class="mb-0">{{ transfer.provider.name }}</span>
          <input name="transferName" type="hidden" ref="transferName" v-model="transfer.provider.name" />
          <a class="ml-1" @click="copyInformation($refs.transferName)">
            <i class="ficon icon-layers"></i>
          </a>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-1">
          <span class="mb-0 mr-1 font-weight-bold">{{ $t('bankSettings-iban') }}</span>
          <div class="mb-0" v-html="transfer.provider.address"></div>
          <input name="transferAddress" type="hidden" ref="transferAddress" v-model="transfer.provider.address" />
          <a class="ml-1" @click="copyInformation($refs.transferAddress)">
            <i class="ficon icon-layers"></i>
          </a>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="wallet.address">
        <div class="d-flex justify-content-center mt-1">
          <span class="mb-0 mr-1 font-weight-bold">{{ $t('deposit-walletAddress') }}</span>
        </div>
        <div class="d-flex justify-content-center mt-1 position-relative">
          <qrcode-vue :value="wallet.address" :size="qrSize" level="H" class="wallet-address-qr" />
        </div>
        <div class="d-flex justify-content-center mt-1">
          <span class="walletAddress">{{ wallet.address }}</span>
          <input name="currencyWallet" type="hidden" ref="currencyWallet" v-model="wallet.address" />
        </div>
        <div class="d-flex justify-content-center mt-1">
          <button type="button" @click="copyInformation($refs.currencyWallet)" class="btn btn-blue ml-1 mb-0">
            <i class="ficon icon-layers"></i>
            {{ $t('wallet-detail-btn-copy') }}
          </button>
        </div>
        <template v-if="selectedCurrency.code === 'XRP' || selectedCurrency.code === 'XLM'">
          <div class="d-flex justify-content-center mt-3">
            <span class="font-weight-bold" v-if="selectedCurrency.code === 'XRP'">{{ $t('deposit-walletTag') }}</span>
            <span class="font-weight-bold" v-if="selectedCurrency.code === 'XLM'">{{ $t('deposit-memo') }}</span>
          </div>
          <div class="d-flex justify-content-center mt-1">
            <span>{{ targetValue }}</span>
            <input name="currencyWallet" type="hidden" ref="targetValue" v-model="targetValue" />
          </div>
          <div class="d-flex justify-content-center mt-1">
            <button type="button" @click="copyInformation($refs.targetValue)" class="btn btn-blue ml-1">
              <i class="ficon icon-layers"></i>
              {{ $t('wallet-detail-btn-copy') }}
            </button>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'DepositForm',
  components: {
    Multiselect,
    QrcodeVue
  },
  data: () => ({
    qrSize: 125
  }),
  computed: {
    ...mapState({
      currency: (state) => state.global.currency,
      isLoading: (state) => state.deposit.isLoading,
      user: (state) => state.customer.user,
      isCrypto: (state) => state.deposit.isCrypto,
      wallet: (state) => state.deposit.wallet,
      bankList: (state) => state.deposit.bankList,
      userBalance: (state) => state.deposit.userBalance,
      isDeposit: (state) => state.deposit.isDeposit,
      isTransfer: (state) => state.deposit.isTransfer,
      transfer: (state) => state.deposit.transfer,
      networks: (state) => state.deposit.networks
    }),
    selectedBank: {
      get() {
        return this.$store.state.deposit.selectedBank;
      },
      set(value) {
        this.$store.commit('deposit/updateSelectedBank', value);
      }
    },
    selectedCurrency: {
      get() {
        return this.$store.state.deposit.selectedCurrency;
      },
      set(value) {
        this.$store.commit('deposit/updateSelectedCurrency', value);
      }
    },
    selectedBankCurrency: {
      get() {
        return this.$store.state.deposit.selectedBankCurrency;
      }
    },
    amount: {
      get() {
        return this.$store.state.deposit.amount;
      },
      set(value) {
        this.$store.commit('deposit/updateAmount', value);
      }
    },
    targetValue: function () {
      return this.user.id + 1000000000;
    },
    depositCurrency: function () {
      return this.currency.filter(function (currency) {
        return currency.deposit;
      });
    },
    selectedNetwork: {
      get() {
        return this.$store.state.deposit.selectedNetwork;
      },
      set(value) {
        this.$store.commit('deposit/onChangeSelectedNetwork', value);
      }
    }
  },
  methods: {
    ...mapActions('global', ['GET_CURRENCY']),
    ...mapActions('deposit', [
      'GET_DEPOSIT_PROVIDERS',
      'GET_CURRENCY_BALANCE',
      'POST_CREATE_DEPOSIT',
      'PUT_APPROVE_DEPOSIT',
      'CANCEL_DEPOSIT',
      'GET_HISTORY'
    ]),
    getCoinSelection() {
      this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: false });
      this.GET_DEPOSIT_PROVIDERS(this.selectedCurrency.code.toLowerCase());
      this.GET_CURRENCY_BALANCE(this.selectedCurrency.code.toLowerCase());
      this.selectedBank = null;
      // this.$refs.form.reset();
    },
    createDeposit() {
      /* if (this.amount >= this.selectedBank.min_pay) {
        this.POST_CREATE_DEPOSIT();
      } else {
        Vue.$toast.error(
          this.$t('deposit-invalidAmount', {
            amount: this.selectedBank.min_pay_formatted ? this.selectedBank.min_pay_formatted : ''
          })
        );
      } */
      this.POST_CREATE_DEPOSIT();
    },
    approveDeposit() {
      this.$store
        .dispatch('deposit/PUT_APPROVE_DEPOSIT')
        .then(() => {
          this.$refs.form.reset();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelDeposit() {
      this.CANCEL_DEPOSIT();
    },
    copyInformation(params) {
      params.setAttribute('type', 'text');
      params.select();
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      params.setAttribute('type', 'hidden');
      Vue.$toast.info(this.$t('toast-copyText'));
    },
    currencySelectCustomLabel({ name, code }) {
      return `${name} (${code})`;
    }
  },
  created() {
    this.GET_CURRENCY();
    this.GET_DEPOSIT_PROVIDERS(this.selectedCurrency.code.toLowerCase());
    this.GET_CURRENCY_BALANCE(this.selectedCurrency.code.toLowerCase());
  }
};
</script>

<style lang="scss" scoped>
.walletAddress {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  height: 40px;

  &:hover {
    overflow: auto;
    white-space: inherit;
    text-overflow: unset;
  }
}

@media screen and (max-width: 768px) {
  .nav.nav-tabs {
    height: 40px;
    margin-bottom: 10px;

    .nav-item {
      height: 40px;

      a {
        line-height: 20px;
      }
    }
  }
  #bankLogo {
    width: 100%;
  }
}

.wallet-address-qr {
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  &:hover {
    transform: scale(120%);
    box-shadow: 0 0 40px 32px rgba(0, 0, 0, 0.09);
  }
}
</style>
