export default function(value, currency = 'TRY', digit = 2) {
  if(value) {
    return (
      parseFloat(value?.toString()?.replace(/,/g,"")).toLocaleString(currency === 'TRY' ? 'tr-TR' : 'en-US', {
        minimumFractionDigits: digit,
        maximumFractionDigits: digit
      }) +
      ' ' +
      currency
    );
  }
  return '';
}

export const cryptoAmountFormatter = (value) => {
  if(value) {
      return `${parseFloat(value?.toString()?.replace(/[^0-9.]/g, '')).toFixed(8)}`;
  }
  return ''
}
