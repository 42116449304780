<template>
  <div class="card-body">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form method="post" @submit.prevent="handleSubmit(upgradeTwoNdLevel)">
        <div class="col-12">
          <div class="row">
            <label for="nationalId" class="col-12 col-form-label col-form-label-lg font-small-3">{{
              $t('National ID Number')
            }}</label>
            <div class="w-100">
            <validation-provider rules="required" :name="$t('National ID Number')" v-slot="{ classes, errors }">
              <fieldset class="form-group">
                <input
                  type='number'
                  id="nationalId"
                  v-model='secondLevel.nationalId'
                  class="form-control"
                  maxlength="11"
                  minlength="11"
                  :placeholder="$t('National ID Number')"
                  :class="classes"
                  :disabled="isLoading"
                />
                <span class="error__message">{{ errors[0] }}</span>
              </fieldset>
            </validation-provider>
            </div>
          </div>
        </div>

        <div class="row text-center">
          <div class="col-md-6 col-sm-12 text-center mt-2 order-2 order-sm-1">
            <button type="button" class="btn-gradient-secondary btn-block" @click="cancelUpgrade">
              {{ $t('accountSettings-btnCancel') }}
            </button>
          </div>
          <div class="col-md-6 col-sm-12 text-center mt-2 order-1 order-sm-2">
            <button
              type="submit"
              class="btn-gradient-primary btn-block"
              :style="[isLoading && {'opacity': 0.7}]"
              :disabled="isLoading"
            >
              <template v-if="isLoading">
                {{ $t('commons.data_loading') }}
              </template>
              <template v-else>
                {{ $t('accountSettings-btnSave') }}
              </template>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <!-- <Modal
      v-if="secondLevel.showModal"
      ref="modalBuyNowName"
      :title="$t('authentication')"
      :datas="secondLevel.url"
    /> -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// import Modal from './Modal';

export default {
  name: 'SecondLevelUpgradeNew',
  components: {
    // Modal
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.account.level_2.isLoading,
      secondLevel: (state) => state.account.secondLevel,
    })
  },
  methods: {
    ...mapActions('account', ['POST_2ND_LEVEL_NEW']),
    upgradeTwoNdLevel() {
      this.POST_2ND_LEVEL_NEW();
    },
    cancelUpgrade() {
      this.$store.commit('account/cancelUpgrade', false);
    }
  },
  destroyed() {
    this.cancelUpgrade();
  }
};
</script>

<style lang="scss" scoped>
.col-form-label {
  padding-bottom: 5px;
  color: #5f5f5f;
  max-width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
