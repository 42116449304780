<template>
  <div class='row mt-3'>
    <div class='col-12'>
      <!-- TradingView Widget BEGIN -->
      <div id="chart"></div>
      <!-- TradingView Widget END -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpotTrade',
  mounted() {
      // eslint-disable-next-line no-undef
      new TradingView.widget(
        {
            "autosize": true,
            "symbol": "BITSTAMP:BTCUSD",
            "interval": "D",
            "timezone": "Etc/UTC",
            "theme": "Light",
            "style": "1",
            "locale": "en", 
            "toolbar_bg": "#f1f3f6",
            "enable_publishing": false,
            "hide_side_toolbar": false,
            "allow_symbol_change": true,
            "referral_id": "ToddChristensen",
            "container_id": "chart"
        }
    );
  },
};
</script>

<style scoped>
#chart {
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 100px);
  float: left;
  margin: auto;
}
</style>