<template>
  <div class='content-wrapper'>
    <Breadcrumb :title="$t('verify-success-title')" />
    <div class='row'>
      <div class='col-12'>
        <div class='card pull-up-only-shadow'>
          <div class='card-body'>
            <div class="row mt-1 justify-content-center">
              <div class="col-12 text-center py-4">
                <template v-if="getStatusIncomplete">
                  <i class="la la-exclamation-circle text-danger mb-2" style="font-size: 40px"></i>
                  <h6 class="mb-2" v-for="(error,errorKey) in getErrors" :key="`error-${errorKey}`">
                    {{ $t(`amani-errors.${error}`) }}
                  </h6>
                </template>
                <template v-else>
                  <i class="la la-check-circle text-success mb-2" style="font-size: 40px"></i>
                  <h3 class="mb-2">{{ $t('amani-success-id-verified') }}</h3>
                </template>
                <button class="btn btn-link font-medium-4" @click="goToAccountSettings">{{ $t('top-menu-account') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/layout/Breadcrumb';

export default {
  name: 'VerifySuccess',
  components: {
    Breadcrumb
  },
  methods: {
    goToAccountSettings() {
      this.$router.push({name: 'AccountSettings'});
    }
  },
  computed: {
    getStatusIncomplete() {
      return this.$route.query?.status && this.$route.query.status === 'incomplete'
    },
    getErrors() {
      return this.$route.query?.errors ? this.$route.query.errors.split(',') : ['500'];
    }
  },
  created() {
    this.$store.commit('account/cancelUpgrade', false);
    // this.$store.commit('account/SET_STATUS_MODAL', false);
    this.$store.dispatch('customer/GET_USER', { root: true });
    if(this.getStatusIncomplete && !this.$route.query?.errors) {
      this.goToAccountSettings();
    }
  }
};
</script>