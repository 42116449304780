<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form method="post" @submit.prevent="handleSubmit(changePassword)" novalidate="novalidate">
        <validation-provider
          rules="required|min:8|strongPassword"
          :name="$t('pwSettings-curPassword')"
          v-slot="{ classes, errors }"
          class="form-group row"
        >
          <label for="curPassword" class="col-sm-4 col-form-label col-form-label-lg">
            {{ $t('pwSettings-curPassword') }}
          </label>
          <div class="col-sm-8">
            <i
              class="showPassword"
              @click="switchPasswordType()"
              v-bind:class="passwordFieldType === 'password' ? 'passwordEyeOff' : 'passwordEye'"
            ></i>
            <input
              id="curPassword"
              :type="passwordFieldType"
              v-model="passwords.curPassword"
              class="form-control form-control-lg"
              placeholder="***********"
              :class="classes"
            />
            <span class="error__message">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider
          rules="required|min:8|strongPassword"
          :name="$t('pwSettings-newPassword')"
          v-slot="{ classes, errors }"
          class="form-group row"
        >
          <label for="curPassword" class="col-sm-4 col-form-label col-form-label-lg">
            {{ $t('pwSettings-newPassword') }}
          </label>
          <div class="col-sm-8">
            <i
              class="showPassword"
              @click="switchPasswordTypeSecond()"
              v-bind:class="passwordFieldTypeSecond === 'password' ? 'passwordEyeOff' : 'passwordEye'"
            ></i>
            <input
              id="newPassword"
              :type="passwordFieldTypeSecond"
              v-model="passwords.newPassword"
              class="form-control form-control-lg"
              placeholder="***********"
              :class="classes"
            />
            <span class="error__message">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider
          :rules="'required|min:8|password:@' + $t('pwSettings-newPassword')"
          :name="$t('pwSettings-confirmPassword')"
          v-slot="{ classes, errors }"
          class="form-group row"
        >
          <label for="curPassword" class="col-sm-4 col-form-label col-form-label-lg">
            {{ $t('pwSettings-confirmPassword') }}
          </label>
          <div class="col-sm-8">
            <input
              id="reNewPassword"
              type="password"
              v-model="passwords.reNewPassword"
              class="form-control form-control-lg"
              placeholder="***********"
              :class="classes"
            />
            <span class="error__message">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <div class="row justify-content-center">
          <button type="button" @click="cancelPassword" class="btn btn-outline-primary round btn-block col-3 col-md-3">
            {{ $t('pwSettings-cancel') }}
          </button>
          <button type="submit" class="btn btn-gradient-primary round btn-block col-8 col-md-4 offset-1">
            {{ $t('pwSettings-changePassword') }}
          </button>
        </div>

        <div class="row justify-content-end">
          <div class="col-3"></div>
          <div class="col-3"></div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'PasswordForm',
  data() {
    return {
      passwordFieldType: 'password',
      passwordFieldTypeSecond: 'password'
    };
  },
  computed: {
    ...mapState({
      passwords: (state) => state.account.passwords
    })
  },
  methods: {
    ...mapActions('account', ['POST_PASSWORD', 'CANCEL_PASSWORD']),
    changePassword() {
      this.$store
        .dispatch('account/POST_PASSWORD')
        .then(() => {
          this.$refs.form.reset();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelPassword() {
      this.CANCEL_PASSWORD();
      this.$refs.form.reset();
      this.$router.go(-1);
    },
    switchPasswordType() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    switchPasswordTypeSecond() {
      this.passwordFieldTypeSecond = this.passwordFieldTypeSecond === 'password' ? 'text' : 'password';
    }
  }
};
</script>

<style lang="scss" scoped>
.showPassword {
  right: 30px;
}

@media screen and (max-width: 768px) {
  .btn-outline-primary {
    padding: 0;
  }
}
</style>
