<template>
    <div class="card pull-up-only-shadow mb-0 mt-0">
        <div class="card-body">
            <div class="d-flex justify-content-between mb-1" v-if="isSpot">
                <section>
                    <span class="mr-1">{{ $t('wallet-availablelimit')}}</span>
                    <span class="text-bold-600 text-dark">{{ availableFormatted }}</span>
                </section>
                <section>
                    <span class="mr-1">{{ $t('commons.total')}}</span>
                    <span class="text-bold-600 text-dark">{{ totalFormatted }}</span>
                </section>
            </div>
            <div class="d-flex justify-content-between mb-1" v-if="isMargin">
                <section>
                    <span class="mr-1">{{ $t('wallet-availablelimit')}}</span>
                    <span class="text-bold-600 text-dark">{{ marginWalletBalanceBySelectedPair.available }}</span>
                </section>
                <section>
                    <span class="mr-1">{{ $t('commons.total')}}</span>
                    <span class="text-bold-600 text-dark">{{ marginWalletBalanceBySelectedPair.total }}</span>
                </section>
            </div>
            <!-- wallet type -->
            <div class="d-flex mb-1 flex-row justify-content-around border border-secondary rounded" v-if="isMargin">
                <button
                    type="button"
                    class="btn btn-link text-uppercase"
                    :class="[selectedWalletType === crossWallet ? 'selectedText' : 'textMuted']"
                    @click.prevent="setSelectedWalletType(crossWallet)"
                >
                    {{ $t('marginTrade.cross') }}
                </button>
                <div class="bgMuted rounded" style="width: 2px; height: inherit; margin: 3px 0 3px 0" />
                <button
                    type="button"
                    class="btn btn-link text-uppercase"
                    :class="[selectedWalletType === isolatedWallet ? 'selectedText' : 'textMuted']"
                    @click.prevent="setSelectedWalletType(isolatedWallet)"
                >
                    {{ $t('marginTrade.isolated') }}
                </button>
            </div>
            <!-- limit type -->
            <div class="d-flex mb-1 flex-row justify-content-around border border-secondary rounded">
                <button
                    type="button"
                    class="btn btn-link text-uppercase"
                    :class="[selectedType === typeLimit ? 'selectedText' : 'textMuted']"
                    @click.prevent="setSelectedType(typeLimit)"
                >
                    {{ $t("spotTrade.limit") }}
                </button>
                <div class="bgMuted rounded" style="width: 2px; height: inherit; margin: 3px 0 3px 0" />
                <button
                    type="button"
                    class="btn btn-link text-uppercase"
                    :class="[selectedType === typeMarket ? 'selectedText' : 'textMuted']"
                    @click.prevent="setSelectedType(typeMarket)"
                >
                    {{ $t("spotTrade.market") }}
                </button>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <div class="col-12 px-0 mb-1">
                        <div class="d-flex flex-row flex-wrap justify-content-between">
                            <div class="col-12 col-md-6 row mb-sm-2">
                                <template v-for="(value, key, index) in directions">
                                    <div :key="key" :class="{'mr-1': index !== Object.keys(directions).length-1}" v-if="hideBorrowRepayInSpot(value)">
                                        <button
                                            type="button"
                                            class="btn btn-direction"
                                            :class="[selectedOrderDirection === value ? 'font-weight-bold text-white bgPrimary' : 'btn-link bg-transparent text-dark']"
                                            @click.prevent="setSelectedOrderDirection(value)"
                                        >
                                            {{ getDirectionLabel(value) }}
                                        </button>
                                        <!-- <div
                                            style="height: 4px"
                                            class="rounded"
                                            :class="[selectedOrderDirection === value ? 'bgPrimary' : 'bgMuted']"
                                        /> -->
                                    </div>
                                </template>
                            </div>
                            <div class="col-12 col-md-6 row justify-content-md-end" v-if="isMargin">
                                <button
                                    type="button"
                                    class="btn btn-icon btn-risk ml-1"
                                    v-if="selectedOrderDirection === directionBorrow"
                                >
                                    <span class="font-weight-bold">{{ $t("marginTrade.margin_level") }}</span>
                                    <GaugeMeter :defaultValue="defaultRiskRate" />
                                    <span class="font-small-1">
                                        <strong>{{ defaultRiskRate }}</strong>
                                        <span class="font-weight-bold" :class="[getMarginLevelRiskLabelAndClass(defaultRiskRate).class]">
                                            ({{ getMarginLevelRiskLabelAndClass(defaultRiskRate).label }})
                                        </span>
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-icon btn-margin"
                                    @click="onShowTransferModal"
                                >
                                    <span class="font-weight-bold">Transfer</span>
                                    <div class="transfer-icon-container">
                                        <i class="la la-exchange" style="font-size: 38px;"></i>
                                    </div>
                                </button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <div class="d-flex mt-2">
                <template v-if="[directionBuy, directionSell].includes(selectedOrderDirection)">
                    <TransactionForm
                        :direction="selectedOrderDirection"
                        :type="selectedType"
                        :extraRequestData="buySellFormExtraRequestData"
                        ref="transactionForm"
                    />
                </template>
                <BorrowForm v-if="selectedOrderDirection === directionBorrow" />
                <RepayForm v-if="selectedOrderDirection === directionRepay" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {OrderDirection, OrderType, OrderWalletType} from '@/enums/spot-trade-order.enum';
import TransactionForm from './TransactionForm';
import BorrowForm from '@/components/MarginTrade/BorrowForm.vue';
import RepayForm from '@/components/MarginTrade/RepayForm.vue';
import GaugeMeter from '@/components/global/GaugeMeter.vue';
// import DollarCogSvg from '@/components/icons/DollarCogSVG.vue';
import isDev from '@/config/isDev';
import utils from '@/config/utils';

import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

export default {
    name: "TransactionsMain",
    components: { TransactionForm, BorrowForm, RepayForm, GaugeMeter/* , DollarCogSvg */ },
    computed: {
        ...mapGetters('spottrade', ['pairBalance', 'selectedType', 'selectedOrderDirection', 'selectedPair']),
        ...mapGetters('margintrade', [
            'selectedWalletType',
            'walletRiskRateID',
            'crossWalletAssets',
            'isolatedWalletAssets',
            'crossWallets',
            'isolatedWallets'
        ]),
        availableFormatted() {
            return this.pairBalance?.available_formatted || '0';
        },
        totalFormatted() {
            return this.pairBalance?.total_formatted || '0';
        },
        crossWallet() {
            return OrderWalletType.Cross;
        },
        isolatedWallet() {
            return OrderWalletType.Isolated;
        },
        typeLimit() {
            return OrderType.Limit;
        },
        typeMarket() {
            return OrderType.Market
        },
        directions() {
            return OrderDirection;
        },
        directionBuy() {
            return OrderDirection.Buy;
        },
        directionSell() {
            return OrderDirection.Sell;
        },
        directionBorrow() {
            return OrderDirection.Borrow;
        },
        directionRepay() {
            return OrderDirection.Repay;
        },
        isSpot() {
            return this.$route.name === 'SpotTrade';
        },
        isMargin() {
            return this.$route.name === 'MarginTrade';
        },
        marginWalletBalanceBySelectedPair() {
            const isDirectionBuy = this.selectedOrderDirection === this.directionBuy;
            const pairCoinCode = isDirectionBuy ? `${this.selectedPair?.quoteCurrency || ''}` : `${this.selectedPair?.baseCurrency || ''}`;
            const selectedPairCurrencyId = isDirectionBuy ? this.selectedPair?.base_currency_id : this.selectedPair?.currency_id;
            const selectedPairId = this.selectedPair?.id;

            let findWalletAsset = this.crossWalletAssets?.find(asset => `${asset.currency_id}` === `${selectedPairCurrencyId}`);
            let available = findWalletAsset?.available;
            let total= findWalletAsset?.total;
            if(this.selectedWalletType === this.isolatedWallet) {
                findWalletAsset = this.isolatedWalletAssets?.find(asset => `${asset.pair_id}` === `${selectedPairId}` && `${asset.currency_id}` === `${selectedPairCurrencyId}`);
                available = findWalletAsset?.available;
                total= findWalletAsset?.total;
            }
            return {
                available: available ? `${parseFloat(available).toFixed(8)} ${pairCoinCode}` : `0 ${pairCoinCode}`,
                total: total ? `${parseFloat(total).toFixed(8)} ${pairCoinCode}` : `0 ${pairCoinCode}`
            };
        },
        buySellFormExtraRequestData() {
            let margin = false, wallet_id = null;
            if(this.isMargin) {
                margin = true;
                wallet_id = this.crossWallets.length > 0 ? this.crossWallets[0]['id'] : null;
                if(this.selectedWalletType === this.isolatedWallet) {
                    wallet_id = 
                        this.isolatedWallets.length > 0 ? 
                            this.isolatedWallets.find(wallet => `${wallet.pair_id}` === `${this.selectedPair.id}`).id :
                            null;
                }
            }
            return {margin, wallet_id}
        }
    },
    data() {
        return {
            defaultRiskRate: 1,
            stompClient: null,
            stompConnected: false
        }
    },
    methods: {
        ...mapActions('spottrade', ['SET_PAIR_BALANCE_BY_DIRECTION']),
        ...mapActions('margintrade', ['SET_WALLET_TYPE']),
        setSelectedWalletType(walletType) {
            this.$store.commit('margintrade/SET_WALLET_TYPE', walletType);
        },
        setSelectedType(type) {
            // this.selectedType = type;
            this.$store.commit('spottrade/SET_SELECTED_TYPE', type);
            this.$refs?.transactionForm?.resetForm();
        },
        setSelectedOrderDirection(orderDirection) {
            
            // this.selectedOrderDirection = orderDirection;
            this.$store.commit('spottrade/SET_SELECTED_ORDER_DIRECTION', orderDirection);
            this.$refs?.transactionForm?.resetForm();
            // ex: TRX/TRY get balance
            /* if -> orderDirection is this.directionBuy
                // get quoteCurrency(TRY) balance
            if -> orderDirection is this.directionSell
                // get baseCurrency(TRX) balance
            */
            this.SET_PAIR_BALANCE_BY_DIRECTION();
        },
        onShowTransferModal() {
            this.$store.commit('margintrade/TOGGLE_TRANSFER_MODAL', true);
        },
        getDirectionLabel(key) {
            switch (key) {
                case OrderDirection.Buy:
                    return this.$t('spotTrade.buy')
                case OrderDirection.Sell:
                    return this.$t('spotTrade.sell')
                case OrderDirection.Borrow:
                    return this.$t('marginTrade.Borrow')
                case OrderDirection.Repay:
                    return this.$t('marginTrade.Repay')
                default:
                    return key;
            }
        },
        hideBorrowRepayInSpot(value) {
            if(this.isSpot && [OrderDirection.Borrow, OrderDirection.Repay].includes(value)) {
                return false;
            }
            return true;
        },
        connectSockJS() {
            this.socket = new SockJS(process.env.VUE_APP_MARGIN_TRADE_RISK_WS);

            const stomp_client = Stomp.over(this.socket, {
                debug: isDev,
                protocols: ['v12.stomp'] 
            });
            this.stompClient = stomp_client;
            stomp_client.connect(
                {},
                (/* frame */) => {
                    this.stompConnected = true;
                    // console.log(frame);
                    stomp_client.subscribe("/wallet-risk-rate", (tick) => {
                        // console.log('tick: ',tick);
                        // console.log(JSON.parse(tick.body))
                        if(this.walletRiskRateID && JSON.parse(tick.body).length > 0) {
                            const findWalletRiskRate = JSON.parse(tick.body).find(val => `${val.id}` === `${this.walletRiskRateID}`);
                            this.defaultRiskRate = findWalletRiskRate?.rating || 1;
                        }
                    });
                },
                error => {
                    console.log(error);
                    this.stompConnected = false;
                }
            );
        },
        disconnectSockJS() {
            if (this.stompClient && this.stompConnected) {
                this.stompClient.disconnect();
            }
            this.stompConnected = false;
        },
        getMarginLevelRiskLabelAndClass(value) {
            return utils.getMarginLevelStuffs(value);
        }
    },
    mounted() {
        this.connectSockJS();
    },
    destroyed() {
        this.disconnectSockJS();
    }
};
</script>

<style lang="scss" scoped>
.transaction-forms {
    margin-bottom: 9px;
}
.bgPrimary {
    background-color: #77519c;
}
.bgMuted {
    background-color: #e3cdf8;
}
.textMuted {
    color: #87719e;
    opacity: .8;
}
.selectedText {
    color: #77519c;
    font-weight: bolder;
}

.btn-margin, .btn-risk {
    display: flex;
    flex-direction: column;
    
    align-items: center;
    background: transparent;
}

.btn-risk:hover {
    cursor: default;
}

.btn-margin:hover {
    span {
        opacity: 0.88;
        color: #77519c;
    }
}

.transfer-icon-container {
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.btn-direction:hover {
    border: 1px solid #77519c;
    opacity: 0.8;
}
</style>