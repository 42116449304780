<template>
    <div class="container-fluid mt-1 mb-3">        
      <crypto-widget
        type="ticker-quotes"
        template="chart"
        color="grey"
        :assets="getAssets"
        speed="30"
        direction="left"
        pause="true"
        api="cryptocompare.com"
        realtime="true"
        animation="zoomInLeft"
      >
      </crypto-widget>
    </div>
</template>

<script>
export default {
  name: "Marquee",
  computed: {
    getAssets() {
      const assets = ["BTC", "ETH", "LTC", "DASH", "XRP", "USDT", "ETC", "BTG", "XMR"];
      return assets.map(val => `${val}~TRY`).join(',');
    },
  },
  methods: {
    createScript(url) {
      let script = document.createElement('script');
      script.src = url;
      document.body.appendChild(script);
    },
    createLink(url) {
        let head  = document.getElementsByTagName('head')[0];
        let link  = document.createElement('link');
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = url;
        head.appendChild(link);
    },
  },
  created() {
    const body = document.body;
    body.classList.add("aside-minimize");

    this.createLink('../widgets/assets/css/style.css');
    this.createScript('../widgets/assets/js/dist/app.js');
  },
};
</script>

<style scoped>
</style>