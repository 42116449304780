/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import i18n from '../../config/_i18n';
import { getErrorServiceMsg } from '../../config/serviceMessages';

const qs = require('qs');

const deposit = {
  state: () => ({
    selectedCurrency:
      i18n.locale === 'tr' ? { code: 'TRY', name: 'Türk Lirası' } : { code: 'TRY', name: 'Turkish Liras' },
    userBalance: 0,
    amount: null,
    wallet: null,
    bankList: [],
    selectedBank: null,
    selectedBankCurrency: {
      code: 'TRY',
      name: 'Banka'
    },
    transfer: {},
    isCrypto: false,
    isDeposit: false,
    history: [],
    next: '',
    isLoading: false,
    networks: [],
    selectedNetwork: null
  }),
  mutations: {
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_HISTORY(state, payload) {
      state.next = payload.payload.links.next;
      if (!payload.isNextPage) {
        state.history = [];
      }
      for (let idx in payload.payload.data) {
        state.history.push(payload.payload.data[idx]);
      }
    },
    SET_DEPOSIT(state, payload) {
      if (state.selectedCurrency.crypto === true) {
        /* const providers = payload?.data?.crypto_currency?.providers || [];
        if(providers.length > 0) {
          state.wallet = providers[0];
        } */
      } else {
        state.bankList = [];
        state.bankList = payload.data.bank_transfer.providers;
      }
    },
    SET_CURRENCY_BALANCE(state, payload) {
      state.userBalance = payload.data.available_formatted;
    },
    updateSelectedCurrency(state, value) {
      state.selectedCurrency = value;
    },
    updateSelectedBank(state, value) {
      state.selectedBank = value;
      if (value == '8') {
        state.selectedBankCurrency = 'GBP';
      } else {
        state.selectedBankCurrency = 'TRY';
      }
    },
    updateAmount(state, value) {
      state.amount = value;
    },
    CLEAR_DEPOSIT(state) {
      state.amount = null;
      state.selectedBank = null;
    },
    SET_CREATE_DEPOSIT(state, payload) {
      state.isDeposit = payload ? true : false;
      state.transfer = payload ? payload.data : payload;
    },
    onChangeSelectedNetwork(state, payload) {
      state.selectedNetwork = payload;
      state.wallet = payload;
    },
    SET_WALLET(state, wallets) {
      state.wallet = [];
      state.networks = [];
      state.selectedNetwork = null;
      if(state.selectedCurrency.crypto === true && wallets?.length > 0) {
        if(state.selectedCurrency.code === 'USDT') {
          // set networks
          state.networks = wallets;
          state.selectedNetwork = wallets[0];
        }
        state.wallet = wallets[0];
      }

    }
  },
  actions: {
    GET_HISTORY({ state, commit }, params) {
      let nextPage = `${API.depositHistory}${state.selectedCurrency.code}?page=1`;
      if (params.isNextPage) {
        nextPage = `${state.next}&currency=${state.selectedCurrency.code}`;
      }
      Services.get(nextPage)
        .then(res => {
          let data = {
            payload: res.data,
            isNextPage: params.isNextPage
          };
          commit('SET_HISTORY', data);
        })
        .catch((/* err */) => {
          // Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    GET_DEPOSIT_PROVIDERS({ commit }, currency) {
      commit('SET_CREATE_DEPOSIT', null);
      commit('IS_LOADING', true);
      Services.get(`${API.depositProviders}/${currency}`)
        .then(res => {
          commit('IS_LOADING', false);
          commit('SET_DEPOSIT', res.data);
        })
        .catch(err => {
          commit('IS_LOADING', false);
          Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },

    GET_CURRENCY_BALANCE({ commit }, currency) {
      Services.get(API.currencyBalance + currency)
        .then(res => {
          commit('SET_CURRENCY_BALANCE', res.data);
          commit('SET_WALLET', res.data?.data?.wallets || [])
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_CREATE_DEPOSIT({ state, commit }) {
      commit('IS_LOADING', true);
      Services.post(
        API.depositCreate,
        qs.stringify({
          provider_id: state.selectedBank.id,
          currency: state.selectedCurrency.code,
          // amount: state.amount
        })
      )
        .then(res => {
          commit('IS_LOADING', false);
          commit('SET_CREATE_DEPOSIT', res.data);
          // Vue.$toast.success(res.data.message);
        })
        .catch(err => {
          commit('IS_LOADING', false);
          if (err?.response?.data?.message) {
            Vue.$toast.error(getErrorServiceMsg(err.response));
          }
        });
    },
    PUT_APPROVE_DEPOSIT({ dispatch, state, commit }) {
      Services.put(`${API.depositApprove}${state.transfer.code}`)
        .then(res => {
          commit('SET_CREATE_DEPOSIT', null);
          Vue.$toast.success(res.data.message);
          commit('CLEAR_DEPOSIT');
          dispatch('GET_HISTORY', { currency: state.selectedCurrency, isNextPage: false });
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    CANCEL_DEPOSIT({ state, dispatch, commit }) {
      commit('SET_CREATE_DEPOSIT', null);
      dispatch('GET_HISTORY', { currency: state.selectedCurrency, isNextPage: false });
    },
    CANCEL_OPERATION({ state, dispatch }, code) {
      Services.delete(`${API.depositCancel}${code}`)
        .then(res => {
          Vue.$toast.success(res.data.message);
          dispatch('GET_HISTORY', { currency: state.selectedCurrency, isNextPage: false });
          //location.reload()
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    CONFIRM_OPERATION({ state, dispatch }, code) {
      Services.put(`${API.depositApprove}${code}`)
        .then(res => {
          Vue.$toast.success(res.data.message);
          dispatch('GET_HISTORY', { currency: state.selectedCurrency, isNextPage: false });
          //location.reload()
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
  },
  getters: {},
  namespaced: true
};

export default deposit;
