import API from '@/api';
import Services from '@/config/_axios';
import Vue from 'vue';
import { getErrorServiceMsg, getSuccessServiceMsg } from '@/config/serviceMessages';
import { OrderWalletType } from '@/enums/spot-trade-order.enum';


const margintrade = {
  state: () => ({
    selectedWalletType: OrderWalletType.Cross,
    welcomeModal: localStorage.getItem('marginTradeWelcome') || 'true',
    transferModal: false,
    marginWallets: {
      cross_wallet: [],
      isolated_wallets: []
    },
    crossWalletAssets: [],
    isolatedWalletAssets: [],
    positions: [],
    selectedPosition: null,
    repayModal: false,
    walletRiskRateID: null,
    transferCrossWalletCurrencies: []
  }),
  mutations: {
    SET_WALLET_TYPE(state, payload) {
      state.selectedWalletType = payload;
    },
    TOGGLE_WELCOME_MODAL(state, payload) {
      localStorage.setItem('marginTradeWelcome', payload)
      state.welcomeModal = payload;
    },
    TOGGLE_TRANSFER_MODAL(state, payload) {
      state.transferModal = payload;
    },
    SET_MARGIN_WALLETS(state, payload) {
      const cross_wallet = payload?.cross_wallet || [];
      const isolated_wallets = payload?.isolated_wallets || [];
      state.marginWallets = {cross_wallet, isolated_wallets}
      const crossAssets = []
      const isolatedAssets = []
      cross_wallet?.forEach((wallet) => {
        wallet?.assets?.forEach(asset => {
          crossAssets.push({...asset, pair_id: wallet.pair_id, wallet_id: wallet.id});
        })
      });
      isolated_wallets?.forEach((wallet) => {
        wallet?.assets?.forEach(asset => {
          isolatedAssets.push({...asset, pair_id: wallet.pair_id, wallet_id: wallet.id});
        })
      });
      state.crossWalletAssets = crossAssets;
      state.isolatedWalletAssets = isolatedAssets;
    },
    SET_POSITIONS(state, payload) {
      state.positions = payload;
    },
    SET_REPAY_MODAL(state, payload) {
      state.repayModal = payload;
    },
    ON_SELECT_POSITION(state, payload) {
      state.selectedPosition = payload;
    },
    SET_WALLET_RISK_RATE_ID(state, payload) {
      state.walletRiskRateID = payload;
    },
    SET_TRANSFER_CROSS_WALLET_CURRENCIES(state, payload) {
      state.transferCrossWalletCurrencies = payload;
    }
  },
  actions: {
    async FETCH_MARGIN_WALLETS({ commit, dispatch }) {
      try {
        const res = await Services.get(API.marginTrade.margin_wallets);
        commit('SET_MARGIN_WALLETS', res.data?.data);
        // create cross wallets if not created
        if(res.data?.data?.cross_wallet?.length === 0) {
          dispatch('CREATE_CROSS_WALLET');
        }
        return res;
      } catch (error) {
        console.log('FETCH_MARGIN_WALLETS@error ', error.response);
        throw error;
      }
    },
    async FETCH_POSITIONS({ commit }) {
      try {
        const res = await Services.get(API.marginTrade.position);
        commit('SET_POSITIONS', res.data?.data || []);
        return res;
      } catch (error) {
        return error;
      }
    },
    async CREATE_CROSS_WALLET({commit}) {
      await Services.post(API.marginTrade.create_margin_wallets, { wallet_type: 1 });
      const res = await Services.get(API.marginTrade.margin_wallets);
      commit('SET_MARGIN_WALLETS', res.data?.data);
      return 'success';
    },
    async CREATE_ISOLATED_WALLET_BY_PAIR({commit, state}, pair_id) {
      const isolatedWallets = state.marginWallets?.isolated_wallets || [];
      if(isolatedWallets.length === 0 || !isolatedWallets?.some(val => `${val.pair_id}` === `${pair_id}`)) {
        await Services.post(API.marginTrade.create_margin_wallets, {wallet_type: 0, pair_id});
        const res = await Services.get(API.marginTrade.margin_wallets);
        commit('SET_MARGIN_WALLETS', res.data?.data);
      }
    },
    async MAKE_TRANSFER({dispatch}, requestBody) {
      try {
        const res = await Services.post(API.marginTrade.transfer, requestBody);
        Vue.$toast.success(getSuccessServiceMsg(res));
        dispatch('FETCH_MARGIN_WALLETS');
        return res;
      } catch (error) {
        Vue.$toast.error(getErrorServiceMsg(error.response));
        throw error;
      }
    },
    async CALCULATE(_, assetId) {
      return await Services.get(API.marginTrade.calculate_by_asset_id(assetId));
    },
    async BORROW({dispatch}, {asset_id, amount}) {
      try {
        const res = await Services.post(API.marginTrade.position, {asset_id, amount});
        Vue.$toast.success(getSuccessServiceMsg(res));
        dispatch('FETCH_MARGIN_WALLETS');
        dispatch('FETCH_POSITIONS');
        return res;
      } catch (error) {
        Vue.$toast.error(getErrorServiceMsg(error.response));
        throw error;
      }
    },
    async REPAY({dispatch}, {position_id/* , amount */}) {
      try {
        // const res = await Services.delete(`${API.marginTrade.position}/${position_id}`, {data: {amount}});
        const res = await Services.delete(`${API.marginTrade.position}/${position_id}`);
        Vue.$toast.success(getSuccessServiceMsg(res));
        dispatch('FETCH_MARGIN_WALLETS');
        dispatch('FETCH_POSITIONS');
        return res;
      } catch (error) {
        Vue.$toast.error(getErrorServiceMsg(error.response));
        throw error;
      }
    },
    async TRANSFER_CROSS_WALLET_CURRENCIES({commit}) {
      try {
        const res = await Services.get(API.marginTrade.currencies);
        commit('SET_TRANSFER_CROSS_WALLET_CURRENCIES', res.data?.data || []);
        return res;
      } catch (error) {
        return error;
      }
    }
  },
  getters: {
    selectedWalletType: (state) => state.selectedWalletType,
    crossWallets: (state) => state.marginWallets.cross_wallet,
    isolatedWallets: (state) => state.marginWallets.isolated_wallets,
    crossWalletAssets: (state) => state.crossWalletAssets,
    isolatedWalletAssets: (state) => state.isolatedWalletAssets,
    positions: (state) => state.positions,
    selectedPosition: (state) => state.selectedPosition,
    repayModal: (state) => state.repayModal,
    walletRiskRateID: (state) => state.walletRiskRateID,
    transferCrossWalletCurrencies: (state) => state.transferCrossWalletCurrencies
  },
  namespaced: true
};

export default margintrade;
