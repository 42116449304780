<template>
    <div class="row mt-3">
        <div class="col-12">
            <crypto-widget type="table-quotes" template="basic2" color="violet" assets="BTC~USD,ETH~USD,LTC~USD,XRP~USD,DOGE~USD,DASH~USD,STORJ~USD,CHZ~USD,SUSHI~USD,UNI~USD,LINK~USD,ZIL~USD,AVAX~USD,SHIB~USD,DOT~USD" fields="logo_name_link,price,change_abs_24h,change_pct_24h,market_cap" display_header="true" display_chart="true" pagination="true" rows_per_page="5" sort_field="logo_name_link" sort_direction="asc" api="cryptocompare.com" realtime="true" animation="flash" markups="{name},{name},{name},{name}" urls="/crypto/{symbol_from}/{symbol_to},/crypto/{symbol_from}/{symbol_to},/crypto/{symbol_from}/{symbol_to},/crypto/{symbol_from}/{symbol_to}"></crypto-widget>
        </div>
    </div>
</template>

<script>
export default {
    name: "GeneralWidgets",
};
</script>

<style scoped>
</style>