<template>
  <div>
    <div class='table-responsive'>
      <table class='table table-hover table-xl'>
        <thead>
        <tr>
          <th scope='col'>{{ $t('loginSettings-transactionTime') }}</th>
          <th scope='col'>{{ $t('loginSettings-description') }}</th>
          <th scope='col'>{{ $t('loginSettings-action') }}</th>
          <th scope='col'>{{ $t('loginSettings-ipAddress') }} | {{ $t('loginSettings-agent') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for='(item, index) in actions' :key="'actions-' + index">
          <td :data-label="$t('loginSettings-transactionTime')" class='text'>{{ item.created_at | dateFormat }}</td>
          <td :data-label="$t('loginSettings-description')" class='text'>{{ item.description }}</td>
          <td :data-label="$t('loginSettings-action')" class='text'>{{ item.action }}</td>
          <td :data-label="$t('loginSettings-ipAddress')" class='text'>
            <i>{{ item.ip }}</i> <br />
            {{ item.user_agent }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class='col-12 text-center mt-2 mb-2'>
      <button type='button' class='btn-gradient-primary' @click='getMore'>{{ $t('loginSettings-loadMore') }}</button>
    </div>
  </div>
</template>

<script>
import dateFormat from '@/config/_date-format';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'UserLogin',
  filters: {
    dateFormat
  },
  computed: {
    ...mapState({
      next: state => state.account.next,
      actions: state => state.account.actions
    })
  },
  methods: {
    ...mapActions('account', ['GET_ACTIONS']),
    getMore() {
      if (this.next) this.GET_ACTIONS({ isNextPage: true });
    }
  },
  created() {
    this.GET_ACTIONS({ isNextPage: false });
  }
};
</script>

<style scoped>
@media screen and (min-width: 601px) {
  td:nth-of-type(1) {
    width: 20%;
  }

  td:nth-of-type(2) {
    width: 20%;
  }

  td:nth-of-type(3) {
    width: 20%;
  }

  td:nth-of-type(4) {
    width: 40%;
  }
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
  }

  table td::before {
    content: attr(data-label) ' : ';
    float: left;
    width: 100%;
    margin-bottom: 0.5em;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>
