<template>
  <div>
    <div class='row'>
      <div class='col-12' v-html="$t('accountSettings-limit-content')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Limit'
};
</script>
