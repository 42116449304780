<template>
    <div class="card flex-row flex-wrap justify-between py-1 px-3 mb-2">
        <div class="row align-items-center col-12 col-md-2 mb-1 mb-md-0">
            <div class="row">
                <h5 class="line-height-normal m-0">
                    {{ selectedPair?.baseCurrency ? selectedPair?.baseCurrency : ''}}
                </h5>
                <span class="line-height-normal">/ {{ selectedPair?.quoteCurrency ? selectedPair.quoteCurrency : ''}}</span>
            </div>
        </div>
        <div class="d-flex flex-column col-6 col-md-2 mb-1 mb-md-0">
            <span class="">{{ $t('spotTrade.last-price')}}</span>
            <span>{{ getLastPrice | currency('')}}</span>
        </div>
        <div class="d-flex flex-column col-6 col-md-2 mb-1 mb-md-0">
            <span class="">{{ $t('spotTrade.24h-change')}}</span>
            <section>
                <span :class="priceChangeClass">
                    {{ getPriceChange | currency('')}}
                </span>
                <span
                    v-if="getPriceChangePercent"
                    class="ml-2"
                    :class="priceChangePercentClass"
                >{{ getPriceChangePercent}}%</span>
            </section>
        </div>
        <div class="d-flex flex-column col-6 col-md-2 mb-1 mb-md-0">
            <span class="">{{ $t('spotTrade.24h-high')}}</span>
            <span>{{ getHighPrice | currency('')}}</span>
        </div>
        <div class="d-flex flex-column col-6 col-md-2 mb-1 mb-md-0">
            <span class="">{{ $t('spotTrade.24h-low')}}</span>
            <span>{{ getLowPrice | currency('')}}</span>
        </div>
        <div class="d-flex flex-column col-6 col-md-2 mb-1 mb-md-0">
            <span class="">{{ $t('spotTrade.24h-volume')}}</span>
            <span>{{getTotalTradedQuoteAssetVolume | currency('')}}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import currency from '@/config/_currency';

export default {
    name: 'TopBlock',
    filters: { currency },
    computed: {
        ...mapGetters('spottrade', ['selectedPair']),
        ...mapGetters('binance', ['pairSymbolTicker']),
        priceChangeClass() {
            if (this.pairSymbolTicker) {
                const priceChange = parseFloat(this.pairSymbolTicker.priceChange);
                return priceChange < 0 ? 'text-danger' : 'text-success';
            }

            return undefined;
        },
        priceChangePercentClass() {
            if (this.pairSymbolTicker) {
                const priceChangePercent = parseFloat(
                    this.pairSymbolTicker.priceChangePercent
                );
                return priceChangePercent < 0 ? 'text-danger' : 'text-success';
            }
            return undefined;
        },
        getLastPrice() {
            return this.pairSymbolTicker?.lastPrice || '';
        },
        getPriceChange() {
            return this.pairSymbolTicker?.priceChange || '';
        },
        getPriceChangePercent() {
            return this.pairSymbolTicker?.priceChangePercent || '';
        },
        getHighPrice() {
            return this.pairSymbolTicker?.highPrice || '';
        },
        getLowPrice() {
            return this.pairSymbolTicker?.lowPrice || '';
        },
        getTotalTradedQuoteAssetVolume() {
            return this.pairSymbolTicker?.totalTradedQuoteAssetVolume || '';
        }
    }
};
</script>

<style lang="scss" scoped>
.line-height-normal {
    line-height: normal;
}
</style>