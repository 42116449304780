<template>
  <div class='row'>
    <div id='recent-transactions' class='col-12 mt-2'>
      <h3 class='my-2'>{{ $t('instanttradeTable-title') }}</h3>
      <div class='card'>
        <div class='card-content'>
          <div class='table-responsive'>
            <table id='recent-orders' class='table table-hover table-xl mb-0'>
              <thead>
              <tr>
                <th class='border-top-0'>{{ $t('instanttradeTable-history-transaction') }}</th>
                <th class='border-top-0'>{{ $t('instanttradeTable-history-dateHour') }}</th>
                <th class='border-top-0'>{{ $t('instanttradeTable-history-price') }}</th>
                <th class='border-top-0'>{{ $t('instanttradeTable-history-amount') }}</th>
                <th class='border-top-0'>{{ $t('instanttradeTable-history-activeAmount') }}</th>
                <th class='border-top-0'>{{ $t('instanttradeTable-history-fee') }}</th>
                <th class='border-top-0'>{{ $t('instanttradeTable-history-status') }}</th>
              </tr>
              </thead>
              <tbody>
              <template v-if='isLoading'>
                <tr>
                  <td colspan='6' class='text-center loading-content'>
                    <content-loader
                        :width='1000'
                        :height='63'
                        :speed='2'
                        primaryColor='#ffffff'
                        secondaryColor='#eee'
                        class='loading-bg'
                        v-for='(item, index) in 3'
                        :key="'loading-' + index"
                    >
                      <rect x='0' y='0' rx='6' ry='6' width='1000' height='83'/>
                    </content-loader>
                  </td>
                </tr>
              </template>
              <template v-else-if='history.length === 0'>
                <tr>
                  <td colspan='7' class='text-center'>{{ $t('instanttradeTable-noData') }}</td>
                </tr>
              </template>
              <template v-else>
                <tr v-for='(item, index) in history' :key="'history-' + index"
                    :class="['progress-status',progressStatusColors['transfer'][item.status]]">
                  <td class='text-truncate'>
                      <span :class="['progress-type', item.type_code ==='quicksale-buy' ? 'quicksale-buy' : 'quicksale-sell'] ">
                        {{ item.type }}
                      </span>
                  </td>
                  <td class='text-truncate'>{{ item.created_at | dateFormat }}</td>
                  <td>
                    {{ item.price_formatted }}
                  </td>
                  <td class='text-truncate p-1'>
                    <i :class="'cc ' + item.currency_code.toUpperCase() + '-alt'"></i>
                    {{ item.amount_formatted }}
                  </td>
                  <td class='text-truncate p-1'>{{ item.total_formatted }}</td>
                  <td class='text-truncate p-1'>{{ item.fee_formatted }}</td>
                  <td class='text-truncate'>
                    <span class="progress-text d-flex justify-content-start align-items-center">
                          <i class='la la-dot-circle-o font-medium-1 m-r-5'></i>
                    {{ item.status_str }}
                    </span>
  
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class='col-12 text-center mt-2 mb-2' v-if='next != null'>
          <button type='button' class='btn-gradient-primary' @click='getMore'>{{ $t('instanttrade-loadMore') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from '@/config/_date-format';
import {ContentLoader} from 'vue-content-loader';

import {mapState, mapActions} from 'vuex';

export default {
  name: 'InstantTradeHistory',
  computed: {
    ...mapState({
      history: state => state.global.history,
      quicksale: state => state.quicksale.quicksale,
      next: state => state.global.next,
      isLoading: state => state.global.isLoading,
      progressStatusColors: state => state.global.progressStatusColors
    })
  },
  components: {
    ContentLoader
  },
  filters: {
    dateFormat
  },
  methods: {
    ...mapActions('global', ['GET_HISTORY']),
    getMore() {
      if (this.next) this.GET_HISTORY({currency: this.quicksale.crypto.code.toLowerCase(), isNextPage: true});
    }
  },
  created() {
    //this.GET_HISTORY({ currency: this.quicksale.currency.code.toLowerCase(), isNextPage: false });
  }
};
</script>

<style scoped>
.btn-status {
  cursor: default !important;
}
</style>
