<template>
  <div class='home'>
    Dashboard Sayfası
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  components: {}
};
</script>
