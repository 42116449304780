<template>
    <div class="col-12 px-0">
        <div class="d-flex flex-grow-1">
            <div class="flex-grow-1 mt-1">
                <div class="form-group px-1">
                    <div class="row justify-content-between mb-1">
                        <span>{{ $t('marginTrade.repaying') }}</span>
                        <span class="text-success font-weight-bold">{{ isCalculating ? '...' : debt_formatted }}</span>
                    </div>
                    <div class="row justify-content-between mb-1">
                        <span>{{ $t('marginTrade.total_fee') }}</span>
                        <span class="text-dark font-weight-bold">
                            {{ totalFee | amountFormatter }} {{ currencyCode }}
                        </span>
                    </div>
                    <div class="row justify-content-between mb-1">
                        <span>{{ $t('marginTrade.total_debt') }}</span>
                        <span class="text-dark font-weight-bold">{{ isCalculating ? '...' : debt_formatted }}</span>
                    </div>
                </div>
                <button type="button" @click.prevent="onSubmit" class="btn-block btn-gradient-success" :disabled="isSubmitting">
                    {{ $t('commons.approve') }} {{ isSubmitting ? '...' : '' }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {cryptoAmountFormatter} from '@/config/_currency';
import { getErrorServiceMsg } from '@/config/serviceMessages';

export default {
    name: 'RepayForm',
    data() {
        return {
            isCalculating: false,
            isSubmitting: false,
            debt: 0,
            debt_formatted: '0.00000000',
            debt_formatted_usd: '$ 0',
            amount: null,
            timeValue: 3,
        };
    },
    filters: {
        amountFormatter: cryptoAmountFormatter
    },
    computed: {
        ...mapGetters('margintrade', ['selectedPosition']),
        totalFee() {
            return this.selectedPosition?.total_fee || '';
        },
        currencyCode() {
            return this.selectedPosition?.currency_code || '';
        }
    },
    methods: {
        ...mapActions('margintrade', ['CALCULATE', 'REPAY']),
        async onSubmit() {
            this.isSubmitting = true;
            try {
                await this.REPAY({position_id: this.selectedPosition.id});
                this.isSubmitting = false;
                this.closeRepayModal();
            } catch (error) {
                this.isSubmitting = false;
            }
        },
        closeRepayModal() {
            this.$store.commit('margintrade/ON_SELECT_POSITION', null);
            this.$store.commit('margintrade/SET_REPAY_MODAL', false);
            document.querySelector('body').classList.remove('overflow-hidden');
        }
    },
    async created() {
        this.isCalculating = true;
        try {    
            if(this.selectedPosition?.asset_id) {
                const calcRes = await this.CALCULATE(this.selectedPosition.asset_id);
                this.debt = calcRes.data?.debt || 0;
                this.debt_formatted = calcRes.data?.debt_formatted || '0.00000000';
            }
            this.isCalculating = false;
        } catch (error) {
            this.$toast.error(getErrorServiceMsg(error.response));
            this.isCalculating = false;
        }
    }
};
</script>