<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form method="post" @submit.prevent="handleSubmit(sellBtn)">
      <div class="form-group">
        <label class="mr-sm-2">{{ $t('instanttrade-label-cryptotype') }}</label>
        <div class="row">
          <validation-provider rules="required" v-slot="{ classes, errors }" class="col-12">
            <multiselect
              v-model="quicksale.crypto"
              @input="getCoinSelection"
              deselect-label=""
              select-label=""
              selected-label=""
              track-by="name"
              label="name"
              :custom-label="vueMultiselectCustomLabel"
              :show-labels="false"
              placeholder="Select one"
              :options="getFilteredCrypto"
              :allow-empty="false"
              open-direction="bottom"
              :class="classes"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
              <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
              <template slot="noOptions">{{ $t('noOptions') }}</template>
            </multiselect>
            <span class="error__message">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group">
        <label class="mr-sm-2">{{ $t('instanttrade-label-currenciestype') }}</label>
        <div class="row">
          <validation-provider rules="required" v-slot="{ classes, errors }" class="col-12">
            <multiselect
              v-model="quicksale.currency"
              @input="getCoinSelection"
              deselect-label=""
              select-label=""
              selected-label=""
              track-by="name"
              label="name"
              placeholder="Select one"
              :options="getFilteredCurrency"
              :searchable="false"
              :allow-empty="false"
              :class="classes"
              open-direction="bottom"
            >
              <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
              <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
              <template slot="noOptions">{{ $t('noOptions') }}</template>
            </multiselect>
            <span class="error__message">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group">
        <label class="mr-sm-2">{{ $t('instanttrade-label-amount') }}</label>
        <div class="row">
          <validation-provider
            rules="required"
            :name="$t('instanttrade-label-amount')"
            v-slot="{ classes, errors }"
            class="col-12"
          >
            <currency-input
              id="currency_amount"
              class="form-control"
              :class="classes"
              v-model="quicksale.total"
              :precision="quicksaleCurrenyIsCrypto"
              @keyup="getCalculate('total')"
              @blur="clearCalculateForm"
            />
            <span class="error__message">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </div>
      <div class="form-group">
        <label class="mr-sm-2">{{ $t('instanttrade-label-quantity') }}</label>
        <div class="row">
          <validation-provider
            rules="required"
            :name="$t('instanttrade-label-quantity')"
            v-slot="{ classes, errors }"
            class="col-12"
          >
            <currency-input
              id="usd_amount"
              name="usd_amount"
              class="form-control"
              :class="classes"
              :precision="8"
              v-model="quicksale.amount"
              @keyup="getCalculate('amount')"
              @blur="clearCalculateForm"
            />
            <span class="error__message">{{ errors[0] }}</span>
            <PercentButtons @onSelectedPercent="onSelectedPercent" v-if="quicksale.crypto" ref="percentButtons" class="my-sm-1" />
          </validation-provider>
        </div>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('instanttrade-availablelimit') }}</p>
          <h6 class="mb-0">{{ sellNow.availableBalance }}</h6>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('instanttrade-sellPrice') }}</p>
          <h6 class="mb-0">{{ sellNow.priceBuy }}</h6>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('instanttrade-buycommission') }}</p>
          <h6 class="mb-0">{{ quicksale.fee }}</h6>
        </div>
        <!--
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('instanttrade-minimumSellAmount') }}</p>
          <h6 class="mb-0">{{ sellNow.lowestBuy }}</h6>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('instanttrade-maxSellLimit') }}</p>
          <h6 class="mb-0">{{ sellNow.highestBuy }}</h6>
        </div>
        -->
      </div>
      <div class="col-12 text-center mt-2">
        <button type="submit" class="btn-gradient-secondary btn-block">{{ $t('instanttrade-btn-sellnow') }}</button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import PercentButtons from "@/components/global/PercentButtons.vue";

export default {
  name: 'SellNow',
  components: {
    Multiselect,
    PercentButtons
  },
  methods: {
    ...mapActions('quicksale', [
      'POST_CALCULATE',
      'POST_SELL',
      'GET_CURRENCY_BALANCE',
      'GET_SELL_NOW_PRICES',
      'GET_TRY_BALANCE',
      'GET_BUY_NOW_PRICES',
      'GET_STATIC_CONTENT_SELL'
    ]),
    ...mapActions('global', ['GET_HISTORY']),
    sellBtn() {
      if (this.quicksale.total <= this.quicksale.raw.currency.min_pay / 100) {
        Vue.$toast.error(this.$t('instanttrade-sell-invalidAmount', { amount: this.quicksale.raw.min_pay_formatted }));
        return;
      }
      this.$store
        .dispatch('quicksale/POST_SELL')
        .then(() => {
          this.$refs.form.reset();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCalculate(calcType) {
      if (calcType === 'total' && this.quicksale.total === null) {
        this.quicksale.amount = null;
        return;
      } else if (calcType === 'amount' && this.quicksale.amount === null) {
        this.quicksale.total = null;
        return;
      }
      this.POST_CALCULATE({ buy: this.quicksale.type, calcType });
    },
    getCoinSelection() {
      this.$store.commit('quicksale/CLEAR_FORM');
      this.GET_CURRENCY_BALANCE(this.quicksale.currency);
      this.GET_SELL_NOW_PRICES(this.quicksale.currency);
      this.GET_BUY_NOW_PRICES(this.quicksale.currency.code.toLowerCase());
      this.GET_HISTORY({ currency: this.quicksale.crypto.code.toLowerCase() });
      this.$refs.form.reset();
    },
    clearCalculateForm() {
      this.$refs.form.reset();
    },
    vueMultiselectCustomLabel ({ name, code }) {
      return `${name} (${code})`
    },
    onSelectedPercent(percent) {
      if(!percent) {
        return;
      }
      const cleanBalance = this.quicksale?.crypto ? this.sellNow.availableBalance.replace(/[^\d.]/g, '') : 0;
      if(parseFloat(cleanBalance) > 0) {
        let p = parseInt(percent);
        this.$store.commit('quicksale/SET_QUICKSALE_AMOUNT', (parseFloat(cleanBalance) * (p/100)).toFixed(8));
        this.POST_CALCULATE({ buy: this.quicksale.type, calcType: 'amount' });
      }
    },
  },
  computed: {
    ...mapState({
      quicksale: (state) => state.quicksale.quicksale,
      currency: (state) => state.global.currency,
      crypto: (state) => state.global.crypto,
      sellNow: (state) => state.quicksale.sellNow,
      isLoading: (state) => state.quicksale.isLoading
    }),
    ...mapGetters('global', ['getCrypto', 'getCurrency']),
    getFilteredCrypto: function () {
      const vm = this;
      return this.getCrypto.filter(function (item) {
        return item.id != vm?.quicksale?.currency?.id;
      });
    },
    getFilteredCurrency: function () {
      const vm = this;
      return this.getCurrency.filter(function (item) {
        return item.id != vm?.quicksale?.crypto?.id && item.quicksale && ["TRY","USDT"].includes(item?.code?.toUpperCase());
      });
    },
    quicksaleCurrenyIsCrypto() {
      return this.quicksale?.currency?.crypto ? 8 : 2;
    }
  },
  created() {
    this.GET_CURRENCY_BALANCE();
    this.GET_SELL_NOW_PRICES();
    this.GET_STATIC_CONTENT_SELL();
  }
};
</script>

<style scoped></style>
