export const mutations = {
  SET_DATA(state, payload) {
    state.history = payload;
  },
  IS_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_OVERLAY(state, payload) {
    state.overlayVisible = payload;
    if(payload) {
      document.documentElement.style.overflow = 'hidden';
      return;
    }
    document.documentElement.style.overflow = 'auto';
  }
};
