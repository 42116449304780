<template>
    <div class="col-8 q-px-md h-auto">
        <TradingView />
        <TransactionsMain />
    </div>
</template>

<script>
import TradingView from './TradingView';
import TransactionsMain from './TransactionsMain';

export default {
    name: "MiddleBlock",
    components: { TradingView, TransactionsMain }
};
</script>