<template>
    <div class="content-wrapper">
        <Breadcrumb :title="$t('spot-trade')" />

        <div class="top-page-container remove-margins">
            <TopBlock class="mx-2" />
            <div class="row main-block mx-2">
                <LeftBlock />
                <MiddleBlock />
                <RightBlock />
            </div>
            <BottomBlock />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import {OrderDirection, OrderType, OrderWalletType} from '@/enums/spot-trade-order.enum';
import Breadcrumb from '@/components/layout/Breadcrumb';
import TopBlock from '../components/SpotTrade/TopBlock';
import LeftBlock from '@/components/SpotTrade/LeftBlock';
import RightBlock from '@/components/SpotTrade/RightBlock';
import MiddleBlock from '@/components/SpotTrade/MiddleBlock';
import BottomBlock from '@/components/SpotTrade/BottomBlock';

export default {
    name: 'SpotTrade',
    components: { Breadcrumb, RightBlock, LeftBlock, MiddleBlock, BottomBlock, TopBlock },
    async beforeMount() {
        this.FETCH_ALL_PAIRS();
    },
    methods: {
        ...mapActions('spottrade', ['FETCH_ALL_PAIRS']),
    },
    created() {
        this.$store.commit('margintrade/SET_WALLET_TYPE', OrderWalletType.Cross);
        this.$store.commit('spottrade/SET_SELECTED_TYPE', OrderType.Limit);
        this.$store.commit('spottrade/SET_SELECTED_ORDER_DIRECTION', OrderDirection.Buy);
    }
};
</script>

<style lang="scss" scoped>

.remove-margins {
    margin: 0 -2.2rem;
}

.main-block {
    min-height: 970px;
    height: 100%;
    overflow-y: hidden;
    padding-bottom: 1rem;
}
</style>
