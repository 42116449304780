import Vue from 'vue';

Vue.directive('select-overflow', {
  inserted: (el, _binding, vnode) => {
    let originalWidth;
    let originalPosition;
    let originalZIndex;
    let selectIsOpen = false;
    vnode.child.$watch('isOpen', (isOpen) => {
      selectIsOpen = isOpen;
      if (isOpen) {
        const { offsetWidth } = el;
        originalWidth = el.style.width;
        originalPosition = el.style.position;
        originalZIndex = el.style.zIndex;
        el.style.width = `${offsetWidth}px`;
        el.style.position = 'fixed';
        el.style.zIndex = 2;
      } else {
        el.style.position = originalPosition;
        el.style.width = originalWidth;
        el.style.zIndex = originalZIndex;
      }
    });

    window.addEventListener(
      'wheel',
      (event) => {
        if (selectIsOpen) {
          // disabled outside scroll when select is open
          event.stopPropagation();
        }
      },
      true
    );
  }
});

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});
