<template>
    <div>
        <OrdersTable
            :orders="openOrders"
            title="spotTrade.open-orders"
            :selectable=true
        />
        <OrdersTable
            :orders="completedOrders"
            title="spotTrade.order-history"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrdersTable from './OrdersTable';

export default {
    name: "BottomBlock",
    components: { OrdersTable },
    computed: {
        ...mapGetters("spottrade", ["openOrders", "completedOrders"])
    }
};
</script>