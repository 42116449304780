<template>
    <transition name='fade'>
        <div class='modal show'>
            <div class='modal__backdrop'></div>
            <div class='modal__dialog'>
                <div class='modal__header'>
                    <h3>Transfer</h3>
                    <div class='modal__close ' @click='closeModal()'>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'>
                        <path
                            fill='currentColor'
                            d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'
                        ></path>
                        </svg>
                    </div>
                </div>
                <div class='modal__body'>
                    <ValidationObserver ref="transferForm" v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(onSubmit)">
                            <div class="row align-items-center">
                                <div class="form-group col-12 col-md-5">
                                    <label>From</label>
                                    <multiselect v-model="from_wallet" deselect-label="" select-label=""
                                        selected-label="" track-by="name" label="name" :placeholder="$t('commons.choose')"
                                        :options="fromWallets" :allow-empty="false" :searchable="false" open-direction="bottom">
                                        <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name}` }}</template>
                                        <template slot="option" slot-scope="{ option }">{{ `${option.name}` }}</template>
                                        <template slot="noOptions">{{ $t('noOptions') }}</template>
                                    </multiselect>
                                </div>
                                <div class="col-md-2 d-sm-none d-md-flex justify-content-center">
                                    <i class="la la-arrow-right"></i>
                                </div>
                                <div class="form-group col-12 col-md-5">
                                    <label>To</label>
                                    <multiselect v-model="to_wallet" deselect-label="" select-label=""
                                        selected-label="" track-by="name" label="name" :placeholder="$t('commons.choose')"
                                        :options="toWallets" :allow-empty="false" open-direction="bottom">
                                        <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name}` }}</template>
                                        <template slot="option" slot-scope="{ option }">{{ `${option.name}` }}</template>
                                        <template slot="noOptions">{{ $t('noOptions') }}</template>
                                    </multiselect>
                                </div>
                            </div>
                            <div class="form-group" v-if="isIsolated">
                                <label class="mr-sm-2">{{ $t('spotTrade.pair') }}</label>
                                <validation-provider rules="required" name="Coin" v-slot="{ classes, errors }">
                                    <multiselect v-model="selectedTradingPair" @input="onSelectPair" deselect-label="" select-label=""
                                        selected-label="" track-by="id" label="name" :placeholder="$t('commons.choose')" :options="allPairs"
                                        :allow-empty="false" open-direction="bottom" :class="classes">
                                        <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name}` }}</template>
                                        <template slot="noOptions">{{ $t('noOptions') }}</template>
                                        <template slot="noResult">{{ $t('noOptions') }}</template>
                                    </multiselect>
                                    <span class="error__message">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>
                            <div class="form-group">
                                <label class="mr-sm-2">Coin</label>
                                <validation-provider rules="required" name="Coin" v-slot="{ classes, errors }">
                                    <multiselect v-model="currency" @input="onSelectCoin" deselect-label="" select-label=""
                                        selected-label="" track-by="name" label="name" :custom-label="coinCustomLabel"
                                        :show-labels="false" :placeholder="$t('commons.choose')" :options="filteredCoins"
                                        :allow-empty="false" open-direction="bottom" :class="classes">
                                        <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
                                        <template slot="noOptions">{{ $t('noOptions') }}</template>
                                        <template slot="noResult">{{ $t('noOptions') }}</template>
                                    </multiselect>
                                    <span class="error__message">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>
                            <div class="form-group">
                                <div class="d-flex justify-content-between">
                                    <label class="mr-sm-2">{{ $t('commons.amount') }}</label>
                                    <span>{{ $t('commons.available') }}: {{ balanceLoading ? '...' : currencyBalanceFormatted ? currencyBalanceFormatted : '0' }}</span>
                                </div>
                                <validation-provider :rules="`required|max_amount:${currencyBalance}`" :name="$t('commons.amount')" v-slot="{ classes, errors }">
                                    <currency-input
                                        id="amount"
                                        class="form-control"
                                        :class="classes"
                                        :distractionFree="false"
                                        :precision="8"
                                        v-model="amount"
                                        placeholder="0.00000000"
                                    />
                                    <span class="error__message">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="submit" class="btn btn-gradient-success btn-round w-50" :disabled="isSubmitting">
                                    {{ $t('commons.approve') }} {{ isSubmitting ? '...' : '' }}
                                </button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
                <div class='modal__footer'>
                </div>
            </div>
        </div>
    </transition>
</template>
  
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';

const fromWallets = [
    {name: 'Spot', value: 'spot'}
];
const toWallets = [
    {name: 'Cross', value: 'cross'},
    {name: 'Isolated', value: 'isolated'},
];

export default {
    name: 'MarginTradeTransferModal',
    components: { Multiselect },
    data() {
        return {
            balanceLoading: false,
            isSubmitting: false,
            currency: null,
            amount: null,
            fromWallets: fromWallets,
            from_wallet: fromWallets[0],
            toWallets: toWallets,
            to_wallet: toWallets[0],
            selectedTradingPair: null
        }
    },
    computed: {
        ...mapGetters('spottrade', ['allPairs']),
        ...mapGetters('margintrade', ['transferCrossWalletCurrencies']),
        ...mapState({
            coins: (state) => state.global.currency,
            currencyBalanceFormatted: (state) => state.global.currencyBalanceFormatted,
            currencyBalance: (state) => state.global.currencyBalance,
        }),
        isIsolated() {
            return this.to_wallet.value === 'isolated';
        },
        isCross() {
            return this.to_wallet.value === 'cross';
        },
        filteredCoins() {
            if(this.isCross) {
                /* const basePairs = this.allPairs.length > 0 ? [...new Set(this.allPairs.map((item) => item.baseCurrency))] : [];
                const quotePairs = this.allPairs.length > 0 ? [...new Set(this.allPairs.map((item) => item.quoteCurrency))] : [];
                const pairs = [...basePairs, ...quotePairs];
                return pairs.length > 0 ?  this.coins.filter(coin => pairs.includes(coin.code)) : []; */
                return this.transferCrossWalletCurrencies;
            }
            if(this.selectedTradingPair) {
                return this.coins.filter(val => [this.selectedTradingPair.quoteCurrency, this.selectedTradingPair.baseCurrency].includes(val.code));
            }
            return [];
        }
    },
    created() {
    },
    methods: {
        ...mapActions('global', ['GET_BALANCE_BY_CURRENCY']),
        ...mapActions('margintrade', ['MAKE_TRANSFER', 'CREATE_ISOLATED_WALLET_BY_PAIR']),
        closeModal() {
            this.$store.commit("margintrade/TOGGLE_TRANSFER_MODAL", false);
            document.querySelector('body').classList.remove('overflow-hidden');
        },
        onSubmit() {
            const data = {
                from_wallet: this.from_wallet.value,
                type: this.to_wallet.value,
                amount: this.amount,
                currency: this.currency.code,
            };
            if(this.isIsolated) {
                data.pair_id = this.selectedTradingPair?.id;
            }
            this.isSubmitting = true;
            this.MAKE_TRANSFER(data)
                .then(() => {
                    // Resetting Values
                    this.currency = null;
                    this.amount = null;
                    this.from_wallet = fromWallets[0];
                    this.to_wallet = toWallets[0];
                    this.selectedTradingPair = null;
                    this.isSubmitting = false;
                    // Wait until the models are updated in the UI
                    this.$nextTick(() => {
                        this.$store.commit('global/CLEAR_CURRENCY_BALANCE');
                        this.$store.commit("margintrade/TOGGLE_TRANSFER_MODAL", false);
                        this.$refs.transferForm.reset();
                    });
                }).catch(() => {
                    this.isSubmitting = false;
                });
        },
        coinCustomLabel({ name, code }) {
            return `${name} (${code})`;
        },
        onSelectCoin() {
            if(this.currency) {
                this.balanceLoading = true;
                this.GET_BALANCE_BY_CURRENCY(this.currency?.code?.toLowerCase())
                    .then(() => {
                        this.balanceLoading = false;
                    })
                    .catch(() => {
                        this.balanceLoading = false;
                    })
            }
        },
        onSelectPair() {
            this.CREATE_ISOLATED_WALLET_BY_PAIR(this.selectedTradingPair.id)
            this.currency = null;
        }
    }
}
</script>
  
<style lang='scss' scoped>
.modal {
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1031;

    &.show {
        display: block;
    }

    &__backdrop {
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    &__dialog {
        background-color: #ffffff;
        position: relative;
        width: 600px;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        z-index: 2;

        @media screen and (max-width: 992px) {
            width: 90%;
        }
    }

    &__close {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    &__close:hover {
        opacity: .7;
    }

    &__header {
        padding: 20px 20px 10px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__body {
        padding: 10px 20px 10px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    &__footer {
        padding: 10px 20px 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

</style>