<template>
    <div class="row mt-3">
        <div class="col-12">
            <crypto-widget type="geomap" style="width:100%;height:600px;" template="generic" color="default" assets="BTC~AED,BTC~AFN,BTC~ALL,BTC~AMD,BTC~ANG,BTC~AOA,BTC~ARS,BTC~AUD,BTC~AWG,BTC~AZN,BTC~BAM,BTC~BBD,BTC~BDT,BTC~BGN,BTC~BHD,BTC~BIF,BTC~BMD,BTC~BND,BTC~BOB,BTC~BRL,BTC~BSD,BTC~BTN,BTC~BWP,BTC~BYN,BTC~BYR,BTC~BZD,BTC~CAD,BTC~CDF,BTC~CHF,BTC~CLF,BTC~CLP,BTC~CNY,BTC~COP,BTC~CRC,BTC~CUC,BTC~CUP,BTC~CVE,BTC~CZK,BTC~DJF,BTC~DKK,BTC~DOP,BTC~DZD,BTC~EGP,BTC~ERN,BTC~ETB,BTC~EUR,BTC~FJD,BTC~FKP,BTC~GBP,BTC~GEL,BTC~GHS,BTC~GIP,BTC~GMD,BTC~GNF,BTC~GTQ,BTC~GYD,BTC~HKD,BTC~HNL,BTC~HRK,BTC~HTG,BTC~HUF,BTC~IDR,BTC~ILS,BTC~INR,BTC~IQD,BTC~IRR,BTC~ISK,BTC~JMD,BTC~JOD,BTC~JPY,BTC~KES,BTC~KGS,BTC~KHR,BTC~KMF,BTC~KPW,BTC~KRW,BTC~KWD,BTC~KYD,BTC~KZT,BTC~LAK,BTC~LBP,BTC~LKR,BTC~LRD,BTC~LSL,BTC~LYD,BTC~MAD,BTC~MDL,BTC~MGA,BTC~MKD,BTC~MMK,BTC~MNT,BTC~MOP,BTC~MRO,BTC~MUR,BTC~MVR,BTC~MWK,BTC~MXN,BTC~MXV,BTC~MYR,BTC~MZN,BTC~NAD,BTC~NGN,BTC~NIO,BTC~NOK,BTC~NPR,BTC~NZD,BTC~OMR,BTC~PAB,BTC~PEN,BTC~PGK,BTC~PHP,BTC~PKR,BTC~PLN,BTC~PYG,BTC~QAR,BTC~RON,BTC~RSD,BTC~RUB,BTC~RWF,BTC~SAR,BTC~SBD,BTC~SCR,BTC~SDG,BTC~SEK,BTC~SGD,BTC~SHP,BTC~SLL,BTC~SOS,BTC~SRD,BTC~SSP,BTC~STD,BTC~SVC,BTC~SYP,BTC~SZL,BTC~THB,BTC~TJS,BTC~TMT,BTC~TND,BTC~TOP,BTC~TRY,BTC~TTD,BTC~TWD,BTC~TZS,BTC~UAH,BTC~UGX,BTC~USD,BTC~UYI,BTC~UYU,BTC~UZS,BTC~VEF,BTC~VND,BTC~VUV,BTC~WST,BTC~XAF,BTC~XCD,BTC~XOF,BTC~XPF,BTC~YER,BTC~ZAR,BTC~ZMW,BTC~ZWL" api="cryptocompare.com" realtime="false"></crypto-widget>
        </div>
    </div>
</template>

<script>
export default {
    name: "Geomap",
};
</script>

<style scoped>
</style>