<template>
  <div class="row mt-3 widget-container">
    <div class="tradingview-widget"></div>
    <div class="tradingview-widget"></div>
    <div class="tradingview-widget"></div>
    <div class="tradingview-widget"></div>
  </div>
</template>

<script>
export default {
  name: "TechnicalAnalysis",
  mounted() {    
    let all_widgets = document.querySelectorAll(".tradingview-widget");
    let options = [
        {
            "interval": "1m",
            "width": "300",
            "isTransparent": false,
            "height": "450",
            "symbol": "BINANCE:BTCUSDT",
            "showIntervalTabs": true,
            "locale": "en",
            "colorTheme": "light"
        },
        {
            "interval": "1m",
            "width": "300",
            "isTransparent": false,
            "height": "450",
            "symbol": "BINANCE:ETHUSDT",
            "showIntervalTabs": true,
            "locale": "en",
            "colorTheme": "light"
        },
        {
            "interval": "1m",
            "width": "300",
            "isTransparent": false,
            "height": "450",
            "symbol": "BINANCE:DOGEUSDT",
            "showIntervalTabs": true,
            "locale": "en",
            "colorTheme": "light"
        },
        {
            "interval": "1m",
            "width": "300",
            "isTransparent": false,
            "height": "450",
            "symbol": "BINANCE:XRPUSDT",
            "showIntervalTabs": true,
            "locale": "en",
            "colorTheme": "light"
        }
    ]
    for (let index = 0; index < all_widgets.length; index++) {
        var js = document.createElement("script");
        js.async = true;
        // Path to the script that loads the widget
        js.src = "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js"; // This is where the app is currently hosted
        js.textContent =  JSON.stringify(options[index]);
        const element = all_widgets[index];  
        console.log(element)      
        element.appendChild(js)
    }
  },
};
</script>

<style scoped>
.widget-container {
    display: flex;
    justify-content: space-evenly;
}
</style>