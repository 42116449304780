<template>
    <div class="px-2 mt-2">
        <div class="d-flex justify-content-start align-items-center">
            <h6 class="my-md">
                {{ $t('marginTrade.funds') }}
            </h6>
        </div>
        <div class="table-responsive card m-0 p-0">
            <table class="table">
                <thead>
                    <tr>
                        <th>Coin</th>
                        <th>{{ $t('commons.total_balance') }}</th>
                        <th>{{ $t('commons.available') }}</th>
                        <th>{{ $t('marginTrade.borrowed') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="main-order-row" v-for="(item, index) in assetsData" :key="`asset-data-${index}`">
                        <td>{{ item.currency_name }}</td>
                        <td>{{ item.total | amountFormatter }}</td>
                        <td>{{ item.available | amountFormatter }}</td>
                        <td>{{ item.debt | amountFormatter }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { OrderWalletType } from '@/enums/spot-trade-order.enum';
import {cryptoAmountFormatter} from '@/config/_currency';
export default {
    name: 'Funds',
    props: {},
    filters: {
        amountFormatter: cryptoAmountFormatter
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters('margintrade', ['selectedWalletType', 'crossWalletAssets', 'isolatedWalletAssets']),
        crossWallet() {
            return OrderWalletType.Cross;
        },
        isolatedWallet() {
            return OrderWalletType.Isolated;
        },
        assetsData() {
            if (this.selectedWalletType === this.crossWallet) {
                return this.crossWalletAssets;
            }
            return this.isolatedWalletAssets;
        }
    },
    methods: {
    }
};
</script>

<style lang="scss" scoped>
.table-responsive {
    height: 390px;
    overflow-y: scroll;
}

.main-order-row td {
    font-weight: bold;
}

.coin-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #c4b7b7;
}

</style>