import Vue from 'vue';
import Vuex from 'vuex';
import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import global from './modules/global';
import customer from './modules/customer';
import quicksale from './modules/quicksale';
import account from './modules/account';
import wallet from './modules/wallet';
import withdrawal from './modules/withdrawal';
import deposit from './modules/deposit';
import access from './modules/access';
import striker from './modules/striker';
import keeper from './modules/keeper';
import skmatch from './modules/skmatch';
import binance from './modules/binance';
import spottrade from './modules/spot-trade';
import margintrade from './modules/margin-trade';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    global: global,
    customer: customer,
    quicksale: quicksale,
    account: account,
    wallet: wallet,
    withdrawal: withdrawal,
    deposit: deposit,
    access: access,
    striker: striker,
    keeper: keeper,
    skmatch,
    binance,
    spottrade,
    margintrade
  }
});
