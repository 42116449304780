// import axios from 'axios';
import API from '../api';
// const BASE_API_URL= 'http://api.test.ofinans.com/v2/';

// const Services = axios.create({
//     baseURL: String(BASE_API_URL)});
// Services.defaults.headers.common['Authorization'] = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjgyNGMxMTM2YmFkOTMwNGQ0ZjM5YmI3YTE1Y2JlMWQ4MWY4ZTQ2ODE1MDYxNjI0MTRhNWEyZDk0OGNkYzRjOGMxYjlmNzdlYWEzZjA2NTViIn0.eyJhdWQiOiIyIiwianRpIjoiODI0YzExMzZiYWQ5MzA0ZDRmMzliYjdhMTVjYmUxZDgxZjhlNDY4MTUwNjE2MjQxNGE1YTJkOTQ4Y2RjNGM4YzFiOWY3N2VhYTNmMDY1NWIiLCJpYXQiOjE2MTc5ODY0ODUsIm5iZiI6MTYxNzk4NjQ4NSwiZXhwIjoxNjE4MDA0NDg1LCJzdWIiOiIyNzYiLCJzY29wZXMiOltdfQ.afCz5XL8vKqFEXlZJ1JLeJSkiB8ljGRwD9AsODuaHqh4Qh6FaMXpaM1SPdWpaNAjhEvLxdKtwUHg_k7SQ8Dp1NL2omDjs9xItN53YPVyPXX0ypvYhCuxSNGcy0IybaFKwrKNA1--DxTCpVKhtLT4HiJNMZ02JfyocJv3LIciqq5RtEo_cdLL_1l5UyYMf-CbBmE7lvrA637uuFkber7nE8EvSqvy8I-dABpNZ0dvlOSXPa_zWjAhg7D-EP3PXdEc1uRHwHTeOgirlh9-06MIQKzfxzLTq3UoZ58agVMzHRWK4yKjBQMzjLUakJLjvjEj4THAHBVAggEdO_nwog1-nFSXvCkhWPeJ7KxsYXKEdwoHA6YQVAzYn7Exj1dEyH6yNRVDGuPHAGlyocvC-VkeUdkCcF2yJXusSgcWRHYGIUnXTkKj56stDfViPVG8tndrfuyCRwI_j_VjboJxbAJcmNz0H1lIFQEVQ0mxulgwW072wZuDVB3bKYyIpHbdWdHycU0Kr1a9kjqD9ScmvFYn0qV4cGJ44bgMknz1SQqbVVs7uJSx0EhuALUWdxX-7Gmpt8hjqTtz26cYruutvb8JPrAZlIbQg02pbSRAGE-igN2s_yjccN8_HhBCgrz2mEskf8rQJtI54PLYFdKtg51PUWObCynDIbLe2RwUC8EcGFk';

import Services from '@/config/_axios';

export const actions = {
  GET_HISTORY({ commit }) {
    Services.get(API.history, {
      params: {
        page: 1
      }
    })
      .then(res => {
        commit('SET_DATA', res.data);
        commit('IS_LOADING', false);
      })
      .catch(err => {
        console.error(err);
      });
  }
};
