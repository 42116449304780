<template>
  <footer class="footer footer-static footer-transparent">
    <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2 mt-2">
      <span class="d-block d-md-inline-block">
        <a :href="$t('footer-menu-about-us-link')" target="_blank" class="blue-grey mr-1">
          {{ $t('footer-menu-about-us') }}
        </a>
        <a href="javascript:void(0);" @click="onClickUsageAgreement" class="blue-grey mr-1">
          {{ $t('footer-menu-aggrement') }}
        </a>
        <a :href="$t('footer-menu-privacy-policy-link')" target="_blank" class="blue-grey mr-1">
          {{ $t('footer-menu-privacy-policy') }}
        </a>
        <a :href="$t('footer-menu-AML-link')" target="_blank" class="blue-grey mr-1">{{ $t('footer-menu-AML') }}</a>
        <a :href="$t('footer-risk-information-form-link')" target="_blank" class="blue-grey mr-1">
          {{ $t('footer-risk-information-form') }}
        </a>
        Copyright &copy; 2021
        <a class="text-bold-800 grey darken-2" href="/" target="_blank">Miracle Cash&More </a>, All rights reserved.
      </span>
    </p>
    <modal v-if="showUsageAgreementModal" ref="modalName" @onCloseModal="showUsageAgreementModal = false">
      <template v-slot:header>
        <h1></h1>
      </template>
      <template v-slot:body>
        <div v-if="isLoading">{{ $t('commons.data_loading') }}</div>
        <div v-html="staticContent.content"></div>
      </template>
    </modal>
  </footer>
</template>

<script>
import Modal from '@/components/global/Modal';
import Services from '@/config/_axios';
import API from '@/api';

export default {
  name: 'Footer',
  components: {
    Modal
  },
  data: () => ({
    isLoading: false,
    showUsageAgreementModal: false,
    staticContent: {
      title: null,
      content: null
    }
  }),
  methods: {
    getAgreementContent() {
      this.isLoading = true;
      Services.get(API.userAgreementContent)
        .then((res) => {
          this.staticContent = res.data?.data || '';
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          if (err?.response?.data?.message) {
            this.$toast.error(err.response.data.message);
          }
        });
    },
    onClickUsageAgreement() {
      this.showUsageAgreementModal = true;
      this.getAgreementContent();
    }
  }
};
</script>
