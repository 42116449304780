<template>
    <v-gauge gaugeValueClass="d-none" :width="size" :height="size" :minValue="0" :maxValue="3" :value="value" :options="gaugeOptions" />
</template>
<script>
import VGauge from 'vgauge';
import utils from '@/config/utils';
export default {
    name: "GaugeMeter",
    props: {
        defaultValue: {
            type: Number,
            default: 1.5
        },
        size: {
            type: String,
            default: '45'
        }
    },
    components: { VGauge },
    data: () => ({
        // references http://bernii.github.io/gauge.js/, https://github.com/amroessam/vgauge
        gaugeOptions: {
            angle: -0.2, // The span of the gauge arc
            lineWidth: 0.25, // The line thickness
            radiusScale: 0.8, // Relative radius
            pointer: {
                length: 0.45, // // Relative to gauge radius
                strokeWidth: 0.096, // The thickness
                color: '#07524D' // Fill color
            },
            limitMax: false,     // If false, max value increases automatically if value > maxValue
            limitMin: false,     // If true, the min value of the gauge will be fixed
            colorStart: '#6FADCF',   // Colors
            colorStop: '#8FC0DA',    // just experiment with them
            strokeColor: '#E0E0E0',  // to see which ones work best for you
            generateGradient: true,
            highDpiSupport: true,     // High resolution support
            staticZones: [
                { strokeStyle: "#30B32D", min: 2, max: 3 }, // Green
                { strokeStyle: "#FFDD00", min: 1, max: 2 }, // Yellow
                { strokeStyle: "#F03E3E", min: 0, max: 1 }  // Red
            ],
        },
        value: 1
    }),
    created() {
        this.value = utils.getMarginLevelStuffs(this.defaultValue).value;
    },
    watch: {
        defaultValue: function(newValue) {
            this.value = utils.getMarginLevelStuffs(newValue).value;
        }
    }
}
</script>