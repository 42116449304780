<template>
    <div class="d-flex justify-content-between">
      <button
        type="button"
        v-for="val in times"
        :key="val.value"
        style="width: 22%"
        class="btn btn-sm bg-lighten-1 font-weight-bold"
        :class="defaultValue && defaultValue === val.value ? 'bg-success text-white' : 'text-secondary'"
        @click="onSelected(val)"
      >
        {{ val.label }}
      </button>
    </div>
  </template>
  <script>
  export default {
    name: 'TimesButtons',
    props: {
      defaultValue: {
        type: Number,
        default: 3
      },
      times: {
        type: Array,
        default: () => [
          {label: '3x', value: 3},
          {label: '5x', value: 5},
          {label: '10x', value: 10}
        ]
      }
    },
    methods: {
      onSelected(val) {
        this.$emit('onSelectedTimes', val);
      }
    }
  };
  </script>
  