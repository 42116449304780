/* eslint-disable no-debugger */
import axios from 'axios';
// import Vue from 'vue';
import i18n from './_i18n';

const CancelToken = axios.CancelToken;
const AxiosTokenSource = CancelToken.source();

const BASE_API_URL = process.env.VUE_APP_API_URI;
const Services = axios.create({ baseURL: String(BASE_API_URL), cancelToken: AxiosTokenSource.token });

let lang = i18n.locale || 'tr';

Services.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('userToken');
    const auth = token ? `Bearer ${token}` : '';
    config.headers.common['Authorization'] = auth;
    config.headers.common['X-Localization'] = lang;
    return config;
  },
  (error) => Promise.reject(...error)
);

Services.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      AxiosTokenSource.cancel('401 error');
      localStorage.removeItem('userToken');
      window.location = '/';
    }
    if ([404, 405, 500].includes(error?.response?.status)) {
      let errorMessage = 'An unexpected error occurred. Please try again later.';
      if (lang == 'tr') {
        errorMessage = 'Beklenmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.';
      } else if (lang == 'nl') {
        errorMessage = 'Er is een onverwachte fout opgetreden. Probeer het later opnieuw.';
      } else if (lang == 'de') {
        errorMessage = 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.';
      } else if (lang == 'me') {
        errorMessage = 'Došlo je do neočekivane pogreške. Molimo pokušajte ponovo kasnije.';
      } else if (lang == 'et') {
        errorMessage = 'Ilmnes ootamatu viga. Palun proovi hiljem uuesti.';
      } else if (lang == 'fr') {
        errorMessage = 'Une erreur inattendue est apparue. Veuillez réessayer plus tard.';
      }

      // Vue.$toast.error(errorMessage);
      throw {
        ...error,
        response: {
          ...error.response,
          data: {
            ...error.response.data,
            message: errorMessage
          }
        }
      };
      
    }
    throw error;
  }
);
export default Services;
