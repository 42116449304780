<template>
  <div class='content-wrapper'>
    <Breadcrumb :title="$t('personalMenu-history')" />
    <div class='content-body'>
      <div class='row'>
        <div class='col-xl-8 col-lg-8 col-md-12'>
          <section class='card'>
            <div class='card-content'>
              <div class='card-body'>
                <AccountHistory />
              </div>
            </div>
          </section>
        </div>
        <Sidebar />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/personal/Sidebar.vue';
import AccountHistory from '@/components/personal/History';
import Breadcrumb from '@/components/layout/Breadcrumb';

export default {
  name: 'History',
  components: {
    Sidebar,
    AccountHistory,
    Breadcrumb
  }
};
</script>

<style scoped></style>
