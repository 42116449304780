const websocketBase = 'wss://stream.binance.com:9443/ws/';

const closeWebSocket = async (socket = null) => {
    if (socket) {
        if (socket.readyState === 0 || socket.readyState === 2) {
            setTimeout(async () => {
                await closeWebSocket(socket);
            }, 500);
            return;
        }

        socket.close();
    }
};

const calulateTotal = (price, quantity) => {
    const priceToCalculate = parseFloat(price);
    const quantityToCalculate = parseFloat(quantity);
    return priceToCalculate * quantityToCalculate;
};

const binance = {
    state: () => ({
        tradeStreamsWebSocket: null,
        tickerStreamsWebSocket: null,
        pairSymbolTickerWebSocket: null,
        pair24hrTicker: null,
        pairSymbolTicker: {
            lastPrice: 0,
            priceChange: 0,
            priceChangePercent: 0,
            highPrice: 0,
            lowPrice: 0,
            totalTradedQuoteAssetVolume: 0
        },
        tradeStreams: [],
        symbolTickerStreams: [],
    }),
    mutations: {},
    actions: {
        async INIT_TICKER_STREAMS({ state }, symbol) {
            await closeWebSocket(state.tickerStreamsWebSocket);
            state.symbolTickerStreams = [];
            state.tickerStreamsWebSocket = new WebSocket(
                `${websocketBase}${symbol}@ticker`
            );

            state.tickerStreamsWebSocket.onmessage = (event) => {
                const tickerData = JSON.parse(event.data);

                if (!tickerData.id) {
                    const ticker = {
                        eventType: tickerData.e,
                        eventTime: tickerData.E,
                        symbol: tickerData.s,
                        priceChange: tickerData.p,
                        priceChangePercent: tickerData.P,
                        weightedAveragePrice: tickerData.w,
                        firstTradePrice: tickerData.x,
                        lastPrice: tickerData.c,
                        lastQuantity: tickerData.Q,
                        lastAmount: calulateTotal(tickerData.c, tickerData.Q),
                        bestBidPrice: tickerData.b,
                        bestBidQuantity: tickerData.B,
                        bestAskPrice: tickerData.a,
                        bestAskQuantity: tickerData.A,
                        openPrice: tickerData.o,
                        highPrice: tickerData.h,
                        lowPrice: tickerData.l,
                        totalTradedBaseAssetVolume: tickerData.v,
                        totalTradedQuoteAssetVolume: tickerData.q,
                        statisticsOpenTime: tickerData.O,
                        statisticsCloseTime: tickerData.C,
                        firstTradeId: tickerData.F,
                        lastTradeId: tickerData.L,
                        totalTrades: tickerData.n,
                    };

                    if (state.symbolTickerStreams.length > 30) {
                        state.symbolTickerStreams.pop();
                    }
                    state.symbolTickerStreams.unshift(ticker);
                }
            };
        },
        async FETCH_PAIR_SYMBOL_TICKER_STREAMS({ state }, symbol) {
            state.pairSymbolTicker = undefined;
            await closeWebSocket(state.pairSymbolTickerWebSocket);

            state.pairSymbolTickerWebSocket = new WebSocket(
                `${websocketBase}${symbol}@ticker`
            );

            state.pairSymbolTickerWebSocket.onmessage = (event) => {
                const tickerData = JSON.parse(event.data);

                const symbolticker = {
                    eventType: tickerData.e,
                    eventTime: tickerData.E,
                    symbol: tickerData.s,
                    priceChange: tickerData.p,
                    priceChangePercent: tickerData.P,
                    weightedAveragePrice: tickerData.w,
                    firstTradePrice: tickerData.x,
                    lastPrice: tickerData.c,
                    lastQuantity: tickerData.Q,
                    lastAmount: calulateTotal(tickerData.c, tickerData.Q),
                    bestBidPrice: tickerData.b,
                    bestBidQuantity: tickerData.B,
                    bestAskPrice: tickerData.a,
                    bestAskQuantity: tickerData.A,
                    openPrice: tickerData.o,
                    highPrice: tickerData.h,
                    lowPrice: tickerData.l,
                    totalTradedBaseAssetVolume: tickerData.v,
                    totalTradedQuoteAssetVolume: tickerData.q,
                    statisticsOpenTime: tickerData.O,
                    statisticsCloseTime: tickerData.C,
                    firstTradeId: tickerData.F,
                    lastTradeId: tickerData.L,
                    totalTrades: tickerData.n,
                };

                state.pairSymbolTicker = symbolticker;
            };
        },
    },
    getters: {
        pair24hrTicker: (state) => state.pair24hrTicker,
        pairSymbolTicker: (state) => state.pairSymbolTicker,
        symbolTickerStreams: (state) => state.symbolTickerStreams
    },
    namespaced: true
};

export default binance;